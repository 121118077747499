import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { api } from "../../../services/Api";

const items = [
  {
    key: "1",
    icon: <MailOutlined />,
    label: (
      <span style={{ fontWeight: "700", fontSize: "16px" }}>
        For Inland Revenue Department (IRD)
      </span>
    ),
    children: [
      {
        key: "11",
        label: "Financial Statement",
      },
      {
        key: "12",
        label: "D1 Filing & Tax Clearance",
      },
      {
        key: "13",
        label: "D2 Filing & Tax Clearance",
      },
      {
        key: "14",
        label: "D3 Filing & Tax Clearance",
      },
      {
        key: "16",
        label: "VAT Return",
      },
      {
        key: "17",
        label: "Issue Sales Bill",
      },
      {
        key: "18",
        label: "Ammendment of D3_2079/080",
      },
      {
        key: "19",
        label: "ETDS",
      },
      {
        key: "20",
        label: "VAT Register",
      },
    ],
  },
  {
    key: "2",
    icon: <AppstoreOutlined />,
    label: (
      <span style={{ fontWeight: "700", fontSize: "16px" }}>
        For Office Of Company Registar (OCR)
      </span>
    ),
    children: [
      {
        key: "21",
        label: "Company Registration_Template",
      },
      {
        key: "22",
        label: "Three Month Update",
      },
      {
        key: "23",
        label: "Three Month Update & Share Lagat",
      },
      {
        key: "24",
        label: "Share Jafat",
      },
      {
        key: "25",
        label: "Niyamawali Samsodhan",
      },
      {
        key: "26",
        label: "Company Update",
      },
      {
        key: "27",
        label: "Username & Password Reset_OCR",
      },
      {
        key: "28",
        label: "Share Kharid Bikri",
      },
      {
        key: "29",
        label: "Thegana Paribartan",
      },
    ],
  },
  {
    key: "3",
    icon: <SettingOutlined />,
    label: (
      <span style={{ fontWeight: "700", fontSize: "16px" }}>
        For Local Bodies
      </span>
    ),
    children: [
      {
        key: "31",
        label: "Ward registration",
      },
      {
        key: "32",
        label: "Ward Closure",
      },
    ],
  },
];

// Create a mapping of keys to labels
const createKeyLabelMapping = (items) => {
  const mapping = {};
  const func = (items) => {
    items.forEach((item) => {
      if (item.key) {
        mapping[item.key] = item.label;
      }
      if (item.children) {
        func(item.children);
      }
    });
  };
  func(items);
  return mapping;
};
const keyLabelMapping = createKeyLabelMapping(items);

const getLevelKeys = (items1) => {
  const key = {};
  const func = (items2, level = 1) => {
    items2.forEach((item) => {
      if (item.key) {
        key[item.key] = level;
      }
      if (item.children) {
        func(item.children, level + 1);
      }
    });
  };
  func(items1);
  return key;
};
const levelKeys = getLevelKeys(items);

const AgentMenu = ({ form, setShowTemplateFilled }) => {
  const [stateOpenKeys, setStateOpenKeys] = useState(["", ""]);

  const onOpenChange = (openKeys) => {
    const currentOpenKey = openKeys.find(
      (key) => stateOpenKeys.indexOf(key) === -1
    );
    // open
    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);
      setStateOpenKeys(
        openKeys
          // remove repeat key
          .filter((_, index) => index !== repeatIndex)
          // remove current level all child
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey])
      );
    } else {
      // close
      setStateOpenKeys(openKeys);
    }
  };

  const getTaskTemplate = () => {
    return api.get("/template-tasks");
  };

  const {
    isLoading: template_loading,
    data: template_data,
    refetch: template_refetch,
  } = useQuery(["template"], getTaskTemplate);

  const onClick = (e) => {
    const label = keyLabelMapping[e.key];
    const templateFix = template_data?.data?.docs?.filter(
      (item) => item?.title === label
    );
    form.setFieldsValue(templateFix[0]);
    setShowTemplateFilled(true);
    // You can add your logic here to handle the clicked item
  };

  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={[""]}
      openKeys={stateOpenKeys}
      onOpenChange={onOpenChange}
      onClick={onClick}
      items={items}
    />
  );
};

export default AgentMenu;
