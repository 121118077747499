import {
  Avatar,
  Button,
  Dropdown,
  Form,
  Image,
  Menu,
  Row,
  Space,
  Table,
} from "antd";
import debounce from "lodash/debounce";
import React, { useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { IoEllipsisVertical } from "react-icons/io5";
import { MdModeEditOutline } from "react-icons/md";
import { TiDocumentAdd } from "react-icons/ti";
import { useMutation, useQuery } from "react-query";
import Swal from "sweetalert2";
import feed from "../../../assets/images/feedback.png";
import PageSearch from "../../../components/common/PageSearch";
import { restriction } from "../../../constant/Restrictions";
import { api } from "../../../services/Api";
import { fetchUser } from "../../../services/User";
import { SendPushNotification } from "../../../utils/SendNotification";
import AgentNews from "./AgentNews";
import NewsForm from "./NewsForm";
import NewsModal from "./NewsModal";
function NewsSection() {
  const [regexQuery, setRegexQuery] = useState("");
  const [image, setImage] = useState([]);

  const [addClientVisible, setAddClientVisible] = useState(false);
  const [isClientEditModalVisible, setClientEditModalVisible] = useState(false);
  const [clientForm] = Form.useForm();
  const [clientEditForm] = Form.useForm();
  const [passwordForm] = Form.useForm();
  const [editData, setEditData] = useState({});
  const {
    isUserLoading,
    data: userData,
    refetch: feed_refetch,
  } = useQuery("user", fetchUser);
  const [params, setParams] = useState({
    sort: "-updatedAt",
    page: 1,
    limit: 15,
    skip: 0,
    status: undefined,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editimage, setEditImage] = useState(false);
  const [isDescription, setIsDescription] = useState(false);

  const [descriptionText, setDescriptionText] = useState();

  const showModal = (text) => {
    setDescriptionText(text);
    setIsDescription(true);
    showEditClient(text);
  };
  const handlePasswordOk = () => {
    setIsModalOpen(false);
  };
  const handlePasswordCancel = () => {
    setIsModalOpen(false);
  };
  const showEditClient = (data) => {
    setEditData(data);
    setClientEditModalVisible(true);
  };
  const [feedId, setFeedId] = useState();

  const updateFeedPic = (data) => {
    const payload = {
      featureImage: data.featureImage,
    };
    const id = data?.id;

    const formData = new FormData();

    if (editimage) {
      formData.append("featureImage", data.featureImage);
    } else {
      data.featureImage.fileList?.map((file) =>
        formData.append("featureImage", file.originFileObj)
      );
    }

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return api.patch(
      `/feeds/${id || feedId}/update-feature-img`,
      formData,
      config,
      payload
    );
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const updateImage = (key) => {
    setFeedId(key);
    setEditImage(true);
  };

  function extractTextFromHTML(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  }

  const { mutate: update_feed_img_mutate, isLoading: update_feed_img_loading } =
    useMutation(updateFeedPic, {
      onSuccess: (value) => {
        feed_refetch();
        const formattedDescription = extractTextFromHTML(
          value.data.description
        );
        SendPushNotification(
          "/topics/news",
          value.data.title,
          formattedDescription?.slice(0, 25),
          "FeedsPage",
          value.data._id,
          value.data.featureImage.url
        );
        // newscategory_refetch();
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });

  const uploadImg = (featureImage, id) => {
    update_feed_img_mutate({
      featureImage: featureImage,
      id,
    });
  };

  const handleEditClient = () => {
    clientEditForm.resetFields();
    setEditData({});
    setClientEditModalVisible(false);
    setIsDescription(false);
  };

  const patchNewsCategory = (data) => {
    return api.patch(`/feeds/${editData?.key}`, data);
  };
  const {
    mutate: newscategory_patch_mutate,
    isLoading: newscategory_patch_loading,
  } = useMutation(patchNewsCategory, {
    onSuccess: () => {
      newscategory_refetch();
      clientForm.resetFields();
      setClientEditModalVisible(false);
      setEditData({});
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });

  const removeClient = (data) => {
    return api.delete(`/feeds/${data}`);
  };
  const { mutate: client_delete_mutate, isLoading: client_delete_loading } =
    useMutation(removeClient, {
      onSuccess: () => {
        newscategory_refetch();
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });
  const handleNewsDelete = (data) => {
    Swal.fire({
      title: "Are you sure ?",
      text: "You want to delete this Client.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Ok`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        client_delete_mutate(data?.key);
      }
    });
  };
  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (row, record) => {
        return (
          <>
            <Avatar
              src={<Image src={row} />}
              size="large"
              onClick={() => console.log("key", record.key)}
            />
            {!restriction[userData?.data.role.slug].includes(
              "add_news_img"
            ) && (
              <>
                <label
                  style={{
                    cursor: "pointer",
                  }}
                  htmlFor="file-input-feed"
                >
                  <MdModeEditOutline
                    style={{
                      cursor: "pointer",
                      color: "#00ABC5",
                      position: "absolute",
                      bottom: "1rem",
                    }}
                    size={15}
                    onClick={() => updateImage(record.key)}
                  />
                </label>
                <input
                  id="file-input-feed"
                  type="file"
                  className="hidden"
                  accept="image/png, image/jpeg"
                  onChange={(e) => {
                    uploadImg(e.target.files[0]);
                  }}
                />
              </>
            )}
          </>
        );
      },
    },
    {
      title: "News Title",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text, record) => {
        // Parse the HTML content
        const parser = new DOMParser();
        const parsedHtml = parser.parseFromString(text, "text/html");
        // Get the text content of the parsed HTML
        const slicedText = parsedHtml.body.textContent.slice(0, 20);
        return (
          <>
            <span
              dangerouslySetInnerHTML={{
                __html: slicedText + " ..........",
              }}
            />
            <btn
              // type="primary"
              onClick={() => showModal(record)}
              style={{ color: "green" }}
            >
              View All
            </btn>
          </>
        );
      },
    },

    {
      title: "News Category",
      dataIndex: "categoryName",
      key: "categoryName",
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div>
          {!restriction[userData?.data.role.slug].includes("change_news") && (
            <Dropdown
              overlay={
                <Menu mode="horizontal">
                  <Menu.Item
                    key="one"
                    className="edit-btn"
                    onClick={() => showEditClient(record)}
                    icon={
                      <FaEdit style={{ color: "blue", fontSize: "20px" }} />
                    }
                  >
                    Edit
                  </Menu.Item>

                  <Menu.Item
                    key="two"
                    className="delete-btn"
                    onClick={() => handleNewsDelete(record)}
                    icon={
                      <FaTrash style={{ color: "red", fontSize: "20px" }} />
                    }
                  >
                    Delete
                  </Menu.Item>
                </Menu>
              }
            >
              <Space>
                <IoEllipsisVertical
                  style={{
                    cursor: "pointer",
                    color: "#00ABC5",
                  }}
                  size={20}
                />
              </Space>
            </Dropdown>
          )}
        </div>
      ),
    },
  ];

  const getNews = () => {
    return api.get("/feeds", {
      params: {
        ...params,
        title: `/${regexQuery.trim()}/i`,
      },
    });
  };
  const {
    isLoading,
    data,
    refetch: newscategory_refetch,
  } = useQuery(["allclient", regexQuery], getNews);

  const postNewsCategories = async (data) => {
    const res = await api.post(`/feeds`, data);
    if (res) {
      image?.file && uploadImg(image, res?.data?._id);
      setFeedId(res?.data?._id);
    }
  };
  const { mutate: news_category, isLoading: newscategory_add_loading } =
    useMutation(postNewsCategories, {
      onSuccess: (value) => {
        newscategory_refetch();
        clientForm.resetFields();
        setImage([]);
        setAddClientVisible(false);
        setIsDescription(false);
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });
  const handleClientAddCancel = () => {
    clientForm.resetFields();
    setAddClientVisible(false);
    setIsDescription(false);
  };
  const handleClientAddOk = () => {
    clientForm
      .validateFields()
      .then((values) => {
        news_category(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  const handleClientEditOk = () => {
    clientEditForm
      .validateFields()
      .then((values) => {
        if (!values.category) {
          // If category is not modified, use the initial value
          values.category = editData?.category;
        }
        newscategory_patch_mutate(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const columnData = [];
  data?.data?.docs?.forEach((f) => {
    const temp = {
      key: f._id,
      title: f.title,
      description: f.description,
      category: f.category?._id,
      image: f.featureImage?.url ?? feed,
      categoryName: f.category?.name,
    };
    columnData.push(temp);
  });

  return (
    <>
      <div className="main-container">
        <Row className="content-apart mb-10">
          <div className="main-title">News</div>
          <Row>
            <PageSearch
              onChange={(e) => {
                debounce(setRegexQuery(e.target.value), 800);
              }}
              placeholder="Search for News"
            />
            {!restriction[userData?.data.role.slug].includes(
              "change_news_section"
            ) && (
              <Button
                style={{ borderRadius: "0.5em" }}
                type="primary"
                onClick={() => {
                  setAddClientVisible(true);
                }}
              >
                <TiDocumentAdd size={20} />
              </Button>
            )}
          </Row>
        </Row>
        {!restriction[userData?.data.role.slug].includes("edit_news") && (
          <Table
            loading={isLoading}
            size="small"
            onRow={(record, rowIndex) => {}}
            className="fiscal-table"
            columns={columns}
            pagination={{ pageSize: 15 }}
            dataSource={columnData}
          />
        )}
        {userData?.data.role.slug === "agent" && <AgentNews />}
      </div>

      {addClientVisible && (
        <NewsForm
          isModalVisible={addClientVisible}
          form={clientForm}
          okText="Create"
          handleCancel={handleClientAddCancel}
          handleOk={handleClientAddOk}
          loading={newscategory_add_loading}
          headingText="Create new client"
          setImage={setImage}
        />
      )}
      {isClientEditModalVisible && (
        <NewsForm
          isModalVisible={isClientEditModalVisible}
          form={clientEditForm}
          okText="Update"
          handleCancel={handleEditClient}
          handleOk={handleClientEditOk}
          loading={newscategory_patch_loading}
          initialValues={editData}
          headingText="Edit client"
          edit
          isDescription={isDescription}
        />
      )}
      {isModalOpen && (
        <NewsModal
          title="Basic Modal"
          open={isModalOpen}
          onOk={handleOk}
          descriptionText={descriptionText}
          onCancel={handleCancel}
        />
      )}
    </>
  );
}

export default NewsSection;
