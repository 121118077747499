import { Button, Card, Col, Layout, Row } from "antd";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Client from "../../assets/images/client.png";
import Employee from "../../assets/images/emp.png";
import feed2 from "../../assets/images/feedback.png";
import Office from "../../assets/images/office.png";
import Task from "../../assets/images/task.png";
import AgentBarChart from "../../components/charts/AgentBarChart";
import AgentPieChart from "../../components/charts/AgentChart";
import BarChart from "../../components/charts/BarChart";
import PieChart from "../../components/charts/PieChart";
import CountingCard from "../../components/common/CountingCard";
import { multiTabContext } from "../../components/reducer/context";
import { restriction } from "../../constant/Restrictions";
import { api } from "../../services/Api";
import { fetchUser } from "../../services/User";

const { Header, Content } = Layout;

function Main() {
  const [params, setParams] = useState({
    sort: "-createdAt",
    pagination: false,
    treeFormat: false,
  });
  const navigate = useNavigate();
  const { activeTab, setActiveTab } = useContext(multiTabContext);
  const { isUserLoading, data: userData } = useQuery("user", fetchUser);
  const getFeeds = () => {
    return api.get("/feeds", { params: { ...params } });
  };

  const {
    isLoading: feed_loading,
    data: feed_data,
    refetch: feed_refetch,
  } = useQuery("feed", getFeeds);

  const getTotalUsers = () => {
    return api.get("/users/count", {
      params: {
        "relation_role.slug": "user",
      },
    });
  };

  const getTotalAgents = () => {
    return api.get("/users/count", {
      params: {
        "relation_role.slug": "agent",
      },
    });
  };

  const {
    isLoading: count_users_loading,
    data: count_users,
    refetch: count_users_refetch,
  } = useQuery("TotalUsers", getTotalUsers);

  // TODO: agent count
  const {
    isLoading: count_agents_loading,
    data: count_agents,
    refetch: count_agents_refetch,
  } = useQuery("TotalAgents", getTotalAgents);

  const getTotalEmp = () => {
    return api.get("/users/count", {
      params: {
        "relation_role.slug": "employee",
      },
    });
  };

  const {
    isLoading: count_Emp_loading,
    data: count_Emp,
    refetch: count_Emp_refetch,
  } = useQuery("TotalEmps", getTotalEmp);

  const getTotalTasks = () => {
    return api.get("/tasks/count");
  };
  const getAllTasks = () => {
    return api.get("/tasks");
  };
  const getindividualTask = () => {
    return api.get("/tasks", {
      params: { ...params },
    });
  };

  const {
    isLoading: individual_task_loading,
    data: individual_task,
    refetch: individual_task_refetch,
  } = useQuery("individualtask", getindividualTask);

  const fetchTask = () => {
    return api.get(`/tasks?assignee=${userData?.data?.id}`, {
      params: { ...params },
    });
  };

  const {
    isLoading: task_loading,
    data: task_data,
    refetch,
  } = useQuery(["task", params], fetchTask);

  const { data } = useQuery("individualtask", getAllTasks);

  const {
    isLoading: total_tasks_loading,
    data: total_tasks,
    refetch: total_tasks_refetch,
  } = useQuery("TotalTasks", getTotalTasks);

  const getTotalCompanies = () => {
    return api.get("/companies/count");
  };

  const {
    isLoading: total_companies_loading,
    data: total_companies,
    refetch: total_companies_refetch,
  } = useQuery("TotalCompanies", getTotalCompanies);

  const getGraphData = () => {
    return api.get("/tasks/dashboard/graph-data");
  };

  const {
    isLoading: graphData_loading,
    data: graphData_data,
    refetch: graphData_refetch,
  } = useQuery("totalGraphData", getGraphData);
  const userId = userData.data._id;

  const assignedTask = individual_task?.data?.docs?.filter((data) =>
    data.assignee.some((assignee) => assignee._id === userId)
  );

  const employeeLogin = userData?.data.role.slug === "employee";

  const totalOngoing = task_data?.data?.docs?.filter(
    (task) => task?.status === "Ongoing"
  );

  const totalPending = task_data?.data?.docs?.filter(
    (task) => task?.status === "Pending"
  );

  const totalCompleted = task_data?.data?.docs?.filter(
    (task) => task.status === "Completed"
  );

  //

  const handleClick = (name) => {
    if (name === "All") {
      navigate(employeeLogin ? "/dashboard/tasks" : "/dashboard/employees");
    }
    if (name === "Completed") {
      navigate(employeeLogin ? "/dashboard/tasks" : "/dashboard/clients");
    }
    if (name === "Pending") {
      navigate(employeeLogin && "/dashboard/tasks");
    }
    if (name === "task") {
      navigate("/dashboard/tasks");
    }
    if (name === "Ongoing") {
      navigate(employeeLogin ? "/dashboard/tasks" : "/dashboard/companies");
    }
    setActiveTab(name);
  };

  const getAgentTask = ({ status }) => {
    return api.get(
      `/tasks?relation_createdBy._id=${userId}&status=${status}&sort=-createdAt`,
      {
        params: { "relation_createdBy.role.slug": "agent" },
      }
    );
  };

  // For "Pending" tasks
  const {
    isLoading: pending_loading,
    data: pending_tasks,
    refetch: pending_refetch,
  } = useQuery("pendingTasks", () => getAgentTask({ status: "Pending" }));

  // For "Ongoing" tasks
  const {
    isLoading: ongoing_loading,
    data: ongoing_tasks,
    refetch: ongoing_refetch,
  } = useQuery("ongoingTasks", () => getAgentTask({ status: "Ongoing" }));

  // For "Completed" tasks
  const {
    isLoading: completed_loading,
    data: completed_tasks,
    refetch: completed_refetch,
  } = useQuery("completedTasks", () => getAgentTask({ status: "Completed" }));

  const agentTask = [
    pending_tasks?.data?.docs,
    ongoing_tasks?.data?.docs,
    completed_tasks?.data?.docs,
  ];

  const gridContainerStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridGap: "10px",
    overflow: "hidden",
  };

  const firstItemStyle = {
    gridRow: "span 2",
    gridColumn: "span 1",
    backgroundColor: "lightblue",
  };

  const secondItemStyle = {
    gridColumn: "span 1",
    backgroundColor: "lightgreen",
  };

  const thirdItemStyle = {
    gridColumn: "span 1",
    backgroundColor: "lightcoral",
  };

  return (
    <div className="main-container">
      {!restriction[userData?.data.role.slug].includes("view_dashboard") && (
        <>
          <h1 className="main-title">Dashboard Overview</h1>
          <div>
            <Row
              style={{
                justifyContent: "space-between",
              }}
            >
              <div onClick={() => handleClick("All")}>
                <CountingCard
                  icon={
                    <img height={66} width={66} src={Employee} alt="employee" />
                  }
                  number={
                    employeeLogin ? assignedTask?.length : count_Emp?.data
                  }
                >
                  <h3>
                    {" "}
                    {employeeLogin
                      ? "Total Number of Assigned Task"
                      : "Total number of Employees"}
                  </h3>
                </CountingCard>
              </div>

              <div onClick={() => handleClick("Completed")}>
                <CountingCard
                  icon={
                    <img height={66} width={66} src={Client} alt="client" />
                  }
                  number={
                    employeeLogin ? totalCompleted?.length : count_users?.data
                  }
                >
                  <h3>
                    {" "}
                    {employeeLogin
                      ? "Total Number of Completed Task"
                      : "Total number of Clients"}
                  </h3>
                </CountingCard>
              </div>

              {!employeeLogin && (
                <div onClick={() => navigate("/dashboard/agents")}>
                  <CountingCard
                    icon={
                      <img height={66} width={66} src={Client} alt="client" />
                    }
                    number={count_agents?.data}
                  >
                    <h3>Total number of Agents</h3>
                  </CountingCard>
                </div>
              )}

              <div
                onClick={() => handleClick(employeeLogin ? "Pending" : "task")}
              >
                <CountingCard
                  icon={<img height={66} width={66} src={Task} alt="task" />}
                  number={
                    employeeLogin ? totalPending?.length : total_tasks?.data
                  }
                >
                  <h3>
                    {" "}
                    {employeeLogin
                      ? "Total Number of Pending Task"
                      : "Total number of Tasks"}
                  </h3>
                </CountingCard>
              </div>
              <div onClick={() => handleClick("Ongoing")}>
                <CountingCard
                  icon={
                    <img height={66} width={66} src={Office} alt="companies" />
                  }
                  number={
                    employeeLogin ? totalOngoing?.length : total_companies?.data
                  }
                >
                  <h3>
                    {employeeLogin
                      ? "Total Number of Ongoing Task"
                      : "Total number of Companies"}
                  </h3>
                </CountingCard>
              </div>
            </Row>
          </div>
          {!restriction[userData?.data.role.slug].includes(
            "view_user_overview"
          ) && (
            <>
              <h1 className="main-subtitle">User's Overview</h1>
              <Row
                gutter={16}
                style={{ marginTop: "1rem" }}
                className="feed-responsive"
              >
                <Col span={10}>
                  <div className="dashboard-card-2">
                    <PieChart
                      piechartData={
                        employeeLogin ? assignedTask : data?.data?.docs
                      }
                    />
                  </div>
                </Col>
                <Col span={14}>
                  <div className="dashboard-card">
                    <BarChart data={graphData_data?.data} />
                  </div>
                </Col>
              </Row>
            </>
          )}
        </>
      )}
      {restriction[userData?.data.role.slug].includes("agent_dashboard") && (
        <>
          <h1 className="main-title">Dashboard Agent Overview</h1>
          <div>
            <Row
              style={{
                justifyContent: "space-between",
              }}
            >
              {!employeeLogin && (
                <div>
                  <CountingCard
                    icon={<img height={66} width={66} src={Task} alt="task" />}
                    number={pending_tasks?.data?.totalDocs}
                  >
                    <h3>Pending Task</h3>
                  </CountingCard>
                </div>
              )}

              <div>
                <CountingCard
                  icon={<img height={66} width={66} src={Task} alt="task" />}
                  number={ongoing_tasks?.data?.totalDocs}
                >
                  <h3>Ongoing Task</h3>
                </CountingCard>
              </div>
              <div>
                <CountingCard
                  icon={<img height={66} width={66} src={Task} alt="task" />}
                  number={completed_tasks?.data?.totalDocs}
                >
                  <h3>Completed Task</h3>
                </CountingCard>
              </div>
            </Row>
          </div>
          {!restriction[userData?.data.role.slug].includes(
            "view_user_overview"
          ) && (
            <>
              <h1 className="main-subtitle">Task's Overview</h1>
              <Row
                gutter={16}
                style={{ marginTop: "1rem" }}
                className="feed-responsive"
              >
                <Col span={10}>
                  <div className="dashboard-card-2">
                    <AgentPieChart piechartData={agentTask} />
                  </div>
                </Col>
                <Col span={14}>
                  <div className="dashboard-card">
                    <AgentBarChart data={agentTask} />
                  </div>
                </Col>
              </Row>
            </>
          )}
        </>
      )}

      <Row gutter={16} style={{ marginTop: "2rem" }}>
        <Col span={24}>
          <div className="feed-section">
            <h2
              className="main-subtitle"
              style={{
                marginLeft: "5px",
              }}
            >
              Feeds
            </h2>
            <hr />
            {/* {feed_data?.data?.docs?.slice(0, 3)?.map((feed, i) => {
              return (
                <>
                  <div key={i}>
                    <Comment
                      avatar={
                        <Avatar
                          src={<Image src={feed?.featureImage?.url ?? feed2} />}
                          alt="feed"
                        />
                      }
                      content={
                        <p
                          style={{
                            paddingRight: "7px",
                            opacity: 0.8,
                            marginTop: "5px",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: limit(feed?.description, 160),
                          }}
                        ></p>
                      }
                      datetime={
                        <Tooltip>
                          <span
                            style={{
                              marginLeft: "5px",
                            }}
                          >
                            {moment(feed.createdAt).format(
                              "YYYY/MM/DD , h:mm a"
                            )}
                          </span>
                        </Tooltip>
                      }
                    />
                  </div>
                </>
              );
            })} */}

            <Row gutter={[16, 16]}>
              <Col
                span={8}
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
              >
                {/* Second item */}
                <Row gutter={[16, 16]}>
                  {feed_data?.data?.docs?.slice(0, 5)?.map((feed, i) => {
                    return (
                      <Col span={8} lg={8} md={12} xs={24}>
                        <div
                          style={{
                            // background: "#f0f0f0",
                            height: "300px",
                            overflow: "hidden",
                            boxShadow: "1px 2px black",
                          }}
                          onClick={() =>
                            navigate(
                              `/dashboard/news-section/news/${feed?._id}`
                            )
                          }
                        >
                          <img
                            alt="example"
                            src={feed?.featureImage?.url ?? feed2}
                            style={{
                              width: "100%",
                              height: "60%",
                              objectFit: "cover",
                              objectPosition: "top",
                              borderBottom: "1px solid gray",
                              background: "lightgray",
                            }}
                          />
                          <div style={{ padding: "8px 4px" }}>
                            <h3
                              style={{
                                paddingRight: "7px",
                                opacity: 0.8,
                                marginTop: "5px",
                              }}
                              // dangerouslySetInnerHTML={{
                              //   __html: limit(feed?.description, 160),
                              // }}
                            >
                              {feed?.title}
                            </h3>
                            <span
                              style={{
                                marginLeft: "5px",
                              }}
                            >
                              {moment(feed.createdAt).format(
                                "YYYY/MM/DD , h:mm a"
                              )}
                            </span>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>

            <Button
              type="primary"
              onClick={() => {
                navigate(`/dashboard/news`);
              }}
              style={{
                marginLeft: "35%",
                marginTop: "2%",
                position: "sticky",
              }}
            >
              View all Feeds
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Main;

const NewsCard = () => {
  const { Meta } = Card;

  const secondNews = {
    display: "flex",
    backgroundColor: "lightcoral",
  };

  return (
    <Card
      hoverable
      style={{
        width: 240,
      }}
      cover={
        <img
          alt="example"
          src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
        />
      }
    >
      <Meta title="Europe Street beat" description="www.instagram.com" />
    </Card>
  );
};
