import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import React from "react";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

function AgentPieChart({ piechartData }) {
  const data = {
    labels: ["completed", "ongoing", "pending"],
    datasets: [
      {
        label: "# of Votes",
        data: [
          piechartData[2]?.length,

          piechartData[1]?.length,
          piechartData[0]?.length,
        ],
        backgroundColor: [
          "rgba(53, 235, 99, 0.5)",
          "rgba(53, 162, 235, 0.5)",
          "rgba(255, 99, 132, 0.5)",
        ],
        borderColor: [
          "rgba(53, 235, 99, 0.5)",
          "rgba(53, 162, 235, 0.5)",
          "rgba(255, 99, 132, 0.5)",
        ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <>
      <div
        style={{
          width: "83%",
          height: "83%",
          // marginLeft:""
        }}
      >
        <Doughnut
          options={{
            responsive: true,
            maintainAspectRatio: true,
          }}
          data={data}
        />
      </div>
    </>
  );
}

export default AgentPieChart;
