import { Button, Col, Image, Row, Tabs } from "antd";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { AiOutlineArrowRight, AiOutlineCloudDownload } from "react-icons/ai";
import { useMutation, useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { api } from "../../../services/Api";

const adbs = require("ad-bs-converter");
const { TabPane } = Tabs;

function Types() {
  const { companyId } = useParams();
  const navigate = useNavigate();

  const [params, setParams] = useState({
    billType: "Sales",
    isPost: false,
    limit: 1000,
    sort: "-updatedAt",
  });

  const [billHashMap, setBillHashmap] = useState({});
  // const getBill = () => {
  //   if (companyId.length > 10) {
  //     return api.get("/bills", {
  //       params: { company: companyId },

  //     });
  //   }
  // };

  const getBill = () => {
    if (companyId.length > 10) {
      return api.get("/bills", {
        params: { company: companyId, ...params },
      });
    }
  };

  const getCompany = () => {
    return api.get("/companies/" + companyId);
  };
  const {
    data: company_data,
    isLoading: company_loading,
    refetch: company_refetch,
  } = useQuery("company", getCompany);

  const {
    // isloading,
    isFetching,
    isLoading,
    data: data1,
    refetch: data1_refetch,
  } = useQuery("billtype", getBill);
  const [loading, setLoading] = useState(false);
  const nepaliMonthMapping = {
    1: "बैशाख",
    2: "जेठ",
    3: "आषाढ",
    4: "साउन",
    5: "भदौ",
    6: "आसोज",
    7: "कार्तिक",
    8: "मंसिर",
    9: "पुष",
    10: "माघ",
    11: "फागुन",
    12: "चैत्र",
  };

  const dateChange = (date, data) => {
    update_bill_mutate({
      id: data._id,
      createdAt: date.adDate,
    });
    /** {"bsYear":2077,
     * "bsMonth":2,
     * "bsDate":15,"weekDay":5,
     * "formattedDate":"२०७७ जेठ, १५",
     * "adDate":"2020-05-27T18:15:00.000Z",
     * "bsMonthFirstAdDate":"2020-05-13T18:15:00.000Z",
     * "bsMonthDays":32}
     **/
  };

  function convertToNepaliDate(e) {
    const key = moment(e.dateOfBillIssued).format("YYYY/MM/DD");
    const nepaliDate = adbs.ad2bs(key);
    return nepaliDate.en.strMonth;
  }
  const [dateNepali, setDateNepali] = useState("");

  useEffect(() => {
    setLoading(true);
    if (data1?.data) {
      let finalMap = {};
      data1?.data.docs.forEach((e) => {
        let temp = [];
        const hh = convertToNepaliDate(e);
        setDateNepali(hh);
        if (hh in finalMap) {
          temp = finalMap[hh];
        }
        temp.push(e);
        finalMap = {
          ...finalMap,
          [hh]: temp,
        };
      });
      setBillHashmap(finalMap);
      setLoading(false);
    }
  }, [data1]);

  useEffect(() => {
    data1_refetch();
  }, [data1_refetch, params]);

  const updateBill = (data) => {
    const payload = {
      dateOfBillIssued: data.createdAt,
    };
    return api.patch(`/bills/${data.id}`, payload);
  };
  const [deleteLoad, setDeleteLoad] = useState(false);

  const deleteBill = (billId) => {
    return api.delete(`/bills/${billId}`);
  };

  const { mutate: bill_delete_mutate, isLoading: bill_delete_loading } =
    useMutation(deleteBill, {
      onSuccess: () => {
        // navigate(-1);
        data1_refetch();
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });
  const removeBill = async (id) => {
    // setDeleteLoad(true);
    // const res = await api.delete("/bills/" + id);

    // res && message.success("Bill removed successfully");
    // setDeleteLoad(false);
    // await data1_refetch();

    Swal.fire({
      title: "Are you sure ?",
      text: "You want to delete this Bill.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Ok`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        bill_delete_mutate(id);
      }
    });

    return;
  };

  const { mutate: update_bill_mutate, isLoading: update_bill_loading } =
    useMutation(updateBill, {
      onSuccess: () => {
        data1_refetch();
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });

  if (isLoading) {
    return <h1>Loading ...</h1>;
  }

  const arrayOfBillTypes = [
    "Sales",
    "Purchase",
    "Sales Return",
    "Purchase Return",
    "Others",
  ];

  return (
    <>
      <div className="main-container">
        <div>
          <span
            className="main-title link-element"
            onClick={() => {
              navigate("/dashboard/companies");
            }}
          >
            Companies/
          </span>
          <span
            className="main-subtitle link-element"
            onClick={() => {
              navigate(-1);
            }}
          >
            {company_data?.data.name}/
          </span>
          <span className="main-subtitle">Bill Items</span>
        </div>
        <div>
          <Tabs
            defaultActiveKey="1"
            style={{ justifyContent: "spaceBetween" }}
            onTabClick={(e) =>
              setParams((prev) => ({
                ...prev,
                billType: arrayOfBillTypes[e - 1],
                isPost: false,
              }))
            }
          >
            <TabPane tab="Sales Bill" key="1">
              <Tabs
                defaultActiveKey="11"
                onTabClick={(e) =>
                  setParams((prev) => ({
                    ...prev,
                    isPost: e == 11 ? false : true,
                  }))
                }
              >
                <TabPane tab="Unposted Bills" key="11">
                  {Object.keys(billHashMap).map((key, index) => {
                    const filteredBills = billHashMap[key]?.filter(
                      (e) => e.billType === "Sales" && !e.isPost
                    );
                    if (filteredBills.length < 1) {
                      return <></>;
                    }
                    return (
                      <>
                        <h4>{dateNepali}</h4>
                        <Row gutter={24} style={{ marginTop: "1em" }}>
                          {!loading
                            ? filteredBills.map((data) => {
                                return (
                                  <Col
                                    span={4}
                                    gap={2}
                                    style={{
                                      border: "1px solid black",
                                    }}
                                  >
                                    <Image
                                      height={300}
                                      width={"100%"}
                                      className="bill-image"
                                      src={data.attachment.url}
                                      alt="bill"
                                    />
                                    <Link to={`${data._id}`}>
                                      <h3 className="bill-post">
                                        Post this bill <AiOutlineArrowRight />
                                      </h3>
                                    </Link>
                                    <br />
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        border: "1px solid black !important",
                                        paddingInline: "5px",
                                        gap: "6px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <a
                                        href={data.attachment.url}
                                        // style={{ margin: "0rem 3rem" }}
                                      >
                                        <AiOutlineCloudDownload size={40} />
                                      </a>
                                      <Button
                                        onClick={() => removeBill(data._id)}
                                        disabled={deleteLoad}
                                        style={{
                                          background: "red",
                                          color: "white",
                                        }}
                                      >
                                        DELETE
                                      </Button>
                                    </div>
                                  </Col>
                                );
                              })
                            : "Loading"}
                        </Row>
                      </>
                    );
                  })}
                </TabPane>
                <TabPane tab="Posted Bills" key="12">
                  {Object.keys(billHashMap).map((key) => {
                    const filteredBills = billHashMap[key].filter(
                      (e) => e.billType === "Sales" && e.isPost
                    );

                    if (filteredBills.length < 1) {
                      return <></>;
                    }
                    return (
                      <>
                        <strong>
                          {[
                            ...new Set(
                              filteredBills.map((data) =>
                                convertToNepaliDate(data)
                              )
                            ),
                          ].map((uniqueDate) => (
                            <span key={uniqueDate}>{uniqueDate}</span>
                          ))}
                        </strong>

                        <Row gutter={24} style={{ marginTop: "1em" }}>
                          {filteredBills.map((data) => {
                            return (
                              <Col span={4} gap={2}>
                                {/* <h2> {convertToNepaliDate(data)}</h2> */}
                                <div className="overlay-image">
                                  <Image
                                    height={300}
                                    width={"100%"}
                                    className="bill-image"
                                    src={data.attachment.url}
                                    alt="bill"
                                  />
                                  {/* <DatePicker
                                    picker="month"
                                    placeholder=""
                                    className="overlay"
                                    onChange={(value) =>
                                      update_bill_mutate({
                                        id: data._id,
                                        createdAt: value,
                                      })
                                    }
                                  /> */}
                                </div>
                                <br />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    border: "1px solid black !important",
                                    paddingInline: "5px",
                                    gap: "6px",
                                    alignItems: "center",
                                  }}
                                >
                                  <a
                                    href={data.attachment.url}
                                    // style={{ margin: "0rem 3rem" }}
                                  >
                                    <AiOutlineCloudDownload size={40} />
                                  </a>
                                  <Button
                                    onClick={() => removeBill(data._id)}
                                    disabled={deleteLoad}
                                    style={{
                                      background: "red",
                                      color: "white",
                                    }}
                                  >
                                    DELETE
                                  </Button>
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      </>
                    );
                  })}
                </TabPane>
              </Tabs>
            </TabPane>
            <TabPane tab="Purchase Bill" key="2">
              <Tabs
                defaultActiveKey="13"
                onTabClick={(e) =>
                  setParams((prev) => ({
                    ...prev,
                    isPost: e == 13 ? false : true,
                  }))
                }
              >
                <TabPane tab="Unposted Bills" key="13">
                  {Object.keys(billHashMap).map((key) => {
                    const filteredBills = billHashMap[key].filter(
                      (e) => e.billType === "Purchase" && !e.isPost
                    );
                    if (filteredBills.length < 1) {
                      return <></>;
                    }
                    return (
                      <>
                        <Row gutter={24} style={{ marginTop: "1em" }}>
                          {!loading
                            ? filteredBills?.map((data) => {
                                return (
                                  <Col span={4} gap={2}>
                                    <Image
                                      height={300}
                                      width={"100%"}
                                      className="bill-image"
                                      src={data.attachment.url}
                                      alt="bill"
                                    />
                                    <Link to={`${data._id}`}>
                                      <h3 className="bill-post">
                                        Post this bill <AiOutlineArrowRight />
                                      </h3>
                                    </Link>
                                    <br />
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        paddingInline: "5px",
                                        gap: "6px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <a
                                        href={data.attachment.url}
                                        // style={{ margin: "0rem 3rem" }}
                                      >
                                        <AiOutlineCloudDownload size={40} />
                                      </a>
                                      <Button
                                        onClick={() => removeBill(data._id)}
                                        disabled={deleteLoad}
                                        style={{
                                          background: "red",
                                          color: "white",
                                        }}
                                      >
                                        DELETE
                                      </Button>
                                    </div>
                                  </Col>
                                );
                              })
                            : "Loading..."}
                        </Row>
                      </>
                    );
                  })}
                </TabPane>

                <TabPane tab="Posted Bills" key="14">
                  {Object.keys(billHashMap).map((key) => {
                    const filteredBills = billHashMap[key].filter(
                      (e) => e.billType === "Purchase" && e.isPost
                    );
                    if (filteredBills.length < 1) {
                      return <></>;
                    }
                    return (
                      <>
                        <h4>
                          {[
                            ...new Set(
                              filteredBills.map((data) =>
                                convertToNepaliDate(data)
                              )
                            ),
                          ].map((uniqueDate) => (
                            <span key={uniqueDate}>{uniqueDate}</span>
                          ))}
                        </h4>
                        <Row gutter={24} style={{ marginTop: "1em" }}>
                          {filteredBills.map((data) => {
                            return (
                              <Col span={4} gap={2}>
                                <div className="overlay-image">
                                  <Image
                                    height={300}
                                    width={"100%"}
                                    className="bill-image"
                                    src={data.attachment.url}
                                    alt="bill"
                                  />
                                  {/* <DatePicker
                                    picker="month"
                                    placeholder=""
                                    className="overlay"
                                    onChange={(value) =>
                                      update_bill_mutate({
                                        id: data._id,
                                        createdAt: value,
                                      })
                                    }
                                  /> */}
                                </div>
                                <br />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    paddingInline: "5px",
                                    gap: "6px",
                                    alignItems: "center",
                                  }}
                                >
                                  <a
                                    href={data.attachment.url}
                                    // style={{ margin: "0rem 3rem" }}
                                  >
                                    <AiOutlineCloudDownload size={40} />
                                  </a>
                                  <Button
                                    onClick={() => removeBill(data._id)}
                                    disabled={deleteLoad}
                                    style={{
                                      background: "red",
                                      color: "white",
                                    }}
                                  >
                                    DELETE
                                  </Button>
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      </>
                    );
                  })}
                </TabPane>
              </Tabs>
            </TabPane>
            <TabPane tab="Sales Return Bill" key="3">
              <Tabs
                defaultActiveKey="15"
                onTabClick={(e) =>
                  setParams((prev) => ({
                    ...prev,
                    isPost: e == 15 ? false : true,
                  }))
                }
              >
                <TabPane tab="unposted Bills" key="15">
                  {Object.keys(billHashMap).map((key) => {
                    const filteredBills = billHashMap[key].filter(
                      (e) => e.billType === "Sales Return" && !e.isPost
                    );

                    if (filteredBills.length < 1) {
                      return <></>;
                    }
                    return (
                      <>
                        <Row gutter={24} style={{ marginTop: "1em" }}>
                          {filteredBills.map((data) => {
                            return (
                              <Col span={4} gap={2}>
                                <Image
                                  height={300}
                                  width={"100%"}
                                  className="bill-image"
                                  src={data.attachment.url}
                                  alt="bill"
                                />
                                <Link to={`${data._id}`}>
                                  <h3 className="bill-post">
                                    Post this bill <AiOutlineArrowRight />
                                  </h3>
                                </Link>
                                <br />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    paddingInline: "5px",
                                    gap: "6px",
                                    alignItems: "center",
                                  }}
                                >
                                  <a
                                    href={data.attachment.url}
                                    // style={{ margin: "0rem 3rem" }}
                                  >
                                    <AiOutlineCloudDownload size={40} />
                                  </a>
                                  <Button
                                    onClick={() => removeBill(data._id)}
                                    disabled={deleteLoad}
                                    style={{
                                      background: "red",
                                      color: "white",
                                    }}
                                  >
                                    DELETE
                                  </Button>
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      </>
                    );
                  })}
                </TabPane>

                <TabPane tab="Posted Bills" key="16">
                  {Object.keys(billHashMap).map((key) => {
                    const filteredBills = billHashMap[key].filter(
                      (e) => e.billType === "Sales Return" && e.isPost
                    );

                    if (filteredBills.length < 1) {
                      return <></>;
                    }
                    return (
                      <>
                        <strong>
                          {[
                            ...new Set(
                              filteredBills.map((data) =>
                                convertToNepaliDate(data)
                              )
                            ),
                          ].map((uniqueDate) => (
                            <span key={uniqueDate}>{uniqueDate}</span>
                          ))}
                        </strong>

                        <Row gutter={24} style={{ marginTop: "1em" }}>
                          {filteredBills.map((data) => {
                            return (
                              <Col span={4} gap={2}>
                                <div className="overlay-image">
                                  <Image
                                    height={300}
                                    width={"100%"}
                                    className="bill-image"
                                    src={data.attachment.url}
                                    alt="bill"
                                  />
                                  {/* <DatePicker
                                    picker="month"
                                    placeholder=""
                                    className="overlay"
                                    onChange={(value) =>
                                      update_bill_mutate({
                                        id: data._id,
                                        createdAt: value,
                                      })
                                    }
                                  /> */}
                                  {/* <DatePicker
                                    dateFormat={"%y %M, %d"}
                                    onDateChange={(date) =>
                                      update_bill_mutate({
                                        id: data._id,
                                        createdAt: date.adDate,
                                      })
                                    }
                                    placeholderText="From Date" //optional
                                  /> */}
                                  <br />
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      border: "1px solid black !important",
                                      paddingInline: "5px",
                                      gap: "6px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <a
                                      href={data.attachment.url}
                                      // style={{ margin: "0rem 3rem" }}
                                    >
                                      <AiOutlineCloudDownload size={40} />
                                    </a>
                                    <Button
                                      onClick={() => removeBill(data._id)}
                                      disabled={deleteLoad}
                                      style={{
                                        background: "red",
                                        color: "white",
                                      }}
                                    >
                                      DELETE
                                    </Button>
                                  </div>
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      </>
                    );
                  })}
                </TabPane>
              </Tabs>
            </TabPane>
            <TabPane tab="Purchase Return Bill" key="4">
              <Tabs
                defaultActiveKey="17"
                onTabClick={(e) =>
                  setParams((prev) => ({
                    ...prev,
                    isPost: e == 17 ? false : true,
                  }))
                }
              >
                <TabPane tab="Unposted Bills" key="17">
                  {Object.keys(billHashMap).map((key) => {
                    const filteredBills = billHashMap[key].filter(
                      (e) => e.billType === "Purchase Return" && !e.isPost
                    );
                    const nepaliMonthName = nepaliMonthMapping[key];

                    if (filteredBills.length < 1) {
                      return <></>;
                    }
                    return (
                      <>
                        <Row gutter={24} style={{ marginTop: "1em" }}>
                          {filteredBills.map((data) => {
                            return (
                              <Col span={4} gap={2}>
                                <Image
                                  height={300}
                                  width={"100%"}
                                  className="bill-image"
                                  src={data.attachment.url}
                                  alt="bill"
                                />
                                <Link to={`${data._id}`}>
                                  <h3 className="bill-post">
                                    Post this bill <AiOutlineArrowRight />
                                  </h3>
                                </Link>
                                <br />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    border: "1px solid black !important",
                                    paddingInline: "5px",
                                    gap: "6px",
                                    alignItems: "center",
                                  }}
                                >
                                  <a
                                    href={data.attachment.url}
                                    // style={{ margin: "0rem 3rem" }}
                                  >
                                    <AiOutlineCloudDownload size={40} />
                                  </a>
                                  <Button
                                    onClick={() => removeBill(data._id)}
                                    disabled={deleteLoad}
                                    style={{
                                      background: "red",
                                      color: "white",
                                    }}
                                  >
                                    DELETE
                                  </Button>
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      </>
                    );
                  })}
                </TabPane>
                <TabPane tab="Posted Bills" key="18">
                  {Object.keys(billHashMap).map((key) => {
                    const filteredBills = billHashMap[key].filter(
                      (e) => e.billType === "Purchase Return" && e.isPost
                    );
                    if (filteredBills.length < 1) {
                      return <></>;
                    }
                    const nepaliMonthName = nepaliMonthMapping[key];

                    return (
                      <>
                        <h4>
                          {[
                            ...new Set(
                              filteredBills.map((data) =>
                                convertToNepaliDate(data)
                              )
                            ),
                          ].map((uniqueDate) => (
                            <span key={uniqueDate}>{uniqueDate}</span>
                          ))}
                        </h4>

                        <Row gutter={24} style={{ marginTop: "1em" }}>
                          {filteredBills.map((data) => {
                            return (
                              <Col span={4} gap={2}>
                                <div className="overlay-image">
                                  <Image
                                    height={300}
                                    width={"100%"}
                                    className="bill-image"
                                    src={data.attachment.url}
                                    alt="bill"
                                  />
                                  {/* <DatePicker
                                    picker="month"
                                    placeholder=""
                                    className="overlay"
                                    onChange={(value) =>
                                      update_bill_mutate({
                                        id: data._id,
                                        createdAt: value,
                                      })
                                    }
                                  /> */}
                                  <br />
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      border: "1px solid black !important",
                                      paddingInline: "5px",
                                      gap: "6px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <a
                                      href={data.attachment.url}
                                      // style={{ margin: "0rem 3rem" }}
                                    >
                                      <AiOutlineCloudDownload size={40} />
                                    </a>
                                    <Button
                                      onClick={() => removeBill(data._id)}
                                      disabled={deleteLoad}
                                      style={{
                                        background: "red",
                                        color: "white",
                                      }}
                                    >
                                      DELETE
                                    </Button>
                                  </div>
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      </>
                    );
                  })}
                </TabPane>
              </Tabs>
            </TabPane>

            <TabPane tab="Others" key="5">
              <Tabs
                defaultActiveKey="19"
                onTabClick={(e) =>
                  setParams((prev) => ({
                    ...prev,
                    isPost: e == 11 ? false : true,
                  }))
                }
              >
                <TabPane tab="Unposted Bills" key="19">
                  {Object.keys(billHashMap).map((key) => {
                    const filteredBills = billHashMap[key].filter(
                      (e) => e.billType === "Others" && !e.isPost
                    );
                    if (filteredBills.length < 1) {
                      return <></>;
                    }

                    return (
                      <>
                        <Row gutter={24} style={{ marginTop: "1em" }}>
                          {filteredBills.map((data) => {
                            return (
                              <Col span={4} gap={2}>
                                <Image
                                  height={300}
                                  width={"100%"}
                                  className="bill-image"
                                  src={data.attachment.url}
                                  alt="bill"
                                />
                                <Link to={`${data._id}`}>
                                  <h3 className="bill-post">
                                    Post this bill <AiOutlineArrowRight />
                                  </h3>
                                </Link>
                                <br />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    border: "1px solid black !important",
                                    paddingInline: "5px",
                                    gap: "6px",
                                    alignItems: "center",
                                  }}
                                >
                                  <a
                                    href={data.attachment.url}
                                    // style={{ margin: "0rem 3rem" }}
                                  >
                                    <AiOutlineCloudDownload size={40} />
                                  </a>
                                  <Button
                                    onClick={() => removeBill(data._id)}
                                    disabled={deleteLoad}
                                    style={{
                                      background: "red",
                                      color: "white",
                                    }}
                                  >
                                    DELETE
                                  </Button>
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      </>
                    );
                  })}
                </TabPane>

                <TabPane tab="Posted Bills" key="20">
                  {Object.keys(billHashMap).map((key) => {
                    const filteredBills = billHashMap[key].filter(
                      (e) => e.billType === "Others" && e.isPost
                    );
                    if (filteredBills.length < 1) {
                      return <></>;
                    }

                    return (
                      <>
                        <h4>
                          {[
                            ...new Set(
                              filteredBills.map((data) =>
                                convertToNepaliDate(data)
                              )
                            ),
                          ].map((uniqueDate) => (
                            <span key={uniqueDate}>{uniqueDate}</span>
                          ))}
                        </h4>

                        <Row gutter={24} style={{ marginTop: "1em" }}>
                          {filteredBills.map((data) => {
                            return (
                              <Col span={4} gap={2}>
                                <div className="overlay-image">
                                  <Image
                                    height={300}
                                    width={"100%"}
                                    className="bill-image"
                                    src={data.attachment.url}
                                    alt="bill"
                                  />
                                  {/* <DatePicker
                                    picker="month"
                                    placeholder=""
                                    className="overlay"
                                    onChange={(value) =>
                                      update_bill_mutate({
                                        id: data._id,
                                        createdAt: value,
                                      })
                                    }
                                  /> */}
                                  <br />
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      border: "1px solid black !important",
                                      paddingInline: "5px",
                                      gap: "6px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <a
                                      href={data.attachment.url}
                                      // style={{ margin: "0rem 3rem" }}
                                    >
                                      <AiOutlineCloudDownload size={40} />
                                    </a>
                                    <Button
                                      onClick={() => removeBill(data._id)}
                                      disabled={deleteLoad}
                                      style={{
                                        background: "red",
                                        color: "white",
                                      }}
                                    >
                                      DELETE
                                    </Button>
                                  </div>
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      </>
                    );
                  })}
                </TabPane>
              </Tabs>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default Types;
