import {
  Avatar,
  Button,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Switch,
  Table,
  Tooltip,
  message,
} from "antd";
import debounce from "lodash/debounce";
import React, { useState } from "react";
import { FaUserPlus } from "react-icons/fa";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import PageSearch from "../../../components/common/PageSearch";
import { restriction } from "../../../constant/Restrictions";
import { api } from "../../../services/Api";
import { fetchUser } from "../../../services/User";
import { stringToColour } from "../../../utils/getProfileColor";
import NewsClientForm from "./NewsClientForm";

function NewsClient({ role }) {
  const [regexQuery, setRegexQuery] = useState("");
  const navigate = useNavigate();
  const [addClientVisible, setAddClientVisible] = useState(false);
  const [isClientEditModalVisible, setClientEditModalVisible] = useState(false);
  const [clientForm] = Form.useForm();
  const [clientEditForm] = Form.useForm();
  const [passwordForm] = Form.useForm();
  const [editData, setEditData] = useState({});
  const { isUserLoading, data: userData } = useQuery("user", fetchUser);
  const [params, setParams] = useState({
    sort: "-createdAt",
    page: 1,
    limit: 12,
    skip: 0,
    // status: undefined,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = (record) => {
    setEditData(record);
    setIsModalOpen(true);
  };
  const handlePasswordOk = () => {
    setIsModalOpen(false);
  };
  const handlePasswordCancel = () => {
    setIsModalOpen(false);
  };
  const showEditClient = (data) => {
    setEditData(data);
    setClientEditModalVisible(true);
  };
  const changeStatus = async (data) => {
    const payload = {
      enabled: data?.enabled,
    };
    try {
      await api.patch(`/users/${data?.record?._id}`, payload);
      client_refetch();

      // window.location.reload();
    } catch (error) {
      console.log("error updating status");
    }
  };
  const patchPassword = (data) => {
    const payload = {
      password: data.password,
    };
    return api.patch(`/users/${editData._id}/update-password`, payload);
  };

  ///edit password
  const { mutate: password_patch_mutate, isLoading: password_patch_loading } =
    useMutation(patchPassword, {
      onSuccess: () => {
        client_refetch();
        passwordForm.resetFields();
        setIsModalOpen(false);
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });

  //edit agent part
  const handleEditClient = () => {
    clientEditForm.resetFields();
    setEditData({});
    setClientEditModalVisible(false);
  };
  const patchClient = (data) => {
    return api.patch(`/users/${editData._id}`, data);
  };
  const { mutate: client_patch_mutate, isLoading: client_patch_loading } =
    useMutation(patchClient, {
      onSuccess: () => {
        client_refetch();
        clientForm.resetFields();
        setClientEditModalVisible(false);
        setEditData({});
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });
  const removeClient = (data) => {
    return api.delete(`/users/${data._id}`);
  };
  const { mutate: client_delete_mutate, isLoading: client_delete_loading } =
    useMutation(removeClient, {
      onSuccess: () => {
        client_refetch();
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });
  const handleClientDelete = (data) => {
    Swal.fire({
      title: "Are you sure ?",
      text: "You want to delete this Client.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Ok`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        client_delete_mutate(data);
      }
    });
  };

  const columns = [
    {
      title: "Full Name",
      dataIndex: "firstName",
      key: "firstName",
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      render: (_, record) => (
        <span onClick={() => navigate(record?._id)}>
          <Tooltip
            title={<div>{`${record?.firstName} ${record?.lastName}`}</div>}
            placement="top"
          >
            {record?.avatar?.url ? (
              <Avatar size="small" src={record?.avatar?.url} />
            ) : (
              <Avatar
                size="small"
                className="capital"
                style={{ backgroundColor: stringToColour(record?.userName) }}
              >
                {record?.firstName?.slice(0, 1) ?? "U"}
              </Avatar>
            )}
          </Tooltip>
          &nbsp; &nbsp;
          {`${record.firstName} ${record.lastName}`}
        </span>
      ),
    },
    {
      title: "Contact",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => (
        <a
          target="_blank"
          rel="noreferrer"
          href={`mailto:${text}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      render: (_, record) => <div>{record?.company?.name || ""}</div>,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (_, record) => (
        <div>
          {record?.address?.municipality?.label ||
            record?.address?.municipality ||
            "---"}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <Popconfirm
          title="Are you sure?"
          okText="Yes"
          cancelText="No"
          onConfirm={() => changeStatus({ enabled: !record?.enabled, record })}
        >
          <Switch checked={record?.enabled} />
        </Popconfirm>
      ),
    },
    // {
    //   title: "Actions",
    //   dataIndex: "action",
    //   key: "action",
    //   render: (text, record) => (
    //     <div>
    //       {!restriction[userData?.data.role.slug].includes("change_client") && (
    //         <Dropdown
    //           overlay={
    //             <Menu mode="horizontal">
    //               <Menu.Item
    //                 key="one"
    //                 className="edit-btn"
    //                 onClick={() => showEditClient(record)}
    //                 icon={
    //                   <FaEdit style={{ color: "blue", fontSize: "20px" }} />
    //                 }
    //               >
    //                 Edit
    //               </Menu.Item>

    //               <Menu.Item
    //                 key="two"
    //                 className="delete-btn"
    //                 onClick={() => handleClientDelete(record)}
    //                 icon={
    //                   <FaTrash style={{ color: "red", fontSize: "20px" }} />
    //                 }
    //               >
    //                 Delete
    //               </Menu.Item>
    //               <Menu.Item
    //                 key="three"
    //                 icon={
    //                   <FaUserEdit
    //                     style={{
    //                       color: "green",
    //                       fontSize: "20px",
    //                       marginRight: "8px",
    //                     }}
    //                   />
    //                 }
    //                 onClick={() => showModal(record)}
    //               >
    //                 Change Password
    //               </Menu.Item>
    //             </Menu>
    //           }
    //         >
    //           <Space>
    //             <IoEllipsisVertical
    //               style={{
    //                 cursor: "pointer",
    //                 color: "#00ABC5",
    //               }}
    //               size={20}
    //             />
    //           </Space>
    //         </Dropdown>
    //       )}
    //     </div>
    //   ),
    // },
  ];

  const getClient = () => {
    return api.get("/users", {
      params: {
        "relation_role.slug": "Feed-Reader",
        userName: `/${regexQuery.trim()}/i`,
        page: regexQuery ? 1 : params.page,
        skip: regexQuery ? 0 : params.skip,
        ...params,
      },
    });
  };
  const {
    isLoading,
    data: client_data,
    refetch: client_refetch,
  } = useQuery(["allclient", regexQuery, params, role], getClient);

  const postClient = (data) => {
    const agentdata = { ...data, role: "agent" };
    return api.post("/users", data);
  };
  const { mutate: client_add_mutate, isLoading: client_add_loading } =
    useMutation(postClient, {
      onSuccess: () => {
        client_refetch();
        clientForm.resetFields();
        setAddClientVisible(false);
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });
  const handleClientAddCancel = () => {
    clientForm.resetFields();
    setAddClientVisible(false);
  };
  const handleClientAddOk = () => {
    clientForm
      .validateFields()
      .then((values) => {
        client_add_mutate(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  const handleClientEditOk = () => {
    clientEditForm
      .validateFields()
      .then((values) => {
        client_patch_mutate(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  return (
    <>
      <div className="main-container">
        <Row className="content-apart mb-10">
          <div className="main-title">News Client</div>
          <Row>
            <PageSearch
              onChange={(e) => {
                debounce(setRegexQuery(e.target.value), 800);
              }}
              placeholder={`Search For Agent`}
            />
            {!restriction[userData?.data.role.slug].includes(
              "change_client"
            ) && (
              <Button
                style={{ borderRadius: "0.5em" }}
                type="primary"
                onClick={() => {
                  setAddClientVisible(true);
                }}
              >
                <FaUserPlus size={20} />
              </Button>
            )}
          </Row>
        </Row>

        <Table
          loading={isLoading}
          size="small"
          // onRow={(record, _) => {
          //   return {
          //     onClick: (_) => {
          //       navigate(`${record._id}`);
          //     },
          //   };
          // }}
          className="fiscal-table"
          columns={columns}
          dataSource={client_data?.data?.docs}
          pagination={{
            current: params.page,
            showSizeChanger: false,
            total: client_data?.data.totalDocs,
            pageSize: params.limit,
            onChange: (page) => {
              setParams({
                ...params,
                page,
                skip: (page - 1) * params.limit,
              });
            },
          }}
        />
      </div>
      {addClientVisible && (
        <NewsClientForm
          isModalVisible={addClientVisible}
          form={clientForm}
          okText="Create"
          handleCancel={handleClientAddCancel}
          handleOk={handleClientAddOk}
          loading={client_add_loading}
          headingText="Create new Agent"
        />
      )}
      {isClientEditModalVisible && (
        <NewsClientForm
          isModalVisible={isClientEditModalVisible}
          form={clientEditForm}
          okText="Update"
          handleCancel={handleEditClient}
          handleOk={handleClientEditOk}
          loading={client_patch_loading}
          initialValues={editData}
          headingText={`Agent`}
        />
      )}

      {isModalOpen && (
        <Modal
          title="Change Password"
          visible={isModalOpen}
          onOk={handlePasswordOk}
          onCancel={handlePasswordCancel}
          footer={[
            <Button
              loading={password_patch_loading}
              type="primary"
              htmlType="submit"
              onClick={() => {
                passwordForm
                  .validateFields()
                  .then((values) => {
                    password_patch_mutate(values);
                    message.success(
                      <div>
                        <p style={{ color: "black" }}>Password Changed!</p>
                      </div>
                    );
                  })
                  .catch((info) => {
                    console.log("Validate Failed:", info);
                  });
              }}
            >
              Change Password
            </Button>,
          ]}
        >
          <Form form={passwordForm} autoComplete="off" layout="vertical">
            <Form.Item
              label="New Password"
              name="password"
              required
              rules={[
                {
                  required: true,
                  pattern: new RegExp(
                    // eslint-disable-next-line no-useless-escape
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/
                  ),
                  message:
                    "Password length must be 6 to 10 characters with at least one uppercase, one lowercase, one number and one special character!",
                },
              ]}
              hasFeedback
            >
              <Input.Password placeholder="New Password" />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name="confirm password"
              required
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The password that you have entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm Password" />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  );
}

export default NewsClient;
