import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Input, Upload, Select, Space } from "antd";
import DebounceSelect from "../../../components/common/DebounceSelect";
import { FaInfoCircle, FaPlus, FaMinusCircle } from "react-icons/fa";
import { api } from "../../../services/Api";
import { AiOutlineUpload } from "react-icons/ai";
import TextArea from "antd/lib/input/TextArea";
import { Municipality } from "../../../constant/Municipalities";
import { Districts } from "../../../constant/Districts";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 20,
    },
    sm: {
      span: 14,
    },
  },
};

const { Option } = Select;

function CompanyForm({
  isModalVisible,
  handleCancel,
  handleOk,
  form,
  loading,
  initialValues,
  okText,
}) {
  const getUsers = async (queryString) => {
    return api
      .get("/users", {
        params: {
          "relation_role.slug": "user",
          firstName: encodeURI(`/${queryString}/i`),
        },
      })
      .then((body) =>
        body.data.docs.map((user) => ({
          label: `${user.firstName} ${user.lastName}`,
          value: user._id,
        }))
      );
  };

  const props = {
    listType: "picture",
    beforeUpload: (file) => {
      // uploadFile(file);
      return false;
    },
    showUploadList: false,
  };

  const [districtId, setId] = useState(1);
  const [districtname, setDistrictName] = useState();

  useEffect(() => {
    setDistrictName(
      Districts.filter((d, i) => d.id === initialValues?.address?.district)
        ?.label
    );
  }, [form, initialValues]);
  return (
    <Modal
      maskClosable={false}
      // title="Create a new company"
      visible={isModalVisible}
      onCancel={handleCancel}
      okText="Create"
      cancelText="Cancel"
      width={"30vw"}
      style={{ background: "white", top: "0px" }}
      className="global-modal"
      footer={[
        <Button
          loading={loading}
          key="submit"
          type="primary"
          onClick={handleOk}
        >
          {okText}
        </Button>,
      ]}
    >
      <div className="form-wrapper">
        <Form
          initialValues={{
            ...initialValues,
            address: {
              country: "Nepal",
              // district: initialValues?.address?.district
              //   ? {
              //       ...initialValues?.address?.district,
              //       label: `${initialValues?.address?.district}`,
              //       value: `${initialValues?.address?.district?.districtId}`,
              //     }
              //   : undefined,
              district: {
                label: districtname,
                value:
                  initialValues?.address?.district?.value ||
                  initialValues?.address?.district,
              },

              municipality: initialValues?.address?.municipality
                ? {
                    ...initialValues?.address?.municipality?.label,
                    label:
                      initialValues?.address?.municipality?.label ||
                      initialValues?.address?.municipality,
                    value: `${initialValues?.address?.municipality?.district_Id}`,
                  }
                : undefined,
            },
            user: initialValues?.user
              ? {
                  ...initialValues?.user,
                  label: `${initialValues?.user?.firstName}  ${initialValues?.user?.lastName}`,
                  value: `${initialValues?.user?._id}`,
                }
              : undefined,
          }}
          form={form}
          layout="vertical"
        >
          <Form.Item
            label="Company Name"
            name="name"
            required
            rules={[
              {
                required: true,
                message: "Please enter company name!",
              },
            ]}
          >
            <Input placeholder="Company Name" />
          </Form.Item>
          <Form.Item
            label="नेपाली नाम"
            name="nepaliName"
            rules={[
              {
                required: true,
                message: "कृपया कम्पनिको नेपालीमा नाम राख्नुहोस्",
              },
            ]}
            required
          >
            <Input placeholder="कम्पनिको नाम" />
          </Form.Item>
          <Form.Item
            label="Company Email"
            name="email"
            required
            rules={[
              {
                required: true,
                message: "Please enter company's email!",
              },
              {
                type: "email",
                message: "Please enter a valid email address !",
              },
            ]}
          >
            <Input type="email" placeholder="Company's Email" />
          </Form.Item>
          <Form.Item
            label="Company Phone Number"
            name="phone"
            required
            rules={[
              {
                required: true,
                message: "Please enter company's phone!",
              },
            ]}
          >
            <Input type="tel" placeholder="Company's Phone Number" />
          </Form.Item>
          {/* 
          {!initialValues && (
            <Form.Item
              label="Company Avatar"
              name="avatar"
              required
              rules={[
                {
                  required: true,
                  message: "Please select avatar of this company !",
                },
              ]}
            >
              <Upload.Dragger multiple {...props}>
                Drag files here Or
                <br />
                <br />
                <Button icon={<AiOutlineUpload />}>Upload</Button>
              </Upload.Dragger>
            </Form.Item>
          )} */}

          <Form.Item
            label="Company Pan Number"
            name="pan"
            required
            rules={[
              {
                required: true,
                message: "Please enter company's pan number !",
              },
            ]}
          >
            <Input type="number" placeholder="Company's Pan Number" />
          </Form.Item>
          <Form.Item
            label="Business Type"
            name="businessType"
            required
            rules={[
              {
                required: true,
                message: "Please enter company's business type !",
              },
            ]}
          >
            <Select>
              <Select.Option value="Solo">Solo</Select.Option>
              <Select.Option value="Partnership">Partnership</Select.Option>
              <Select.Option value="NGO/INGO">NGO/INGO</Select.Option>
              <Select.Option value="Cooperative">Cooperative</Select.Option>
              <Select.Option value="Company">Company</Select.Option>
              <Select.Option value="Others">Others</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Tax Category"
            name="taxCategory"
            required
            rules={[
              {
                required: true,
                message: "Please enter company's tax category !",
              },
            ]}
          >
            <Select>
              <Select.Option value="D1">D1</Select.Option>
              <Select.Option value="D2">D2</Select.Option>
              <Select.Option value="D3">D3</Select.Option>
              <Select.Option value="D4">D4</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Business Nature"
            name="businessNature"
            required
            rules={[
              {
                required: true,
                message: "Please enter company's business nature !",
              },
            ]}
          >
            <Select>
              <Select.Option value="Government sector">
                Government sector
              </Select.Option>
              <Select.Option value="Military sector">
                Military sector
              </Select.Option>
              <Select.Option value="International sector">
                International sector
              </Select.Option>
              <Select.Option value="Private sector">
                Private sector
              </Select.Option>
              <Select.Option value="Technology sector">
                Technology sector
              </Select.Option>
              <Select.Option value="Merchandising sector">
                Merchandising sector
              </Select.Option>
              <Select.Option value="Service sector">
                Service sector
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="User"
            name="user"
            required
            rules={[
              {
                required: true,
                message: "Please enter user for this company !",
              },
            ]}
          >
            <DebounceSelect placeholder="Select User" fetchOptions={getUsers} />
          </Form.Item>
          <Form.Item
            label="Country"
            name={["address", "country"]}
            required
            rules={[
              {
                required: true,
                message: "Please enter company's country",
              },
            ]}
          >
            <Input placeholder="Company's Country" />
          </Form.Item>
          <Form.Item label="District" name={["address", "district"]}>
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={districtId}
              onChange={(e) => setId(e)}
            >
              {Districts?.map((d, i) => {
                return (
                  <Option key={d.id} value={d.id}>
                    {d.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item label="Municipality" name={["address", "municipality"]}>
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {Municipality?.filter((el) => el.district_id === districtId)?.map(
                (m, i) => {
                  return (
                    <Option key={i} value={m.value}>
                      {m.label}
                    </Option>
                  );
                }
              )}
            </Select>
          </Form.Item>
          <br />
          <Form.List name="objectives">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields?.map((field) => (
                  <Space key={field.key} align="baseline">
                    <Form.Item name={[field.name]}>
                      <TextArea
                        className="text-text"
                        rows={2}
                        placeholder="Objective"
                      />
                    </Form.Item>
                    <FaMinusCircle onClick={() => remove(field.name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    style={
                      {
                        // left: "50%",
                      }
                    }
                    icon={<FaPlus />}
                  >
                    Add Objective
                  </Button>
                </Form.Item>
                <br />
                <br />
                <br />
              </>
            )}
          </Form.List>
          {/* <Form.Item label="Enabled" name="enabled" valuePropName="checked">
            <Switch checked />
          </Form.Item> */}
        </Form>
      </div>
    </Modal>
  );
}

export default CompanyForm;
