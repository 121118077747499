import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Input, Switch, Select } from "antd";
import DebounceSelect from "../../../components/common/DebounceSelect";
import { api } from "../../../services/Api";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useMutation } from "react-query";
import { Municipality } from "../../../constant/Municipalities";
import { Districts } from "../../../constant/Districts";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 20,
    },
    sm: {
      span: 14,
    },
  },
};
const { Option } = Select;

function NewsCategoryForm({
  isModalVisible,
  handleCancel,
  handleOk,
  form,
  loading,
  initialValues,
  okText,
  headingText,
}) {


  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);



  return (
    <Modal
      maskClosable={false}
      // title={headingText}
      visible={isModalVisible}
      onCancel={handleCancel}
      okText="Create"
      cancelText="Cancel"
      width={"30vw"}
      style={{ background: "white", top: "0px" }}
      className="global-modal"
      footer={[
        <Button
          loading={loading}
          key="submit"
          type="primary"
          onClick={handleOk}
        >
          {okText}
        </Button>,
      ]}
    >
      <div className="form-wrapper">
        <Form
      
          initialValues={{
            ...initialValues,
          }}
          form={form}
          layout="vertical"
        >
          <Form.Item
            label="News Category"
            name="name"
            required
            rules={[
              {
                required: true,
                message: "Please Enter First Name!",
              },
            ]}
          >
            <Input placeholder="place news category name" />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            required
            rules={[
              {
                required: true,
                message: "Please provide description",
              },
            ]}
          >
            <Input placeholder="Description" />
          </Form.Item>

        </Form>
      </div>
    </Modal>
  );
}

export default NewsCategoryForm;
