import React from "react";
import InventoryCard from "../../../components/common/InventoryCard";
import { Col, Row, Button } from "antd";
import Brand from "../../../assets/images/brand.png";
import Category from "../../../assets/images/category.png";
import Product from "../../../assets/images/product.png";
import Unit from "../../../assets/images/unit.png";
import Merchant from "../../../assets/images/merchant.png";
import { api } from "../../../services/Api";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Services from "../../../components/homepage/Services";
import InvenChart from "../../../components/charts/InvenChart";

const InventoryHome = () => {
  const navigate = useNavigate();

  const getTotalProducts = () => {
    return api.get("/products/count");
  };

  const {
    isLoading: total_products_loading,
    data: total_products,
    refetch: total_products_refetch,
  } = useQuery("TotalProducts", getTotalProducts);

  const getTotalBrands = () => {
    return api.get("/brands/count");
  };

  const {
    isLoading: total_brands_loading,
    data: total_brands,
    refetch: total_brands_refetch,
  } = useQuery("TotalBrands", getTotalBrands);

  const getTotalCategories = () => {
    return api.get("/categories/count");
  };

  const {
    isLoading: total_categories_loading,
    data: total_categories,
    refetch: total_categories_refetch,
  } = useQuery("TotalCategories", getTotalCategories);

  const getTotalUnits = () => {
    return api.get("/unit-of-measurements/count");
  };

  const {
    isLoading: total_units_loading,
    data: total_units,
    refetch: total_units_refetch,
  } = useQuery("TotalUnits", getTotalUnits);

  const getTotalMerchants = () => {
    return api.get("/merchants/count");
  };

  const {
    isLoading: total_merchants_loading,
    data: total_merchants,
    refetch: total_merchants_refetch,
  } = useQuery("TotalMerchants", getTotalMerchants);

  return (
    <div className="main-container">
      <h1 className="main-title">Inventory Overview</h1>
      <div>
        <Row
          style={{
            justifyContent: "space-between",
          }}
        >
          <InventoryCard
            icon={<img height={66} width={66} src={Product} alt="product" />}
            number={total_products?.data}
          >
            <h3>Products</h3>
            <Button
              type="primary"
              onClick={() => {
                navigate("/dashboard/products");
              }}
              style={{
                marginBottom: "10px",
              }}
            >
              View all products
            </Button>
          </InventoryCard>

          <InventoryCard
            icon={
              <img height={66} width={66} src={Brand} alt="product-brand" />
            }
            number={total_brands?.data}
          >
            <h3>Brands</h3>
            <Button
              type="primary"
              onClick={() => {
                navigate("/dashboard/brands");
              }}
              style={{
                marginBottom: "10px",
              }}
            >
              View all brands
            </Button>
          </InventoryCard>

          <InventoryCard
            icon={
              <img
                height={66}
                width={66}
                src={Category}
                alt="product-category"
              />
            }
            number={total_categories?.data}
          >
            <h3>Categories</h3>
            <Button
              type="primary"
              onClick={() => {
                navigate("/dashboard/productCategory");
              }}
              style={{
                marginBottom: "10px",
              }}
            >
              View all categories
            </Button>
          </InventoryCard>

          <InventoryCard
            icon={<img height={66} width={66} src={Unit} alt="product-unit" />}
            number={total_units?.data}
          >
            <h3>Product Units</h3>
            <Button
              type="primary"
              onClick={() => {
                navigate("/dashboard/uom");
              }}
              style={{
                marginBottom: "10px",
              }}
            >
              View all units
            </Button>
          </InventoryCard>

          <InventoryCard
            icon={
              <img height={66} width={66} src={Merchant} alt="product-unit" />
            }
            number={total_merchants?.data}
          >
            <h3>Merchants</h3>
            <Button
              type="primary"
              onClick={() => {
                navigate("/dashboard/merchants");
              }}
              style={{
                marginBottom: "10px",
              }}
            >
              View all merchants
            </Button>
          </InventoryCard>
        </Row>
      </div>
      <div className="mt-16">
        <Row gutter={16}>
          <Col span={24}>
            <div className="services-inv">
              <InvenChart />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default InventoryHome;
