import {
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Modal,
  Row,
  Switch,
} from "antd";
import debounce from "lodash/debounce";
import React, { useState } from "react";
import { BiBellPlus } from "react-icons/bi";
import { useMutation, useQuery } from "react-query";
import NoData from "../../components/common/NoData";
import PageSearch from "../../components/common/PageSearch";
import NotificationComponent from "../../components/NotificationComponent";
import { restriction } from "../../constant/Restrictions";
import { api } from "../../services/Api";
import { fetchUser } from "../../services/User";
import { SendPushNotification } from "../../utils/SendNotification";
import "./Notification.css"; // Import your CSS file here

function Notification() {
  const [addNotificationVisible, setAddNotificationVisible] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [regexQuery, setRegexQuery] = useState("");
  const [filterOption, setFilterOption] = useState("");
  const [isNotificationModalVisible, setIsNotificationModalVisible] =
    useState(false);
  const { isUserLoading, data: userData } = useQuery("user", fetchUser);
  const [params, setParams] = useState({
    sort: "-createdAt",
    page: 1,
    limit: 10000000,
    skip: 0,
    fromEvent: "notification.created",
  });

  const fetchNotifications = () => {
    return api.get("/notifications", {
      params: {
        ...params,
        title: encodeURI(`/${regexQuery}/i`),
        group: filterOption,
      },
    });
  };
  const fetchIndividualNotification = () => {
    return api.get("/notifications", {
      params: {
        user: userData.data?._id,
        ...params,
      },
    });
  };

  const { data: notification_user_data } = useQuery(
    ["individualNotification"],
    fetchIndividualNotification
  );
  const filteredData = notification_user_data?.data?.docs.filter(
    (e) =>
      e.global ||
      userData.data.role.slug === "superadmin" ||
      userData.data.role.slug === "moderator" ||
      userData.data.role.slug === "admin" ||
      userData.data.role.slug === "employee" ||
      userData.data.role.slug === "feedwritter" ||
      userData.data.role.slug === "agent" ||
      (e.users && e.users.some((u) => u._id === userData.data._id))
  );

  const [form] = Form.useForm();
  const agent = Form.useWatch("agent", form);
  const employees = Form.useWatch("employees", form);
  const news = Form.useWatch("news", form);
  const clients = Form.useWatch("clients", form);

  const {
    isLoading: notification_loading,
    data: notification_data,
    refetch: notification_refetch,
  } = useQuery(
    ["notifications", regexQuery, params, filterOption],
    fetchNotifications
  );

  console.log(notification_data);

  const [agentUser, setAgentUser] = useState(false);
  const [employeeOnly, setEmployeeOnly] = useState(false);
  const [newsClientOnly, setNewsClientOnly] = useState(false);
  const [ClientOnly, setClientOnly] = useState(false);

  const postNotification = async (payload) => {
    if (payload?.agent) {
      const res = await api.post("/notifications", {
        title: payload.title,
        description: payload.description,
        ...(agentUser && { group: "agent" }),
      });

      SendPushNotification(
        "/topics/agent",
        res?.data?.results[0].title,
        res?.data?.results[0].description,
        "FeedsPage"
      );
    }

    if (payload?.employees) {
      api.post("/notifications", {
        title: payload.title,
        description: payload.description,

        ...(employeeOnly && { group: "employee" }),
      });
    }

    if (payload?.clients) {
      if (selectedValues?.length > 0) {
        const res = api.post("/notifications", {
          title: payload.title,

          description: payload.description,
          group: selectedValues,
        });
        SendPushNotification(
          "/topics/client",
          res?.data?.results[0].title,
          res?.data?.results[0].description,
          "FeedsPage"
        );
      } else {
        api.post("/notifications", {
          title: payload.title,

          description: payload.description,
          ...(ClientOnly && { group: "clients" }),
        });
      }
    }
    if (payload?.news) {
      const res = api.post("/notifications", {
        title: payload.title,
        description: payload.description,
        ...(newsClientOnly && { group: "news" }),
      });
      SendPushNotification(
        "/topics/news",
        res?.data?.results[0].title,
        res?.data?.results[0].description,
        "FeedsPage"
      );
    }
  };

  const [notifictation_clicked, setNotification_clicked] = useState({});
  const {
    mutate: notification_add_mutate,
    isLoading: notification_add_loading,
  } = useMutation(postNotification, {
    onSuccess: (value) => {
      // console.log(value);
      // console.log(value?.data?.results[0]?.user?.fcmTokens);
      // console.log(value?.results?.);
      notification_refetch();
      form.resetFields();
      setAddNotificationVisible(false);

      // value?.data?.results[0]?.user?.fcmTokens?.forEach((token) =>
      //   SendPushNotification(
      //     `${token?.token}`,
      //     `${value?.data?.results[0]?.title}`,
      //     `${value?.data?.results[0]?.description}`,

      //     "",
      //     value.data.results[0]._id
      //   )
      // );
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });

  const handleCancel = () => {
    setIsNotificationModalVisible(false);
  };

  const MarkRead = (id) => {
    return api.patch(`/notifications/${id}/mark-as-read`);
  };
  const { mutate: markAsRead } = useMutation(MarkRead);

  const getCompanies = async (queryString) => {
    return api
      .get("/companies", {
        params: {
          name: encodeURI(`/${queryString}/i`),
        },
      })
      .then((body) =>
        body?.data?.docs?.map((company) => ({
          label: `${company.name}`,
          value: company._id,
        }))
      );
  };

  // TODO: tabs
  const tabItems = [
    {
      key: "0",
      label: "All",
      value: "",
    },

    {
      key: "1",
      label: "Employees",
      value: "employees",
    },
    {
      key: "2",
      label: "Agents",
      value: "agent",
    },
    {
      key: "3",
      label: "Clients",
      value: "clients",
    },

    {
      key: "4",
      label: "News",
      value: "news",
    },
  ];

  const filterTabsByRole = (tabs, role) => {
    switch (role) {
      case "admin":
        return tabs; // Admin can see all tabs
      case "superadmin":
        return tabs; // Admin can see all tabs
      case "employee":
        return tabs.filter((tab) => ["1"].includes(tab.key)); // Employees can see "All", "Employees", and "News"
      case "agent":
        return tabs.filter((tab) => ["2"].includes(tab.key));
      case "client":
        return tabs.filter((tab) => ["3"].includes(tab.key)); // Clients can see "All" and "Clients"
      case "Feed-Reader":
        return tabs.filter((tab) => ["4"].includes(tab.key)); // Clients can see "All" and "Clients"
      default:
        return tabs.filter((tab) => tab.key === "0"); // Default to only "All"
    }
  };
  const availableTabs = filterTabsByRole(tabItems, userData?.data?.role?.slug);

  // Function to toggle checkbox selection
  const handleCheckboxChange = (value) => {
    if (selectedValues.includes(value)) {
      setSelectedValues(selectedValues.filter((item) => item !== value));
    } else {
      setSelectedValues([...selectedValues, value]);
    }
  };

  return (
    <div className="main-container-N">
      <Row className="content-apart mb-10">
        <h1 className="main-title">Notification</h1>
        <Row>
          <PageSearch
            onChange={(e) => {
              debounce(setRegexQuery(e.target.value), 800);
            }}
            placeholder="Search for notifications"
          />
          {!restriction[userData?.data.role.slug].includes(
            "change_notification"
          ) && (
            <Button
              style={{ borderRadius: "0.5em" }}
              type="primary"
              onClick={() => {
                setIsNotificationModalVisible(true);
              }}
            >
              <BiBellPlus size={20} />
            </Button>
          )}

          <Modal
            visible={isNotificationModalVisible}
            okText="Send"
            onCancel={handleCancel}
            width={"30vw"}
            style={{ background: "white", top: "0px" }}
            className="global-modal"
            footer={[
              <Button
                loading={notification_add_loading}
                type="primary"
                htmlType="submit"
                onClick={() => {
                  form
                    .validateFields()
                    .then((values) => {
                      notification_add_mutate(values);
                      message.success(
                        <div>
                          <p style={{ color: "black" }}>Notification sent!</p>
                        </div>
                      );
                    })
                    .catch((info) => {
                      console.log("Validate Failed:", info);
                    });
                }}
              >
                Send Notification
              </Button>,
            ]}
          >
            <div className="form-wrapper">
              <Form
                layout="vertical"
                form={form}
                style={{ marginTop: "2rem" }}
                name="basic"
                autoComplete="off"
              >
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Please input title!",
                    },
                  ]}
                >
                  <Input placeholder="title" />
                </Form.Item>
                <Form.Item
                  label="Description"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Please input description!",
                    },
                  ]}
                >
                  <Input placeholder="description" />
                </Form.Item>
                {/* {!employees && !news && !clients && ( */}
                <Form.Item label="Agent" name="agent">
                  <Switch onChange={(checked) => setAgentUser(checked)} />
                </Form.Item>
                {/* )} */}
                {/* {!employees && !agent && !clients && ( */}
                <Form.Item label="News" name="news">
                  <Switch onChange={(checked) => setNewsClientOnly(checked)} />
                </Form.Item>
                {/* )} */}

                {/* {!agent && !news && !clients && ( */}
                <>
                  <Form.Item label="Employees" name="employees">
                    <Switch onChange={(checked) => setEmployeeOnly(checked)} />
                  </Form.Item>
                  {/* {!employees && !agent && !news && ( */}
                  <Form.Item label="Clients" name="clients">
                    <Switch onChange={(checked) => setClientOnly(checked)} />
                  </Form.Item>
                  {/* )} */}

                  {clients && (
                    <div style={{ marginBottom: "20px" }}>
                      <Checkbox
                        value="D1"
                        onChange={() => handleCheckboxChange("D1")}
                        checked={selectedValues.includes("D1")}
                      >
                        D1
                      </Checkbox>
                      <Checkbox
                        value="D2"
                        onChange={() => handleCheckboxChange("D2")}
                        checked={selectedValues.includes("D2")}
                      >
                        D2
                      </Checkbox>
                      <Checkbox
                        value="D3"
                        onChange={() => handleCheckboxChange("D3")}
                        checked={selectedValues.includes("D3")}
                      >
                        D3
                      </Checkbox>
                      <Checkbox
                        value="D4"
                        onChange={() => handleCheckboxChange("D4")}
                        checked={selectedValues.includes("D4")}
                      >
                        D4
                      </Checkbox>
                    </div>
                  )}
                </>
                {/* )} */}
              </Form>
            </div>
          </Modal>
        </Row>
      </Row>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          gap: "5rem",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            zIndex: "1000",
            backgroundColor: "#f4f4f4",
            padding: "5px",
            borderBottom: "2px solid #ddd",
            flex: 1,
            position: "sticky",
            top: 0,
            height: "fit-content",
          }}
        >
          {availableTabs?.map((item, index) => {
            return (
              <button
                onClick={() => setFilterOption(item?.value)}
                key={index}
                style={{
                  width: "100%",
                  padding: "15px",
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: filterOption === item?.value ? "#fff" : "#333",
                  backgroundColor:
                    filterOption === item?.value ? "#01ABC6" : "#fff",
                  border: "2px solid #E1F6F9",
                  borderRadius: "8px",
                  cursor: "pointer",
                  transition: "background-color 0.3s, border-color 0.3s",
                  textAlign: "left",
                  marginBottom: "10px", // Add some space between buttons
                }}
              >
                {item?.label}
              </button>
            );
          })}
        </div>

        <div
          style={{
            flex: 3,
          }}
        >
          {notification_data?.data?.docs?.length ? (
            <NotificationComponent
              userData={userData}
              notification_data={notification_data}
              filteredData={filteredData}
            />
          ) : (
            <NoData />
          )}
        </div>
      </div>
    </div>
  );
}

export default Notification;
