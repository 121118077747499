import {
  Button,
  Collapse,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Tabs,
  Upload,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import JoditEditor from "jodit-react";
import moment from "moment";
import React, { useRef, useState } from "react";
import { AiOutlineUpload } from "react-icons/ai";
import { FaMinusCircle, FaPlus } from "react-icons/fa";
import { useQuery } from "react-query";
import { fetchUser } from "../../../services/User";
import AgentMenu from "./AgentMenu";
const { TabPane } = Tabs;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 20,
    },
    sm: {
      span: 14,
    },
  },
};

const { Option } = Select;
const { Panel } = Collapse;

function AgentTaskForm({
  isModalVisible,
  handleCancel,
  handleOk,
  form,
  loading,
  initialValues,
  okText,
  headingText,
  agent = false,
  setImage,
}) {
  const [showTemplateFilled, setShowTemplateFilled] = useState(false);

  const [content, setContent] = useState(null);
  const editor = useRef(null);
  // const navigate = useNavigate();



  // const removeTemplate = (id) => {
  //   return api.delete(`/template-tasks/${id}`);
  // };

  // const { mutate: template_delete_mutate, isLoading: template_delete_loading } =
  //   useMutation(removeTemplate, {
  //     onSuccess: () => {
  //       // template_refetch();
  //       setShowTemplateFilled(false);
  //     },
  //     onError: (e) => {
  //       alert(e?.response?.data?.message);
  //     },
  //   });

  // const handleDelete = (id) => {
  //   Swal.fire({
  //     title: "Are you sure ?",
  //     text: "You want to delete this template",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonText: `Ok`,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "red",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       template_delete_mutate(id);
  //       // template_refetch();
  //     }
  //   });
  // };

  // const getUsers = async (queryString) => {
  //   return api
  //     .get("/users", {
  //       params: {
  //         firstName: encodeURI(`/${queryString}/i`),
  //       },
  //     })
  //     .then((body) =>
  //       body?.data?.docs?.map((user) => ({
  //         label: `${user.firstName} ${user.lastName}`,
  //         value: user._id,
  //       }))
  //     );
  // };

  // const getCompany = async (queryString) => {
  //   return api
  //     .get("/companies", {
  //       params: {
  //         name: encodeURI(`/${queryString}/i`),
  //       },
  //     })
  //     .then((body) =>
  //       body.data.docs.map((company) => ({
  //         label: `${company.name}`,
  //         value: company._id,
  //       }))
  //     );
  // };
  if (initialValues) {
    initialValues.dueDate = moment(initialValues.dueDate);
    const modules = initialValues.modules.map((e) => {
      return {
        ...e,
        dueDate: moment(e.dueDate),
      };
    });
    initialValues.modules = modules;
  }

  const props = {
    listType: "picture",
    onChange(fileList) {
      setImage(fileList);
      return false;
    },
    showUploadList: true,
  };
  return (
    <Modal
      maskClosable={false}
      title={headingText}
      visible={isModalVisible}
      onCancel={handleCancel}
      okText="Create"
      cancelText="Cancel"
      className="global-modal"
      width={"30vw"}
      style={{ background: "white", top: "0px", float: "right" }}
      footer={[
        <Button
          loading={loading}
          key="submit"
          type="primary"
          onClick={handleOk}
        >
          {okText}
        </Button>,
      ]}
    >
      <div className="form-wrapper">
        {!showTemplateFilled && (
          <AgentMenu
            form={form}
            setShowTemplateFilled={setShowTemplateFilled}
          />
        )}
        {showTemplateFilled && (
          <Form initialValues={initialValues} form={form} layout="vertical">
            <Form.Item label="Task Title" name="title">
              <Input disabled />
            </Form.Item>
            <Form.Item label="Company Name" name="companyName">
              <Input />
            </Form.Item>

            <Form.Item label="Password" name="password">
              <Input />
            </Form.Item>

            <Form.Item
              name="description"
              required
              label="Task Description"
              rules={[
                {
                  required: true,
                  message: "Please enter task's description !",
                },
              ]}
            >
              {showTemplateFilled ? (
                <JoditEditor
                  ref={editor}
                  value={initialValues?.description} // Set the initial value here
                  readOnly // Disable editing if it's a template
                />
              ) : (
                <TextArea placeholder="Description" />
              )}
            </Form.Item>
            <Form.Item
              name="priority"
              required
              label="Task Priority"
              rules={[
                {
                  required: true,
                  message: "Please select priority !",
                },
              ]}
            >
              <Select placeholder="Task Priority">
                <Option value="Low">Low</Option>
                <Option value="Medium">Medium</Option>
                <Option value="High">High</Option>
              </Select>
            </Form.Item>
            {/* 
                {!agent ? (
                  <Form.Item label="Company" name="company">
                    <DebounceSelect
                      className="mb-10"
                      fetchOptions={getCompany}
                      placeholder="Company"
                    />
                  </Form.Item>
                ) : (
                  <Form.Item label="Company" name="company">
                    <Input />
                  </Form.Item>
                )} */}
            <Form.Item label="Start Date" name="startDate">
              <DatePicker placeholder="Select Start Date" />
            </Form.Item>
            <Form.Item label="Due Date" name="dueDate">
              <DatePicker placeholder="Select Due Date" />
            </Form.Item>

            {/* {!agent && ( */}
            <Form.List name="modules">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field) => (
                    <Row
                      style={{ justifyContent: "start" }}
                      key={field.key}
                      align="baseline"
                      hidden={agent}
                    >
                      <Form.Item noStyle>
                        <Form.Item name={[field.name, "title"]}>
                          <TextArea
                            className="text-text"
                            rows={2}
                            placeholder="Module Title"
                          />
                        </Form.Item>
                      </Form.Item>
                      <FaMinusCircle onClick={() => remove(field.name)} />
                    </Row>
                  ))}
                  <Form.Item hidden={agent}>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<FaPlus />}
                    >
                      Add Module
                    </Button>
                  </Form.Item>

                  <h4>Add Task Attacshments:</h4>
                  <Upload.Dragger multiple {...props}>
                    Drag files here Or
                    <br />
                    <br />
                    <Button
                      // loading={upload_add_loading}
                      icon={<AiOutlineUpload />}
                    >
                      Upload
                    </Button>
                  </Upload.Dragger>
                </>
              )}
            </Form.List>
            {/* )} */}
          </Form>
        )}
      </div>
    </Modal>
  );
}

export default AgentTaskForm;
