import { Button, Dropdown, Form, Menu, Row, Space, Table } from "antd";
import debounce from "lodash/debounce";
import React, { useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { IoEllipsisVertical } from "react-icons/io5";
import { TiDocumentAdd } from "react-icons/ti";
import { useMutation, useQuery } from "react-query";
import Swal from "sweetalert2";
import PageSearch from "../../../components/common/PageSearch";
import { restriction } from "../../../constant/Restrictions";
import { api } from "../../../services/Api";
import { fetchUser } from "../../../services/User";
import NewsCategoryForm from "./NewsCategoryForm";

function NewsCategory() {
  const [regexQuery, setRegexQuery] = useState("");
  const [addClientVisible, setAddClientVisible] = useState(false);
  const [isClientEditModalVisible, setClientEditModalVisible] = useState(false);
  const [clientForm] = Form.useForm();
  const [clientEditForm] = Form.useForm();
  const [passwordForm] = Form.useForm();
  const [editData, setEditData] = useState({});
  const { isUserLoading, data: userData } = useQuery("user", fetchUser);
  const [params, setParams] = useState({
    sort: "-createdAt",
    page: 1,
    limit: 15,
    skip: 0,
    status: undefined,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handlePasswordOk = () => {
    setIsModalOpen(false);
  };
  const handlePasswordCancel = () => {
    setIsModalOpen(false);
  };
  const showEditClient = (data) => {
    setEditData(data);
    setClientEditModalVisible(true);
  };
  const handleEditClient = () => {
    clientEditForm.resetFields();
    setEditData({});
    setClientEditModalVisible(false);
  };

  const patchNewsCategory = (data) => {
    return api.patch(`/feed-categories/${editData?.key}`, data);
  };
  const {
    mutate: newscategory_patch_mutate,
    isLoading: newscategory_patch_loading,
  } = useMutation(patchNewsCategory, {
    onSuccess: () => {
      newscategory_refetch();
      clientForm.resetFields();
      setClientEditModalVisible(false);
      setEditData({});
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });

  const removeClient = (data) => {
    return api.delete(`/feed-categories/${data}`);
  };
  const { mutate: client_delete_mutate, isLoading: client_delete_loading } =
    useMutation(removeClient, {
      onSuccess: () => {
        newscategory_refetch();
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });
  const handleNewsDelete = (data) => {
    Swal.fire({
      title: "Are you sure ?",
      text: "You want to delete this Client.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Ok`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        client_delete_mutate(data?.key);
      }
    });
  };

  const columns = [
    {
      title: "News Category",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => <span>{text}</span>,
    },

    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div>
          {!restriction[userData?.data.role.slug].includes(
            "change_news_category"
          ) && (
            <Dropdown
              overlay={
                <Menu mode="horizontal">
                  <Menu.Item
                    key="one"
                    className="edit-btn"
                    onClick={() => showEditClient(record)}
                    icon={
                      <FaEdit style={{ color: "blue", fontSize: "20px" }} />
                    }
                  >
                    Edit
                  </Menu.Item>

                  <Menu.Item
                    key="two"
                    className="delete-btn"
                    onClick={() => handleNewsDelete(record)}
                    icon={
                      <FaTrash style={{ color: "red", fontSize: "20px" }} />
                    }
                  >
                    Delete
                  </Menu.Item>
                </Menu>
              }
            >
              <Space>
                <IoEllipsisVertical
                  style={{
                    cursor: "pointer",
                    color: "#00ABC5",
                  }}
                  size={20}
                />
              </Space>
            </Dropdown>
          )}
        </div>
      ),
    },
  ];

  const getNewsCategories = () => {
    return api.get("/feed-categories", {
      params: {
        "relation_role.slug": "user",
        name: `/${regexQuery.trim()}/i`,
      },
    });
  };
  const {
    isLoading,
    data,
    refetch: newscategory_refetch,
  } = useQuery(["allclient", regexQuery], getNewsCategories);

  const postNewsCategories = (data) => {
    return api.post(`/feed-categories`, data);
  };
  const { mutate: news_category, isLoading: newscategory_add_loading } =
    useMutation(postNewsCategories, {
      onSuccess: () => {
        newscategory_refetch();
        clientForm.resetFields();
        setAddClientVisible(false);
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });
  const handleClientAddCancel = () => {
    clientForm.resetFields();
    setAddClientVisible(false);
  };
  const handleClientAddOk = () => {
    clientForm
      .validateFields()
      .then((values) => {
        news_category(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  const handleClientEditOk = () => {
    clientEditForm
      .validateFields()
      .then((values) => {
        newscategory_patch_mutate(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const columnData = [];

  data?.data?.docs?.forEach((f) => {
    const temp = {
      key: f?.id?.toString(),
      name: f?.name,
      description: f?.description,
    };
    columnData.push(temp);
  });

  return (
    <>
      <div className="main-container">
        <Row className="content-apart mb-10">
          <div className="main-title">News Category</div>
          <Row>
            <PageSearch
              onChange={(e) => {
                debounce(setRegexQuery(e.target.value), 800);
              }}
              placeholder="Search for news category"
            />
            {!restriction[userData?.data.role.slug].includes(
              "change_news_category"
            ) && (
              <Button
                style={{ borderRadius: "0.5em" }}
                type="primary"
                onClick={() => {
                  setAddClientVisible(true);
                }}
              >
                <TiDocumentAdd size={20} />
              </Button>
            )}
          </Row>
        </Row>

        <Table
          loading={isLoading}
          size="small"
          onRow={(record, rowIndex) => {}}
          className="fiscal-table"
          columns={columns}
          pagination={{ pageSize: 15 }}
          dataSource={columnData}
        />
      </div>
      {addClientVisible && (
        <NewsCategoryForm
          isModalVisible={addClientVisible}
          form={clientForm}
          okText="Create"
          handleCancel={handleClientAddCancel}
          handleOk={handleClientAddOk}
          loading={newscategory_add_loading}
          headingText="Create new client"
        />
      )}
      {isClientEditModalVisible && (
        <NewsCategoryForm
          isModalVisible={isClientEditModalVisible}
          form={clientEditForm}
          okText="Update"
          handleCancel={handleEditClient}
          handleOk={handleClientEditOk}
          loading={newscategory_patch_loading}
          initialValues={editData}
          headingText="Edit client"
        />
      )}
    </>
  );
}

export default NewsCategory;
