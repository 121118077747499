import { Button, Card, Form, Input, Typography, message } from "antd";
import "antd/dist/antd.css";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo2.png";
import { api } from "../../services/Api";

const { Title, Text } = Typography;

const ResetPasswordPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const resetCode = queryParams.get("code");
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const handleFinish = async (values) => {
    setLoading(true);
    try {
      const response = await api.post("/auth/reset-password", {
        verificationCode: resetCode,
        password: values.newPassword,
      });

      console.log(response);
      if (response.data.success) {
        message.success("Password has been reset successfully.");
        navigate("/");
      } else {
        const data = await response.json();
        message.error(
          data.message || "An error occurred. Please try again later."
        );
      }
    } catch (error) {
      message.error("Network error. Please try again later.");
    }
    setLoading(false);
  };

  const validateNewPassword = (_, value) => {
    if (value && value.length < 6) {
      return Promise.reject(
        new Error("Password must be at least 6 characters long")
      );
    }
    return Promise.resolve();
  };

  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("newPassword") === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("The two passwords do not match!"));
    },
  });

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    backgroundColor: "#f0f2f5",
    padding: "20px",
  };

  const cardStyle = {
    width: "100%",
    maxWidth: "400px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  };

  const formStyle = {
    padding: "20px",
    textAlign: "center",
  };

  const buttonStyle = {
    width: "100%",
    backgroundColor: "#1890ff",
    borderColor: "#1890ff",
    color: "#fff",
  };

  const logoStyle = {
    width: "100px",
    marginBottom: "20px",
  };

  return (
    <div style={containerStyle}>
      <Card style={cardStyle}>
        <div style={formStyle}>
          <img src={logo} alt="Logo" style={logoStyle} />
          <Title level={3} style={{ marginBottom: "20px" }}>
            Reset Password
          </Title>
          <Text
            type="secondary"
            style={{ display: "block", marginBottom: "20px" }}
          >
            Enter your new password to reset your current password.
          </Text>
          <Form name="reset_password" onFinish={handleFinish} layout="vertical">
            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: "Please input your new password!",
                },
                { validator: validateNewPassword },
              ]}
            >
              <Input.Password placeholder="Enter your new password" />
            </Form.Item>
            <Form.Item
              label="Confirm New Password"
              name="confirmPassword"
              dependencies={["newPassword"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your new password!",
                },
                validateConfirmPassword,
              ]}
            >
              <Input.Password placeholder="Confirm your new password" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={buttonStyle}
                loading={loading}
              >
                Reset Password
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default ResetPasswordPage;
