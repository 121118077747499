import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Input, Switch, Select } from "antd";
import DebounceSelect from "../../../components/common/DebounceSelect";
import { api } from "../../../services/Api";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useMutation } from "react-query";
import { Municipality } from "../../../constant/Municipalities";
import { Districts } from "../../../constant/Districts";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 20,
    },
    sm: {
      span: 14,
    },
  },
};
const { Option } = Select;

function EmployeeForm({
  isModalVisible,
  handleCancel,
  handleOk,
  form,
  loading,
  initialValues,
  okText,
  headingText,
}) {
  // eslint-disable-next-line no-use-before-define
  const [avatar, setAvatar] = useState("default");
  const getRoles = async (queryString) => {
    return api
      .get("/roles", {
        params: {
          "slug!": "user,applicationadministrator",
          name: encodeURI(`/${queryString}/i`),
        },
      })
      .then((body) =>
        body.data.docs.map((role) => ({
          label: `${role.name}`,
          value: role._id,
        }))
      );
  };

  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const [districtId, setId] = useState(1);

  // const uploadImage = (avatar) => {
  //   update_profile_mutate({ avatar: avatar });
  // };

  // const uploadEmployeeImage = (data) => {
  //   const payload = {
  //     avatar: data.avatar,
  //   };
  //   const formData = new FormData();
  //   formData.append("file", data.avatar);
  //   const config = {
  //     headers: {
  //       "content-type": "multipart/form-data",
  //     },
  //   };
  //   return api.post(
  //     `/upload/file`,
  //     formData,
  //     config,
  //     payload
  //   );
  // };

  // const { mutate: update_profile_mutate, isLoading: update_profile_loading } =
  //   useMutation(uploadEmployeeImage, {
  //     onSuccess: (res) => {
  //       setAvatar(res?.data?.id);
  //       console.log(res?.data?.id)
  //     },
  //     onError: (e) => {
  //       alert(e?.response?.data?.message);
  //     },
  //   });

  return (
    <Modal
      maskClosable={false}
      // title={headingText}
      visible={isModalVisible}
      onCancel={handleCancel}
      okText="Create"
      cancelText="Cancel"
      width={"30vw"}
      style={{ background: "white", top: "0px" }}
      className="global-modal"
      footer={[
        <Button
          loading={loading}
          key="submit"
          type="primary"
          onClick={handleOk}
        >
          {okText}
        </Button>,
      ]}
    >
      <div className="form-wrapper">
        <Form
          initialValues={{
            ...initialValues,
            address: {
              country: "Nepal",
            },
          }}
          form={form}
          layout="vertical"
        >
          <Form.Item
            label="Employee First Name"
            name="firstName"
            required
            rules={[
              {
                required: true,
                message: "Please Enter First Name!",
              },
            ]}
          >
            <Input placeholder="First Name" />
          </Form.Item>
          <Form.Item
            label="Employee Last Name"
            name="lastName"
            required
            rules={[
              {
                required: true,
                message: "Please Last Name!",
              },
            ]}
          >
            <Input placeholder="Last Name" />
          </Form.Item>
          <Form.Item
            label="Employee User Name"
            name="userName"
            required
            rules={[
              {
                required: true,
                message: "Please Enter User Name!",
              },
            ]}
          >
            <Input placeholder="User Name" />
          </Form.Item>
          <Form.Item
            label="Employee Email"
            name="email"
            required
            rules={[
              {
                required: true,
                message: "Please enter employee's email!",
              },
              {
                type: "email",
                message: "Please enter a valid email address !",
              },
            ]}
          >
            <Input type="email" placeholder="Employee's Email" />
          </Form.Item>
          <Form.Item
            label="Employee Password"
            name="password"
            required
            rules={[
              {
                required: true,
                pattern: new RegExp(
                  // eslint-disable-next-line no-useless-escape
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/
                ),
                message:
                  "Password length must be 6 to 10 characters with at least one uppercase, one lowercase, one number and one special character!",
              },
            ]}
          >
            <div style={{ display: "flex" }}>
              <Input
                placeholder="Employee's Password"
                type={showPassword ? "text" : "password"}
              />
              <Button
                style={{
                  borderRadius: 8,
                  marginLeft: 10,
                  padding: 5,
                }}
                icon={
                  !showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />
                }
                onClick={toggleShowPassword}
              ></Button>
            </div>
          </Form.Item>
          <Form.Item
            label="Employee Phone Number"
            name="phoneNumber"
            required
            rules={[
              {
                required: true,
                message: "Please enter employee's phone!",
              },
            ]}
          >
            <Input type="tel" placeholder="Employee's Phone Number" />
          </Form.Item>
          {/* <Form.Item
            name="avatar"
            label="Employee Avatar"
            required
          >
            <Input
              // type="hidden"
              value={avatar}
            />
            <input
              id="file-input"
              type="file"
              className="hidden"
              accept="image/png, image/jpeg"
              onChange={(e) => {
                uploadImage(e.target.files[0]);
              }}
            />
          </Form.Item> */}
          <Form.Item
            label="Employee Role"
            name="role"
            required
            rules={[
              {
                required: true,
                message: "Please select role of this employee !",
              },
            ]}
          >
            <DebounceSelect placeholder="Select Role" fetchOptions={getRoles} />
          </Form.Item>
          <Form.Item
            label="Employee Country"
            name={["address", "country"]}
            required
            rules={[
              {
                required: true,
                message: "Please enter employee's country",
              },
            ]}
          >
            <Input placeholder="Employee's Country" />
          </Form.Item>
          <Form.Item label="Employee District" name={["address", "district"]}>
            <Select
              showSearch
              value={districtId}
              onChange={(value) => setId(value)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {Districts?.map((d) => (
                <Option key={d.id} value={d.id}>
                  {d.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Employee Municipality"
            name={["address", "municipality"]}
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {Municipality?.filter((el) => el.district_id === districtId)?.map(
                (m) => (
                  <Option key={m.value} value={m.value}>
                    {m.label}
                  </Option>
                )
              )}
            </Select>
          </Form.Item>
          <Form.Item
            label="Send invitation mail"
            name="sendInvitationByEmail"
            valuePropName="checked"
          >
            <Switch defaultChecked checked />
          </Form.Item>
          <Form.Item
            label="Send welcome mail"
            name="sendWelcomeEmail"
            valuePropName="checked"
          >
            <Switch defaultChecked checked />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}

export default EmployeeForm;
