import { Modal, Button, Form, Select, DatePicker, Switch } from "antd";
import React from "react";
import DebounceSelect from "../../../components/common/DebounceSelect";
import { api } from "../../../services/Api";

function GenerateReportModal({
  visible,
  handleCancel,
  handleOk,
  form,
  handleReset,
}) {
  const getCategories = async (queryString) => {
    const body = await api.get("/categories", {
      params: {
        name: encodeURI(`/${queryString}/i`),
      },
    });
    return body.data.docs.map((category) => ({
      label: `${category.name}`,
      value: category._id,
    }));
  };
  const getVendor = async (queryString) => {
    const body = await api.get("/merchants", {
      params: {
        pan: encodeURI(`/${queryString}/i`),
      },
    });
    return body.data.docs.map((merchant) => ({
      label: `(${merchant.pan}) ${merchant.firstName} ${merchant.lastName}`,
      value: merchant._id,
    }));
  };
  const vendorWise = Form.useWatch("vendorWise", form);
  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      okText="Create"
      cancelText="Cancel"
      width={"30vw"}
      style={{ background: "white", top: "0px" }}
      className="global-modal"
      footer={[
        <Button key="submit" type="primary" onClick={handleOk}>
          Apply Filter
        </Button>,
        <Button
          //   key="submit"
          type="primary"
          onClick={handleReset}
        >
          Reset Fields
        </Button>,
      ]}
    >
      <div className="form-wrapper">
        <Form form={form} layout="vertical">
          <Form.Item label="From Date" name="updatedAt>">
            <DatePicker picker="year" defaultValue={undefined} />
          </Form.Item>
          <Form.Item label="To Date" name="updatedAt<">
            <DatePicker picker="year" defaultValue={undefined} />
          </Form.Item>
          <Form.Item label="Transaction Type" name="relation_bill.billType">
            <Select>
              <Select.Option value="Sales">Sales</Select.Option>
              <Select.Option value="Purchase">Purchase</Select.Option>
              <Select.Option value="Sales Return">Sales Return</Select.Option>
              <Select.Option value="Purchase Return">
                Purchase Return
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Category" name="relation_product.category">
            <DebounceSelect
              fetchOptions={getCategories}
              placeholder="Product Category"
            />
          </Form.Item>
          <Form.Item
            label="Vendor Wise"
            name="vendorWise"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          {vendorWise && (
            <Form.Item label="Vendor" name="relation_bill.vendor">
              <DebounceSelect
                fetchOptions={getVendor}
                placeholder="Vendor Pan"
              />
            </Form.Item>
          )}
        </Form>
      </div>
    </Modal>
  );
}

export default GenerateReportModal;
