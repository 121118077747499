import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.less";
// import Homepage from "./pages/Homepage";
import { Spin } from "antd";
import { initializeApp } from "firebase/app";
import jwtDecode from "jwt-decode";
import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import Loading from "./components/common/Loading";
import ResetPasswordPage from "./components/login/ChangePassword";
import ResetPasswordForm from "./components/login/ResetPassword";
import { FirebaseConfig } from "./firebase";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Redirect from "./pages/Redirect";

Spin.setDefaultIndicator(<Loading />);
const queryClient = new QueryClient();

export const app = initializeApp(FirebaseConfig);

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Convert to seconds
      if (decodedToken.exp > currentTime) {
        navigate(
          window.location.pathname === "/"
            ? "/dashboard"
            : window.location.pathname
        );
      } else {
        localStorage.removeItem("token");
        navigate("/");
      }
    }
    // else {
    //   navigate("/");
    // }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />
          {/* <Route path="/login" element={<Login />} /> */}
          <Route path="/redirect/:provider" element={<Redirect />} />
          <Route path="/dashboard/*" element={<Dashboard />} />
          <Route path="/forget-password" element={<ResetPasswordForm />} />
          <Route path="/reset-password-page" element={<ResetPasswordPage />} />
        </Routes>
      </div>
    </QueryClientProvider>
  );
}

export default App;
