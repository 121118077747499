import React from "react";
import ReactQuill from "react-quill";

export default function Editor({
  id = "minimal-quill",
  error,
  value,
  onChange,
  simple = false,
  helperText,
  sx,
  showToolbar = true,
  placeholder = "Write something...",
  ...other
}) {
  const modules = {
    ...(showToolbar
      ? {
          toolbar: {
            container: [
              [{ font: [] }],
              [{ header: [1, 2, false] }],
              [{ list: "ordered" }, { list: "bullet" }],
              ["bold", "italic", "underline", "strike"],
              [{ color: [] }, { background: [] }],
              [{ script: "sub" }, { script: "super" }],
              ["blockquote", "code-block"],
              ["link", "image"],
              [{ align: [] }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ direction: "rtl" }],
              ["clean"], // remove formatting button
            ],
          },
        }
      : { toolbar: false }),
  };

  const formats = [
    "font",
    "header",
    "list",
    "bullet",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "blockquote",
    "code-block",
    "link",
    "image",
    "align",
    "size",
    "indent",
    "direction",
  ];

  return (
    <>
      <ReactQuill
        value={value}
        onChange={onChange}
        modules={modules}
        formats={formats}
        placeholder={placeholder}
        {...other}
        theme="snow"
      />
    </>
  );
}
