import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import React from "react";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

function PieChart({ piechartData }) {
  const completedTask = piechartData?.filter(
    (data) => data.status === "Completed"
  );
  const pendingTask = piechartData?.filter((data) => data.status === "Pending");
  const ongoingTask = piechartData?.filter((data) => data.status === "Ongoing");
  const data = {
    labels: ["completed", "ongoing", "pending"],
    datasets: [
      {
        label: "# of Votes",
        data: [completedTask?.length, ongoingTask?.length, pendingTask?.length],
        backgroundColor: [
          "rgba(53, 235, 99, 0.5)",
          "rgba(53, 162, 235, 0.5)",
          "rgba(255, 99, 132, 0.5)",
        ],
        borderColor: [
          "rgba(53, 235, 99, 0.5)",
          "rgba(53, 162, 235, 0.5)",
          "rgba(255, 99, 132, 0.5)",
        ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <>
      <div
        style={{
          width: "83%",
          height: "83%",
          // marginLeft:""
        }}
      >
        <Doughnut
          options={{
            responsive: true,
            maintainAspectRatio: true,
          }}
          data={data}
        />
      </div>
    </>
  );
}

export default PieChart;
