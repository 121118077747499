import React from "react";
import { useQuery } from "react-query";
import { api } from "../../services/Api";

function FetchAssignedTask({ url, id }) {
  const { data } = useQuery([id, id], (id) => {
    const ID = id.queryKey[1];
    return api.get(url, {
      params: {
        assignee: ID,
      },
    });
  });
  return (
    <>
      <div>{data?.data}</div>
    </>
  );
}

export default FetchAssignedTask;
