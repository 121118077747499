import { Button, Col, Form, Input, Row, Select, Switch } from "antd";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import DebounceSelect from "../../../components/common/DebounceSelect";
import Loading from "../../../components/common/Loading";
import { api } from "../../../services/Api";
import MerchantForm from "../merchant/MerchantForm";

import moment from "moment";

import DatePicker from "@kaizer433/react-nepali-datepicker";
import "@kaizer433/react-nepali-datepicker/dist/index.css";

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 20,
    },
    sm: {
      span: 14,
    },
  },
};
function Step1Form({ billId, next }) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const postMerchant = (data) => {
    return api.post("/merchants", data);
  };
  const { mutate: merchant_add_mutate, isLoading: merchant_add_loading } =
    useMutation(postMerchant, {
      onSuccess: () => {
        merchantForm.resetFields();
        setAddMerchantVisible(false);
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });
  const handleMerchantAddCancel = () => {
    merchantForm.resetFields();
    setAddMerchantVisible(false);
  };
  const handleMerchantAddOk = () => {
    merchantForm
      .validateFields()
      .then((values) => {
        merchant_add_mutate(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  const getBill = () => {
    return api.get("/bills/" + billId);
  };
  const [addMerchantVisible, setAddMerchantVisible] = useState(false);
  const [merchantForm] = Form.useForm();

  const getMerchant = async (queryString) => {
    const body = await api.get("/merchants", {
      params: {
        pan: encodeURI(`/${queryString}/i`),
      },
    });
    return body.data.docs.map((merchant) => ({
      label: `(${merchant.pan}) ${merchant.firstName} ${merchant.lastName}`,
      value: merchant._id,
    }));
  };

  const {
    data: bill_data,
    isLoading: bill_loading,
    refetch: bill_refetch,
  } = useQuery("singleBill", getBill);

  const updateBill = async (data) => {
    return api.patch(`/bills/${billId}`, data);
  };

  const deleteBill = () => {
    return api.delete(`/bills/${billId}`);
  };

  const dateChange = (d) => {
    form.setFieldsValue({
      dateOfBillIssued: d.adDate,
    });
  };
  const handleDate = ({ bsDate }) => {
    // Check if bsDate is a valid date
    if (bsDate) {
      const date = new Date(bsDate);
      form.setFieldsValue({
        dateOfBillIssued: bsDate,
      });
    }
  };

  const { mutate: bill_delete_mutate, isLoading: bill_delete_loading } =
    useMutation(deleteBill, {
      onSuccess: () => {
        navigate(-1);
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });

  const handleBillDelete = () => {
    Swal.fire({
      title: "Are you sure ?",
      text: "You want to delete this Bill.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Ok`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        bill_delete_mutate();
      }
    });
  };

  const { mutate: bill_patch_mutate, isLoading: bill_patch_loading } =
    useMutation(updateBill, {
      onSuccess: () => {
        bill_refetch();
        next();
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });
  const handleBillUpdate = () => {
    form
      .validateFields()
      .then((values) => {
        bill_patch_mutate(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  if (bill_loading) {
    return <Loading />;
  }
  return (
    <div style={{ marginTop: "2rem", marginBottom: "4rem" }}>
      <Row gutter={30}>
        <Col span={24}>
          <Form
            initialValues={{
              ...bill_data?.data,
              vendor: bill_data?.data?.vendor
                ? {
                    ...bill_data?.data?.vendor,
                    label: `(${bill_data?.data?.vendor?.pan}) ${bill_data?.data?.vendor?.firstName} ${bill_data?.data?.vendor?.lastName}`,
                    value: bill_data?.data?.vendor?._id,
                  }
                : undefined,
              customer: bill_data?.data?.customer
                ? {
                    ...bill_data?.data?.customer,
                    label: `(${bill_data?.data?.customer?.pan}) ${bill_data?.data?.customer?.firstName} ${bill_data?.data?.customer?.lastName}`,
                    value: bill_data?.data?.customer?._id,
                  }
                : undefined,
              dateOfBillIssued: bill_data?.data?.dateOfBillIssued
                ? moment(bill_data?.data?.dateOfBillIssued)
                : undefined,
            }}
            {...formItemLayout}
            form={form}
            layout="horizontal"
          >
            <Form.Item
              label="Vat Attractive"
              name="vatAttractive"
              valuePropName="checked"
            >
              <Switch defaultChecked checked />
            </Form.Item>
            <Form.Item
              label="Vat Exempt"
              name="vatExempt"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>

            <Form.Item
              label="Invoice Number"
              name="invoiceNo"
              required
              // tooltip={{
              //   title: "Enter the Invoice Number of bill",
              //   icon: <FaInfoCircle />,
              // }}
              rules={[
                {
                  required: true,
                  message: "Please enter Invoice Number of bill !",
                },
              ]}
            >
              <Input placeholder="Invoice Number" />
            </Form.Item>

            <Form.Item label="Bill Type" name="billType" required>
              <Select>
                <Option value="Sales">Sales</Option>
                <Option value="Purchase">Purchase</Option>
                <Option value="Sales Return">Sales Return</Option>
                <Option value="Purchase Return">Purchase Return</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Bill Category"
              name="billCategory"
              required
              rules={[
                {
                  required: true,
                  message: "Please enter billCategory of bill !",
                },
              ]}
            >
              <Select placeholder="Choose Bill Category">
                <Option value="Inventory">Inventory</Option>
                <Option value="Repair and maintenance">
                  Repair and Maintenance
                </Option>
                <Option value="Fixed Assets">Fixed Assets</Option>
                <Option value="Others">Others</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Bill Issued Date"
              name="dateOfBillIssued"
              required
              rules={[
                {
                  required: true,
                  message: "Please enter dateOfBillIssued of bill !",
                },
              ]}
            >
              {/* <Calendar
                onChange={handleDate}
                theme="default"
                style={{ zIndex: 2, padding: 15 }}
                className="border"
              /> */}
              <DatePicker
                dateFormat={"%y %M, %d"}
                onDateChange={(date) => dateChange(date)}
                placeholderText="From Date" //optional
              />
            </Form.Item>
            {bill_data?.data.billType.includes("Sales") ? (
              <Form.Item
                label="Customer(PAN)"
                name="customer"
                // required
                // rules={[
                //   {
                //     required: true,
                //     message: "Please enter customer(pan) of bill !",
                //   },
                // ]}
              >
                <DebounceSelect
                  placeholder="Customer Pan"
                  fetchOptions={getMerchant}
                >
                  <Button
                    style={{
                      marginLeft: "4px",
                    }}
                    loading={bill_patch_loading}
                    key="submit"
                    type="primary"
                    onClick={() => {
                      setAddMerchantVisible(true);
                    }}
                  >
                    +
                  </Button>
                </DebounceSelect>
              </Form.Item>
            ) : (
              <Form.Item
                label="Vendor(PAN)"
                name="vendor"
                required
                rules={[
                  {
                    required: true,
                    message: "Please enter vendor(pan) of bill !",
                  },
                ]}
              >
                <DebounceSelect
                  placeholder="Vendor Pan"
                  fetchOptions={getMerchant}
                >
                  <Button
                    style={{
                      marginLeft: "4px",
                    }}
                    loading={bill_patch_loading}
                    key="submit"
                    type="primary"
                    onClick={() => {
                      setAddMerchantVisible(true);
                    }}
                  >
                    +
                  </Button>
                </DebounceSelect>
              </Form.Item>
            )}

            <Button
              loading={bill_patch_loading}
              key="submit"
              type="primary"
              onClick={handleBillUpdate}
              style={{
                marginRight: "10px",
                float: "right",
              }}
            >
              Update Bill
            </Button>

            <Button
              style={{
                marginLeft: "10px",
              }}
              key="submit"
              type="primary"
              onClick={() => navigate(-1)}
            >
              Back to Bills
            </Button>
          </Form>
        </Col>
      </Row>
      {addMerchantVisible && (
        <MerchantForm
          isModalVisible={addMerchantVisible}
          form={merchantForm}
          okText="Create"
          handleCancel={handleMerchantAddCancel}
          handleOk={handleMerchantAddOk}
          loading={merchant_add_loading}
          headingText=""
        />
      )}
    </div>
  );
}

export default Step1Form;
