import { Avatar, Button, Form, Row, Table, Tooltip } from "antd";
import debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";
import { CgExtensionAdd } from "react-icons/cg";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import PageSearch from "../../../components/common/PageSearch";
import { restriction } from "../../../constant/Restrictions";
import { api } from "../../../services/Api";
import { fetchUser } from "../../../services/User";
import { stringToColour } from "../../../utils/getProfileColor";
import { limit } from "../../../utils/truncateString";
import "./Companies.css"; // Ensure this file contains the .light-red-row class
import CompanyForm from "./CompanyForm";

function Companies() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [params, setParams] = useState({
    sort: "-updatedAt",
    page: 1,
    limit: 15,
    skip: 0,
  });
  const { isLoading: isUserLoading, data: userData } = useQuery(
    "user",
    fetchUser
  );
  const [regexQuery, setRegexQuery] = useState("");

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (_, record) => (
        <span>
          <Tooltip title={<div>{`${record?.name}`}</div>} placement="top">
            {record?.avatar?.url ? (
              <Avatar size="small" src={record?.avatar?.url} />
            ) : (
              <Avatar
                className="capital"
                size="small"
                style={{ backgroundColor: stringToColour(record?.name) }}
              >
                {record?.name?.[0] ?? "U"}
              </Avatar>
            )}
          </Tooltip>
          &nbsp; &nbsp;
          {limit(record.name, 50)}
        </span>
      ),
    },
    {
      title: "नेपाली नाम",
      dataIndex: "nepaliName",
      key: "nepaliName",
      render: (text) => <span>{limit(text, 50)}</span>,
    },
    {
      width: "13vw",
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => (
        <a
          target="_blank"
          rel="noreferrer"
          href={`mailto:${text}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {limit(text, 30)}
        </a>
      ),
    },
    {
      title: "Unposted bill",
      dataIndex: "UnpostedBill",
      key: "UnpostedBill",
      render: (_, record) => {
        return <>{record?.unPostedBillsCount || 0}</>;
      },
    },
  ];

  useEffect(() => {
    if (restriction[userData?.data.role.slug]?.includes("view_all_company")) {
      setParams({ ...params, assignee: userData.data.id });
    }
  }, [params, userData.data.id, userData.data.role.slug]);

  const getCompanies = () => {
    return api.get("/companies", {
      params: {
        ...params,
        name: `/${regexQuery.trim()}/i`,
        page: regexQuery ? 1 : params.page,
        skip: regexQuery ? 0 : params.skip,
        sort: "-unPostedBillsCount",
      },
    });
  };

  const fetchTask = () => {
    return api.get("/tasks", {
      params: { ...params, name: `/${regexQuery.trim()}/i` },
    });
  };

  const createCompany = (data) => {
    return api.post("/companies", data);
  };

  const [isModalVisible, setModalVisible] = useState(false);
  const handleCancel = () => {
    form.resetFields();
    setModalVisible(false);
  };
  const handleCompanyCreate = (values) => {
    company_add_mutate(values);
  };
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        handleCompanyCreate(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const { mutate: company_add_mutate, isLoading: company_add_loading } =
    useMutation(createCompany, {
      onSuccess: () => {
        company_refetch();
        form.resetFields();
        setModalVisible(false);
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });

  const {
    isLoading: company_loading,
    data: company_data,
    refetch: company_refetch,
  } = useQuery(["allcompany", regexQuery, params], getCompanies);

  const {
    isLoading: task_loading,
    data: task_data,
    refetch,
  } = useQuery(["task", regexQuery, params], fetchTask);
  const userId = userData.data._id;

  
  const assignedTask = task_data?.data?.docs?.filter((data) =>
    data.assignee.some((assignee) => assignee?._id === userId)
  );
  const assignedCompanies = assignedTask?.map((task) => task.company);

  const updateCompanypic = (data) => {
    const payload = {
      avatar: data.avatar,
    };
    const formData = new FormData();
    formData.append("avatar", data.avatar);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return api.patch(
      `/companies/${data._id}/update-avatar-pic`,
      formData,
      config,
      payload
    );
  };

  const { mutate: update_profile_mutate, isLoading: update_profile_loading } =
    useMutation(updateCompanypic, {
      onSuccess: () => {
        company_refetch();
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });

  const uploadImage = (avatar) => {
    update_profile_mutate({ avatar: avatar });
  };

  const handleSearchChange = debounce((value) => {
    setRegexQuery(value);
  }, 800);

  console.log(company_data?.data.docs);

  return (
    <>
      {!restriction[userData?.data.role.slug].includes("view_all_company") && (
        <>
          <div className="main-container">
            <Row className="content-apart mb-10">
              <h1 className="main-title">Companies</h1>
              <Row>
                <PageSearch
                  onChange={(e) => handleSearchChange(e.target.value)}
                  placeholder="Search for company"
                />
                {!restriction[userData?.data.role.slug].includes(
                  "change_company"
                ) && (
                  <Button
                    style={{ borderRadius: "0.5em" }}
                    type="primary"
                    onClick={() => {
                      setModalVisible(true);
                    }}
                  >
                    <CgExtensionAdd size={20} />
                  </Button>
                )}
              </Row>
            </Row>
            <Table
              style={{ overflowX: "scroll" }}
              loading={company_loading && isUserLoading}
              pagination={{
                current: params.page,
                total: company_data?.data.totalDocs,
                pageSize: 15,
                onChange: (page) => {
                  setParams({
                    ...params,
                    page,
                    skip: (page - 1) * params.limit,
                  });
                },
              }}
              size="small"
              onRow={(record, _) => {
                return {
                  onClick: (_) => {
                    navigate(`${record._id}`);
                  },
                };
              }}
              className="fiscal-table"
              columns={columns}
              dataSource={
                userData.data.role.slug === "admin" || "Super Admin"
                  ? company_data?.data.docs
                  : assignedCompanies
              }
              rowClassName={(record) =>
                record?.unPostedBillsCount && "light-red-row"
              }
            />
          </div>
          {isModalVisible && (
            <CompanyForm
              isModalVisible={isModalVisible}
              handleCancel={handleCancel}
              handleOk={handleOk}
              form={form}
              loading={company_add_loading}
              okText="Create"
            />
          )}
        </>
      )}
    </>
  );
}

export default Companies;
