import { Button, Col, Form, Input, Row } from "antd";
import React, { useEffect } from "react";
import { useMutation, useQuery } from "react-query";

import isEqual from "lodash/isEqual";
import { FaMinusCircle } from "react-icons/fa";
import { MdAddShoppingCart } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import DebounceSelect from "../../../components/common/DebounceSelect";
import Loading from "../../../components/common/Loading";
import { api } from "../../../services/Api";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 20,
    },
    sm: {
      span: 14,
    },
  },
};
function Step2Form({ billId, next }) {
  let navigate = useNavigate();
  const [form] = Form.useForm();
  const updateBill = async (data) => {
    await api.patch(`/bills/${billId}`, data);
    await api.patch(`/bills/${billId}/post-bill`);
  };
  const { mutate: bill_patch_mutate, isLoading: bill_patch_loading } =
    useMutation(updateBill, {
      onSuccess: () => {
        bill_refetch();
        navigate(`/dashboard/companies`);
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });
  const handleBillUpdate = () => {
    form
      .validateFields()
      .then((values) => {
        bill_patch_mutate(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  const getBill = () => {
    return api.get("/bills/" + billId);
  };
  const getProducts = async (queryString) => {
    return api
      .get("/products", {
        params: {
          name: encodeURI(`/${queryString}/i`),
        },
      })
      .then((body) =>
        body.data.docs.map((product) => ({
          label: `${product.name}`,
          value: product._id,
        }))
      );
  };
  const {
    data: bill_data,
    refetch: bill_refetch,
    isLoading: bill_data_loading,
  } = useQuery("singleBill", getBill);
  const billItems = Form.useWatch("billItems", form);
  const billSummary = Form.useWatch("billSummary", form);
  useEffect(() => {
    const netTaxable =
      billSummary?.billTotalAmount +
      parseFloat(billSummary?.otherDuties) -
      parseFloat(billSummary?.discount);
    const vat = netTaxable * 0.13;
    const grandTotalAmount = netTaxable + vat;
    const newBillSummary = {
      ...billSummary,
      netTaxable,
      vat,
      grandTotalAmount,
    };
    if (!isEqual(billSummary, newBillSummary)) {
      form.setFieldsValue({
        billSummary: newBillSummary,
      });
    }
  }, [billSummary, form]);
  useEffect(() => {
    const billSummaryField = form.getFieldValue("billSummary");
    let total = 0;
    if (billItems) {
      const updatedBillItems = billItems.map((e) => {
        if (e && e.rate && e.productQty) {
          total += e.rate * e.productQty;
          return {
            ...e,
            totalAmount: e.rate * e.productQty,
          };
        }
        return { ...e };
      });
      if (!isEqual(billItems, updatedBillItems)) {
        form.setFieldsValue({ billItems: updatedBillItems });
      }
    }
    if (!isNaN(total)) {
      if (billSummaryField) {
        form.setFieldsValue({
          billSummary: { ...billSummaryField, billTotalAmount: total },
        });
      } else {
        form.setFieldsValue({ billSummary: { billTotalAmount: total } });
      }
    }
  }, [billItems, form]);

  if (bill_data_loading) {
    return <Loading />;
  }
  return (
    <div style={{ marginTop: "2rem", marginBottom: "2rem" }}>
      <Row gutter={30}>
        <Col style={{ padding: "1em" }} span={24}>
          <Form
            {...formItemLayout}
            initialValues={{
              ...bill_data?.data,
              billItems: [
                {},
                ...bill_data?.data?.billItems?.map((e) => {
                  return {
                    ...e,
                    product: {
                      ...e.product,
                      label: e.product.name,
                      value: e.product._id,
                    },
                  };
                }),
              ],
            }}
            form={form}
            layout="horizontal"
          >
            <Form.List name="billItems">
              {(fields, { add, remove }, { errors }) => (
                <>
                  <Form.Item>
                    <Button
                      type="primary"
                      onClick={() => add()}
                      icon={<MdAddShoppingCart />}
                      style={{ marginLeft: "143%" }}
                    >
                      Add Items
                    </Button>
                  </Form.Item>
                  <div
                    className="shadow-card"
                    style={{
                      padding: "10px",
                      borderRadius: "1em",
                    }}
                  >
                    <Row
                      className=""
                      style={{
                        justifyContent: "space-between",
                      }}
                    >
                      <span>Product</span>
                      <span>Qty</span>
                      <span>Rate</span>
                      <span>Amount</span>
                      <h3>{"       "}</h3>
                    </Row>
                    <div
                      style={{
                        height: "40vh",
                        overflow: "auto",
                      }}
                    >
                      {fields.map((field, index) => (
                        <Row
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              add();
                            }
                          }}
                          key={field.key}
                          align="baseline"
                          className="bill-items"
                          style={{
                            justifyContent: "space-between",
                          }}
                        >
                          <Form.Item noStyle>
                            <Form.Item
                              name={[field.name, "product"]}
                              required
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter product !",
                                },
                              ]}
                            >
                              <DebounceSelect
                                bordered={false}
                                style={{
                                  borderBottom: "0.5px solid grey",
                                  width: "140px",
                                  height: "30px",
                                  borderRadius: "0.5em",
                                }}
                                placeholder={"Product"}
                                fetchOptions={getProducts}
                              />
                            </Form.Item>
                            <Form.Item
                              name={[field.name, "productQty"]}
                              required
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter productQty !",
                                },
                              ]}
                            >
                              <Input type="number" placeholder="Product Qty" />
                            </Form.Item>
                            <Form.Item
                              name={[field.name, "rate"]}
                              required
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter rate !",
                                },
                              ]}
                            >
                              <Input type="number" placeholder="Rate" />
                            </Form.Item>
                            <Form.Item name={[field.name, "totalAmount"]}>
                              <Input
                                defaultValue={0}
                                type="number"
                                disabled
                                placeholder="Total"
                              />
                            </Form.Item>
                          </Form.Item>
                          <FaMinusCircle onClick={() => remove(field.name)} />
                        </Row>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </Form.List>
            <Row>
              <Col span={12}>
                {/* <Button
                  style={{
                    marginTop: "10px",
                  }}
                  loading={bill_patch_loading}
                  key="submit"
                  type="primary"
                  onClick={handleBillUpdate}
                >
                  Update Bill
                </Button> */}
              </Col>
              <Col span={12}>
                <div className="bill-items">
                  <Form.Item
                    label="Bill Total"
                    name={["billSummary", "billTotalAmount"]}
                  >
                    <Input
                      type="number"
                      defaultValue={0}
                      disabled
                      placeholder="Total"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Other Duties"
                    name={["billSummary", "otherDuties"]}
                  >
                    <Input type="number" placeholder="Other Duties" />
                  </Form.Item>
                  <Form.Item
                    label="Discount"
                    name={["billSummary", "discount"]}
                  >
                    <Input type="number" placeholder="Discount" />
                  </Form.Item>
                  <Form.Item
                    label="Net Taxable"
                    name={["billSummary", "netTaxable"]}
                  >
                    <Input type="number" disabled placeholder="Net Taxable" />
                  </Form.Item>
                  <Form.Item label="VAT" name={["billSummary", "vat"]}>
                    <Input type="number" disabled placeholder="VAT" />
                  </Form.Item>
                  <Form.Item
                    label="Grand Total"
                    name={["billSummary", "grandTotalAmount"]}
                  >
                    <Input type="number" disabled placeholder="Grand Total" />
                  </Form.Item>
                </div>
                <Button
                  style={{
                    marginTop: "20px",
                    float: "right",
                  }}
                  loading={bill_patch_loading}
                  key="submit"
                  type="primary"
                  onClick={handleBillUpdate}
                >
                  Update Bill
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default Step2Form;
