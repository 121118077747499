import Icon from "@ant-design/icons/lib/components/Icon";
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Form,
  Image,
  Menu,
  Modal,
  Popover,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Tag,
  Tooltip,
  Upload,
} from "antd";
import moment from "moment";
import React, { useState } from "react";
import { BiUserPlus } from "react-icons/bi";
import { BsFlag } from "react-icons/bs";
import { FaBusinessTime, FaEdit, FaTrash } from "react-icons/fa";
import { FcPlus } from "react-icons/fc";
import { FiDownload } from "react-icons/fi";
import { HiReceiptTax } from "react-icons/hi";
import { IoEllipsisVertical } from "react-icons/io5";
import {
  MdClass,
  MdDelete,
  MdEmail,
  MdModeEditOutline,
  MdNature,
  MdOutlineDriveFileRenameOutline,
  MdOutlineManageAccounts,
  MdOutlineSubdirectoryArrowRight,
  MdPlace,
} from "react-icons/md";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Profile from "../../../assets/images/default-company.png";
import CLineChart from "../../../components/charts/CompsLineChart";
import DebounceSelect from "../../../components/common/DebounceSelect";
import Loading from "../../../components/common/Loading";
import ProfileCard from "../../../components/common/ProfileCard";
import { restriction } from "../../../constant/Restrictions";
import { api } from "../../../services/Api";
import { fetchUser } from "../../../services/User";
import { stringToColour } from "../../../utils/getProfileColor";
import { limit } from "../../../utils/truncateString";
import AssignEmployeeForm from "./AssignEmployeeForm";
import CompanyForm from "./CompanyForm";
import CompanyRegistrationOfficeForm from "./CompanyRegistrationOfficeForm";

function CompanyProfile() {
  const [addAssigneeVisible, setAddAssigneeVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [billType, setbillType] = useState("Sales");
  const [confirmLoading, setConfirmLoading] = useState(false);
  let { companyId } = useParams();
  const navigate = useNavigate();
  const [registrationForm] = Form.useForm();
  const [registrationEditForm] = Form.useForm();
  const [companyForm] = Form.useForm();
  const [addAssigneeForm] = Form.useForm();
  const [isRegistrationModalVisible, setRegistrationModalVisible] =
    useState(false);
  const [isRegistrationEditModalVisible, setRegistrationEditModalVisible] =
    useState(false);
  const [isRegistrationDeleteModalVisible, setRegistrationDeleteModalVisible] =
    useState(false);
  const [editingRegistration, setEditingRegistration] = useState({});
  const [isCompanyModalVisible, setCompanyModalVisible] = useState(false);
  const [isCompanyDeleteModalVisible, setCompanyDeleteModalVisible] =
    useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const { isUserLoading, data: userData } = useQuery("user", fetchUser);

  const [cro, setCro] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCROOk = () => {
    setIsModalOpen(false);
  };

  const handleCROCancel = () => {
    setIsModalOpen(false);
  };

  const assignTask = (data) => {
    const payload = {
      assignee: data.assignee,
    };
    return api.patch("/tasks/" + data.taskId + "/assign-user", payload);
  };
  const { mutate: assign_task_mutate } = useMutation(assignTask, {
    onSuccess: () => {
      refetch();
      setPopoverVisible(false);
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });

  const priorityFlag = {
    Low: "green",
    High: "red",
    Medium: "#e4d422",
  };

  //crofile
  const addCrofile = (data) => {
    const formData = new FormData();
    formData.append("croFile", data.croFile);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return api.patch(
      `/companyregistrationoffices/${data.id}/add-cro-file`,
      (data = formData),
      config
    );
  };

  const [addBillform] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const uploadButton = (
    <div>
      <Icon type="plus" />
      <div className="ant-upload-text">Upload</div>
    </div>
  );
  const handleUpload = (info) => {
    let newFileList = [...info.fileList];
    newFileList = newFileList.slice(-2);
    newFileList = newFileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    setFileList(newFileList);
  };

  const onFinish = async (data) => {
    setConfirmLoading(true);
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("attachments", file.originFileObj);
    });
    formData.append("billType", billType);
    formData.append("company", companyId);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    await api
      .post(`/bills/upload-bills`, (data = formData), config)
      .then(() => {
        setOpen(false);
      });
  };

  const onReset = () => {
    addBillform.resetFields();
  };

  const { mutate: add_cro_file, isLoading: add_cro_loading } = useMutation(
    addCrofile,
    {
      onSuccess: () => {
        registration_refetch();
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    }
  );

  const addCroFile = (croFile, id) => {
    add_cro_file({ croFile: croFile, id: id });
  };

  const removeCroFile = (data) => {
    return api.delete(
      `/companyregistrationoffices/${data.id}/remove-cro-file/${data.file}`
    );
  };

  const { mutate: remove_croFile_mutate, isLoading: remove_CroFile_loading } =
    useMutation(removeCroFile, {
      onSuccess: () => {
        registration_refetch();
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });

  const handleCroFileDelete = (id, file) => {
    Swal.fire({
      title: "Are you sure ?",
      text: "You want to delete this file",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Ok`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        remove_croFile_mutate({ id: id, file: file });
      }
    });
  };

  //crofile

  const cro_columns = [
    {
      title: "Registration Number",
      dataIndex: "regNo",
      key: "regNo",
      render: (text) => <b style={{ color: "black" }}>{text}</b>,
    },
    {
      title: "Office",
      dataIndex: "regOffice",
      key: "regOffice",
      render: (text) => <b style={{ color: "black" }}>{text}</b>,
    },
    {
      title: "Expiry Date",
      dataIndex: "expiryDate",
      key: "expiryDate",
      sorter: (a, b) => moment(a.expiryDate) - moment(b.expiryDate),
      render: (text) => <p>{moment(text).add(1, "years").calendar()}</p>,
    },
    {
      title: "Documents",
      dataIndex: "files",
      key: "files",
      render: (files, record) => (
        <Row>
          <Avatar.Group
            maxCount={5}
            maxPopoverTrigger="hover"
            size="small"
            maxStyle={{
              color: "#f56a00",
              backgroundColor: "#fde3cf",
              cursor: "pointer",
            }}
          >
            {files?.map?.((f, i) => {
              return (
                <>
                  <div key={i}>
                    <Tooltip
                      title={
                        <div>
                          <MdDelete
                            onClick={() => {
                              handleCroFileDelete(record._id, f._id);
                            }}
                            style={{
                              color: "red",
                              fontSize: "20px",
                              cursor: "pointer",
                            }}
                          />
                          <button
                            className="delete-btn"
                            id="del-task"
                            style={{
                              position: "relative",
                            }}
                          >
                            <a href={f.url} target="next">
                              <FiDownload
                                style={{
                                  color: "white",
                                  fontSize: "20px",
                                  cursor: "pointer",
                                }}
                              />
                            </a>
                          </button>
                        </div>
                      }
                      placement="top"
                    >
                      <Avatar
                        className="capital"
                        src={<Image src={f?.url} />}
                      ></Avatar>
                    </Tooltip>
                  </div>
                </>
              );
            })}
            <label
              style={{
                cursor: "pointer",
              }}
              htmlFor={"file-input-cro" + record._id}
            >
              <FcPlus size={20} />
            </label>
            <input
              id={"file-input-cro" + record._id}
              type="file"
              className="hidden"
              accept="image/png, image/jpeg, application/pdf"
              onChange={(e) => {
                addCroFile(e.target.files[0], record._id);
              }}
            />
          </Avatar.Group>
        </Row>
      ),
    },
    {
      title: "Registered Date",
      dataIndex: "regDate",
      key: "regDate",
      sorter: (a, b) => moment(a.regDate) - moment(b.regDate),
      render: (text) => <p>{moment(text).fromNow()}</p>,
    },
    {
      //here
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <div>
          <Dropdown
            overlay={
              <Menu mode="horizontal">
                <Menu.Item
                  key="one"
                  className="edit-btn"
                  onClick={() => {
                    record.expiryDate = moment(record.expiryDate);
                    record.regDate = moment(record.regDate);
                    setEditingRegistration(record);
                    setRegistrationEditModalVisible(true);
                  }}
                  icon={<FaEdit style={{ color: "blue", fontSize: "20px" }} />}
                >
                  Edit
                </Menu.Item>

                <Menu.Item
                  key="two"
                  className="delete-btn"
                  onClick={() => {
                    handleRegistrationDelete(record._id);
                  }}
                  icon={<FaTrash style={{ color: "red", fontSize: "20px" }} />}
                >
                  Delete
                </Menu.Item>
              </Menu>
            }
          >
            <Space>
              <IoEllipsisVertical size={20} />
            </Space>
          </Dropdown>
        </div>
      ),
    },
    {
      title: "Full Details",
      render: (record) => (
        <>
          <div>
            <Button
              onClick={() => {
                setCro(record);
                showModal();
              }}
              type="primary"
            >
              View Details
            </Button>
          </div>
        </>
      ),
    },
  ];

  const handleCancel = () => {
    registrationForm.resetFields();
    setRegistrationModalVisible(false);
  };
  const handleRegistrationEditCancel = () => {
    setEditingRegistration({});
    registrationEditForm.resetFields();
    setRegistrationEditModalVisible(false);
  };
  const handleRegistrationFormOk = () => {
    registrationEditForm
      .validateFields()
      .then((values) => {
        handleRegistrationEdit(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  const createRegistration = (data) => {
    data.company = companyId;
    return api.post("/companyregistrationoffices", data);
  };
  const patchRegistration = (data) => {
    return api.patch(
      `/companyregistrationoffices/${editingRegistration._id}`,
      data
    );
  };
  const patchCompany = (data) => {
    return api.patch(`/companies/${companyId}`, data);
  };
  const removeCompany = () => {
    return api.delete(`/companies/${companyId}`);
  };
  const removeRegistration = (id) => {
    return api.delete(`/companyregistrationoffices/${id}`);
  };

  const getUsers = async (queryString, exclude) => {
    return api
      .get("/users", {
        params: {
          "userName!": exclude.join(","),
          "relation_role.slug!": "user",
          firstName: encodeURI(`/${queryString}/i`),
        },
      })
      .then((body) =>
        body.data.docs.map((user) => ({
          label: `${user.firstName} ${user.lastName}`,
          value: user._id,
        }))
      );
  };
  const handleCompanyDelete = () => {
    Swal.fire({
      title: "Are you sure ?",
      text: "You want to delete this Company.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Ok`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        company_delete_mutate();
      }
    });
  };
  const handleRegistrationDelete = (id) => {
    Swal.fire({
      title: "Are you sure ?",
      text: "You want to delete this registration",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Ok`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        registration_delete_mutate(id);
      }
    });
  };
  const { mutate: company_delete_mutate, isLoading: company_delete_loading } =
    useMutation(removeCompany, {
      onSuccess: () => {
        company_refetch();
        navigate("/dashboard/companies");
        setCompanyDeleteModalVisible(false);
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });
  const assignCompany = (data) => {
    return api.patch("/companies/" + companyId + "/assign-employee", data);
  };
  const { mutate: assign_company_mutate } = useMutation(assignCompany, {
    onSuccess: () => {
      company_refetch();
      addAssigneeForm.resetFields();
      setAddAssigneeVisible(false);
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });
  const {
    mutate: registration_delete_mutate,
    isLoading: registration_delete_loading,
  } = useMutation(removeRegistration, {
    onSuccess: () => {
      registration_refetch();
      setRegistrationDeleteModalVisible(false);
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });
  const { mutate: company_patch_mutate, isLoading: company_patch_loading } =
    useMutation(patchCompany, {
      onSuccess: () => {
        company_refetch();
        window.location.reload();
        companyForm.resetFields();
        setCompanyModalVisible(false);
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });
  const {
    mutate: registration_add_mutate,
    isLoading: registration_add_loading,
  } = useMutation(createRegistration, {
    onSuccess: () => {
      registration_refetch();
      registrationForm.resetFields();
      setRegistrationModalVisible(false);
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });
  const {
    mutate: registration_patch_mutate,
    isLoading: registration_patch_loading,
  } = useMutation(patchRegistration, {
    onSuccess: () => {
      registration_refetch();
      setEditingRegistration({});
      registrationEditForm.resetFields();
      setRegistrationEditModalVisible(false);
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });
  const handleRegistrationCreate = (values) => {
    registration_add_mutate(values);
  };
  const handleRegistrationEdit = (values) => {
    registration_patch_mutate(values);
  };
  const handleOk = () => {
    registrationForm
      .validateFields()
      .then((values) => {
        handleRegistrationCreate(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  const handleCompanyFormCancel = () => {
    companyForm.resetFields();
    setCompanyModalVisible(false);
  };
  const handleCompanyFormOk = () => {
    companyForm
      .validateFields()
      .then((values) => {
        company_patch_mutate(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const getCompany = () => {
    return api.get("/companies/" + companyId);
  };

  const getRegistrationOffices = () => {
    return api.get("/companyregistrationoffices", {
      params: {
        company: companyId,
      },
    });
  };

  const fetchTask = () => {
    return api.get("/tasks", {
      params: {
        company: companyId,
      },
    });
  };

  const fetchOngoingTask = () => {
    return api.get("/tasks", {
      params: {
        company: companyId,
        status: "Ongoing",
        limit: 4,
      },
    });
  };

  const assignEmployee = (data) => {
    const payload = {
      assignee: data.assignee,
    };
    return api.patch("/companies/" + companyId + "/assign-employee", payload);
  };

  const priorityTask = (data) => {
    const payload = {
      priority: data.priority,
    };
    return api.patch("/tasks/" + data.taskId, payload);
  };
  const { mutate: priority_task_mutate } = useMutation(priorityTask, {
    onSuccess: () => {
      refetch();
      setPopoverVisible(false);
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });

  const { mutate: assign_mutate } = useMutation(assignEmployee, {
    onSuccess: () => {
      refetch();
      setPopoverVisible(false);
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });

  const {
    isLoading: task_loading,
    data: task_data,
    refetch: task_refetch,
  } = useQuery("task", fetchTask);

  const {
    isLoading: taskOngoing_loading,
    data: taskOngoing_data,
    refetch: taskOngoing_refetch,
  } = useQuery("Ongoingtask", fetchOngoingTask);

  const removeAssignee = (data) => {
    return api.patch(
      "/companies/" + companyId + "/remove-assigned-employee",
      data
    );
  };
  const { mutate: delete_assignee_mutate } = useMutation(removeAssignee, {
    onSuccess: () => {
      company_refetch();
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });
  const handleAssigneeDelete = (id) => {
    Swal.fire({
      title: "Are you sure ?",
      text: "You want to remove this assignee",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Ok`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        delete_assignee_mutate({
          assignee: id,
        });
      }
    });
  };
  const {
    data: company,
    isLoading: companyLoading,
    refetch: company_refetch,
  } = useQuery("company", getCompany);
  const {
    data: registrationOffices,
    isLoading: registrarionOfficeLoading,
    refetch: registration_refetch,
  } = useQuery(["registration_offices", companyId], getRegistrationOffices);

  const { TabPane } = Tabs;

  const updateCompanypic = (data) => {
    const payload = {
      avatar: data.avatar,
    };
    const formData = new FormData();
    formData.append("avatar", data.avatar);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return api.patch(
      `/companies/${companyId}/update-avatar-pic`,
      formData,
      config,
      payload
    );
  };

  const { mutate: update_profile_mutate, isLoading: update_profile_loading } =
    useMutation(updateCompanypic, {
      onSuccess: () => {
        refetch();
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });

  const uploadImage = (avatar) => {
    update_profile_mutate({ avatar: avatar });
  };

  const getGraphData = () => {
    return api.get("/tasks/dashboard/graph-data", {
      params: {
        company: companyId,
        // "createdAt>": moment().startOf("year"),
        // "createdAt<": moment().endOf("year"),
      },
    });
  };

  const {
    isLoading: graphData_loading,
    data: graphData_data,
    refetch: graphData_refetch,
  } = useQuery("graphData", getGraphData);

  const menu = (
    <Menu mode="horizontal">
      <Menu.Item
        key="one"
        onClick={() => {
          setCompanyModalVisible(true);
        }}
        icon={<FaEdit style={{ color: "blue", fontSize: "20px" }} />}
      >
        Edit
      </Menu.Item>

      <Menu.Item
        key="two"
        onClick={() => {
          handleCompanyDelete();
        }}
        icon={<FaTrash style={{ color: "red", fontSize: "20px" }} />}
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  const colorByStatus = {
    Pending: "volcano",
    Ongoing: "yellow",
    Completed: "green",
  };

  const {
    isLoading: loading1,
    data: data1,
    refetch,
  } = useQuery("company", getCompany, {
    refetchOnWindowFocus: "always",
  });

  if (loading1) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Loading />
      </div>
    );
  }

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, record) => (
        <p
          onClick={() => {
            navigate(`/dashboard/tasks/${record._id}`);
          }}
          style={{ color: "black" }}
        >
          {text}
        </p>
      ),
      // <p style={{ color: "black" }}>{text}</p>,

      width: "40%",
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      sorter: (a, b) => moment(a.dueDate) - moment(b.dueDate),
      render: (text) => <p>{moment(text).fromNow()}</p>,
    },
    {
      title: "Others",
      dataIndex: "assignee",
      key: "assignee",
      render: (assignee, record) => (
        <>
          <Avatar.Group
            maxCount={5}
            maxPopoverTrigger="hover"
            size="small"
            maxStyle={{
              color: "#f56a00",
              backgroundColor: "#fde3cf",
              cursor: "pointer",
            }}
          >
            {assignee.map((user, i) => {
              const color = stringToColour(user.userName);
              return (
                <>
                  <div key={i}>
                    <Tooltip
                      title={<div>{`${user.firstName} ${user.lastName}`}</div>}
                      placement="top"
                    >
                      {user?.avatar?.url ? (
                        <Avatar src={user.avatar.url} />
                      ) : (
                        <Avatar
                          className="capital"
                          style={{ backgroundColor: color }}
                        >
                          {user.firstName[0] ?? "U"}
                        </Avatar>
                      )}
                    </Tooltip>
                  </div>
                </>
              );
            })}
            <Popover
              open={popoverVisible}
              onOpenChange={() => setPopoverVisible(true)}
              content={
                <div
                  style={{
                    width: "300px",
                  }}
                >
                  <DebounceSelect
                    fetchOptions={(query) =>
                      getUsers(query, assignee?.map((e) => e.userName) ?? [])
                    }
                    onChange={(e) => {
                      assign_task_mutate({ taskId: record._id, assignee: e });
                    }}
                    placeholder="Assignee"
                  />
                </div>
              }
              trigger="click"
              placement="bottomLeft"
            >
              <Avatar
                className="capital"
                size="small"
                style={{
                  backgroundColor: "#87d068",
                  cursor: "pointer",
                }}
              >
                <BiUserPlus size={12} />
              </Avatar>
            </Popover>
          </Avatar.Group>
        </>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: [
        {
          text: "Pending",
          value: "Pending",
        },
        {
          text: "Ongoing",
          value: "Ongoing",
        },
        {
          text: "Completed",
          value: "Completed",
        },
      ],
      onFilter: (value, record) => record.status === value,
      render: (status) => {
        return <Tag color={colorByStatus[status]}>{status}</Tag>;
      },
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      render: (priority, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                onClick={() =>
                  priority_task_mutate({
                    taskId: record._id,
                    priority: "High",
                  })
                }
              >
                High
              </Menu.Item>
              <Menu.Item
                onClick={() =>
                  priority_task_mutate({
                    taskId: record._id,
                    priority: "Medium",
                  })
                }
              >
                Medium
              </Menu.Item>
              <Menu.Item
                onClick={() =>
                  priority_task_mutate({
                    taskId: record._id,
                    priority: "Low",
                  })
                }
              >
                Low
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <BsFlag color={priorityFlag[priority ?? "Medium"]} />
        </Dropdown>
      ),
    },
  ];
  return (
    <>
      <div className="main-container">
        <div className="white-box">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="breadcrumb">
              <span
                className="main-title link-element"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Company /
              </span>
              <span className="main-subtitle">{company?.data.name}</span>
            </div>
            <Row>
              {!restriction[userData?.data.role.slug].includes(
                "access_modify"
              ) && (
                <Dropdown overlay={menu}>
                  <Space>
                    <IoEllipsisVertical
                      style={{
                        cursor: "pointer",
                        color: "#00ABC5",
                      }}
                      size={20}
                    />
                  </Space>
                </Dropdown>
              )}
            </Row>
          </div>
          <Row
            gutter={15}
            style={{
              justifyContent: "space-between",
            }}
            className="comps-responsive"
          >
            <Col span={8} id="col-col">
              <ProfileCard
                imgSrc={company?.data.avatar?.url ?? Profile}
                icon={
                  !restriction[userData?.data.role.slug].includes(
                    "change_company"
                  ) && (
                    <label
                      style={{
                        cursor: "pointer",
                      }}
                      htmlFor="file-input"
                    >
                      <MdModeEditOutline />
                    </label>
                  )
                }
                title={`${company?.data.nepaliName}`}
              >
                <input
                  id="file-input"
                  type="file"
                  className="hidden"
                  accept="image/png, image/jpeg"
                  onChange={(e) => {
                    uploadImage(e.target.files[0]);
                  }}
                />
                <Row className="content-center profile-meta">
                  <MdPlace className="mr-10" />
                  {company?.data.address?.country}
                </Row>
                <Row className="content-center profile-meta">
                  <MdClass className="mr-10" />
                  {company?.data.pan}
                </Row>
                <Row className="content-center profile-meta">
                  <p>
                    <MdEmail className="mr-10" />
                    {limit(company?.data.email, 14)}
                  </p>
                </Row>
              </ProfileCard>
            </Col>

            <Col className="mt-16" span={16}>
              <CLineChart data={graphData_data?.data} />
            </Col>
          </Row>

          <Row
            style={{
              marginLeft: "10px",
              marginBottom: "14px",
            }}
          >
            <h2>Assigne to:</h2>&nbsp;
            <Avatar.Group
              maxCount={5}
              maxPopoverTrigger="hover"
              size="large"
              maxStyle={{
                color: "#f56a00",
                backgroundColor: "#fde3cf",
                cursor: "pointer",
              }}
            >
              {company?.data.assignee?.map((user, i) => {
                const color = stringToColour(user.userName);
                return (
                  <div key={i}>
                    <Tooltip
                      title={
                        <div>
                          {user.userName}
                          <MdDelete
                            onClick={() => {
                              handleAssigneeDelete(user._id);
                            }}
                            style={{
                              color: "red",
                              fontSize: "20px",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      }
                      placement="top"
                    >
                      <Avatar
                        className="capital"
                        style={{ backgroundColor: color }}
                      >
                        {user.userName[0]}
                      </Avatar>
                    </Tooltip>
                  </div>
                );
              })}
              <Popover
                open={popoverVisible}
                onOpenChange={() => {
                  setAddAssigneeVisible(true);
                }}
                content={
                  <div
                    style={{
                      width: "300px",
                    }}
                  >
                    <DebounceSelect
                      fetchOptions={(query) =>
                        getUsers(
                          query,
                          data1?.data?.assignee?.map((e) => e.userName) ?? []
                        )
                      }
                      onChange={(e) => {
                        assign_mutate({
                          companyId: companyId,
                          assignee: e,
                        });
                      }}
                      placeholder="Assignee"
                    />
                  </div>
                }
                trigger="click"
                placement="bottomLeft"
              >
                <Avatar
                  size="large"
                  style={{
                    backgroundColor: "#87d068",
                    cursor: "pointer",
                    padding: "4px",
                  }}
                >
                  <BiUserPlus size={20} />
                </Avatar>
              </Popover>
            </Avatar.Group>
          </Row>

          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
            style={{
              marginLeft: "10px",
              marginBottom: "10px",
            }}
          >
            <Button
              type="primary"
              onClick={() => {
                navigate(`/dashboard/companies/${companyId}/inventory`);
              }}
            >
              Inventory
            </Button>
            &nbsp;&nbsp;
            <Button
              type="primary"
              onClick={() => {
                navigate(`/dashboard/companies/${companyId}/bill-items`);
              }}
            >
              Bill Gallery
            </Button>
            &nbsp;&nbsp;
            <Button
              type="primary"
              onClick={() => {
                setOpen(true);
              }}
            >
              Add Bills
            </Button>
          </Row>

          <div
            style={{
              marginTop: "30px",
              marginLeft: "13px",
            }}
          >
            {taskOngoing_data?.data?.docs?.length > 0 && (
              <Tag color="green" className="task-tag">
                <h1
                  style={{
                    color: "green",
                    textDecoration: "underline",
                  }}
                >
                  Ongoing Task
                </h1>
              </Tag>
            )}
          </div>

          <Row>
            {taskOngoing_data?.data?.docs?.map?.((ot, i) => {
              return (
                <>
                  <div
                    className="details-card"
                    style={{
                      border: "1px solid #e6d9d9",
                      marginTop: "10px",
                      borderRadius: "25px",
                      marginLeft: "10px",
                    }}
                    key={i}
                  >
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      {ot.title}
                    </p>
                    <hr />
                    <p
                      style={{
                        fontSize: "14px",
                        opacity: 0.8,
                      }}
                    >
                      Due date: {moment(ot.dueDate).format("MMM Do, h:mm a")}
                    </p>
                    <p>
                      <Button type="primary">{ot.status}</Button>
                    </p>
                  </div>
                </>
              );
            })}
          </Row>

          <Tabs className="mt-16" defaultActiveKey="1">
            <TabPane tab="General Info" key="1">
              <div className="emp-details-card">
                <h1 className="dpage-title">Full Details</h1>
                <hr />
                <div style={{ marginTop: "15px" }}>
                  <p>
                    <MdOutlineDriveFileRenameOutline />
                    &nbsp; Name: {company?.data?.name}
                  </p>
                  <p>
                    <MdEmail />
                    &nbsp; Email: {company?.data.email}
                  </p>
                  <p>
                    <MdOutlineManageAccounts />
                    &nbsp; NepaliName: {company?.data.nepaliName}
                  </p>

                  <p>
                    <HiReceiptTax />
                    &nbsp; taxCategory: {company?.data.taxCategory}
                  </p>
                  <p>
                    <MdClass />
                    &nbsp; Pan: {company?.data.pan}
                  </p>
                  <p>
                    <FaBusinessTime />
                    &nbsp; BusinessType: {company?.data.businessType}
                  </p>
                  <p>
                    <MdNature />
                    &nbsp; BusinessNature: {company?.data.businessNature}
                  </p>
                </div>
              </div>
            </TabPane>
            <TabPane tab="Tasks" key="2">
              <Table
                size="small"
                scroll={{ y: "50vh" }}
                onRow={(record, rowIndex) => {}}
                className="fiscal-table"
                columns={columns}
                dataSource={task_data?.data.docs}
              />
            </TabPane>
            <TabPane tab="Registration Offices" key="3">
              {!restriction[userData?.data.role.slug].includes(
                "change_company"
              ) && (
                <Button
                  className="btn-btn-register"
                  type="primary"
                  onClick={() => {
                    registrationForm.resetFields();
                    setRegistrationModalVisible(true);
                  }}
                >
                  Add New Registration
                </Button>
              )}

              <Table
                size="small"
                style={{ width: "100%" }}
                columns={cro_columns}
                dataSource={registrationOffices?.data.docs}
                className="fiscal-table"
              />
            </TabPane>

            <TabPane tab="Objectives" key="4">
              <div className="emp-details-card">
                <h1 className="dpage-title">Objectives</h1>
                <hr />

                {company?.data.objectives?.map((e, i) => (
                  <div key={i}>
                    <p
                      style={{
                        fontSize: "15px",
                        marginTop: "5px",
                        opacity: 0.9,
                      }}
                    >
                      <MdOutlineSubdirectoryArrowRight />
                      &nbsp;&nbsp;
                      {limit(e, 200)}
                    </p>
                  </div>
                ))}
              </div>
            </TabPane>
          </Tabs>
        </div>
        <CompanyRegistrationOfficeForm />
        {isCompanyModalVisible && (
          <CompanyForm
            isModalVisible={isCompanyModalVisible}
            handleCancel={handleCompanyFormCancel}
            handleOk={handleCompanyFormOk}
            form={companyForm}
            loading={company_patch_loading}
            initialValues={company?.data}
            okText="Update"
          />
        )}
        {isRegistrationModalVisible && (
          <CompanyRegistrationOfficeForm
            form={registrationForm}
            handleOk={handleOk}
            handleCancel={handleCancel}
            isVisible={isRegistrationModalVisible}
            loading={registration_add_loading}
            okText="Create"
          />
        )}
        {isRegistrationEditModalVisible && (
          <CompanyRegistrationOfficeForm
            form={registrationEditForm}
            handleOk={handleRegistrationFormOk}
            handleCancel={handleRegistrationEditCancel}
            initialValues={editingRegistration}
            isVisible={isRegistrationEditModalVisible}
            loading={registration_patch_loading}
            okText="Update"
          />
        )}
        {addAssigneeVisible && (
          <AssignEmployeeForm
            exclude={company?.data.assignee?.map((e) => e.userName) ?? []}
            form={addAssigneeForm}
            visible={addAssigneeVisible}
            handleCancel={() => {
              addAssigneeForm.resetFields();
              setAddAssigneeVisible(false);
            }}
            handleOk={() => {
              addAssigneeForm
                .validateFields()
                .then((values) => assign_company_mutate(values))
                .catch(() => {});
            }}
          />
        )}
        {isModalOpen && (
          <Modal
            title="CRO Details"
            visible={isModalOpen}
            onOk={handleCROOk}
            onCancel={handleCROCancel}
          >
            <div>
              <h4>
                <b>Office:</b>&nbsp;{cro?.regOffice}
              </h4>
              <h4>
                <b>Registration Number:</b>&nbsp;{cro?.regNo}
              </h4>
              <h4>
                <b>Registration Date:</b>&nbsp;
                {moment(cro?.regDate).format("YYYY MMM Do, h:mm a")}
              </h4>
              <hr />
              <h4>
                <b>Associated Company:</b>&nbsp;{cro?.company?.name}
                <br />
                <b>सम्बन्धित कम्पनी:</b>&nbsp;{cro?.company?.nepaliName}
              </h4>
              <h4>
                <b>Email:</b>&nbsp;{cro?.company?.email}
              </h4>
              <h4>
                <b>Business Type:</b>&nbsp;{cro?.company?.businessType}
              </h4>
              <h4>
                <b>Business Nature:</b>&nbsp;{cro?.company?.businessNature}
              </h4>
              <h4>
                <b>Tax Category:</b>&nbsp;{cro?.company?.taxCategory}
              </h4>
              <h4>
                <b>PAN Number:</b>&nbsp;{cro?.company?.pan}
              </h4>
              <h4>
                <b>Contact Number:</b>&nbsp;{cro?.company?.phone}
              </h4>
              <hr />
              {cro?.shareHolders?.map((sh, i) => {
                return (
                  <>
                    <h4>
                      <b>ShareHolder Name:</b>&nbsp;{sh?.name}
                    </h4>
                    <h4>
                      <b>ShareHolder Holding:</b>&nbsp;
                      {sh?.holding}
                    </h4>
                    <h4>
                      <b>ShareHolder CitizenshipNo:</b>&nbsp;
                      {sh?.citizenshipNo}
                    </h4>
                  </>
                );
              })}
            </div>
          </Modal>
        )}
        <Modal
          title="Add Bills"
          open={open}
          className="addbillmodal"
          centered
          visible={open}
          footer={null}
          onCancel={() => setOpen(false)}
        >
          <Form form={addBillform} name="control-hooks" onFinish={onFinish}>
            <Form.Item label="Select Bill Type" rules={[{ required: true }]}>
              <Select onChange={(value) => setbillType(value)}>
                <Select.Option value="Sales">Sales</Select.Option>
                <Select.Option value="Purchase">Purchase</Select.Option>
                <Select.Option value="Sales Return">Sales Return</Select.Option>
                <Select.Option value="Purchase Return">
                  Purchase Return
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Select the files" rules={[{ required: true }]}>
              <Upload
                multiple
                listType="picture-card"
                fileList={fileList}
                onChange={handleUpload}
                beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
              >
                {uploadButton}
              </Upload>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ float: "right" }}
                disabled={confirmLoading}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  );
}

export default CompanyProfile;
