import { Button, Form, Input, Modal, Select, Upload } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineUpload } from "react-icons/ai";
import DebounceSelect from "../../../components/common/DebounceSelect";
import Editor from "../../../components/common/Editor";
import { api } from "../../../services/Api";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 20,
    },
    sm: {
      span: 14,
    },
  },
};
const { Option } = Select;

function NewsForm({
  isModalVisible,
  handleCancel,
  handleOk,
  isModalOpen,
  form,
  loading,
  initialValues,
  okText,
  headingText,
  setImage,
  edit = false,
  isDescription = false,
}) {
  const getNewsCategory = async () => {
    return api.get("/feed-categories", {}).then((body) =>
      body.data.docs.map((newscat) => ({
        label: newscat?.name,
        value: newscat._id.toString(),
      }))
    );
  };
  const editor = useRef(null);
  const [content, setContent] = useState(null);

  useEffect(() => {
    const updatedInitialValues = {
      ...initialValues,
    };
    form.setFieldsValue(updatedInitialValues);
  }, [form, initialValues]);

  const props = {
    listType: "picture",

    onChange(fileList) {
      setImage(fileList);
      return false;
    },

    showUploadList: true,
  };

  return (
    <>
      <Modal
        maskClosable={false}
        // title={headingText}
        visible={isModalVisible}
        onCancel={handleCancel}
        okText="Create"
        cancelText="Cancel"
        width={"30vw"}
        style={{ background: "white", top: "0px" }}
        className="global-modal"
        footer={[
          <Button
            loading={loading}
            key="submit"
            type="primary"
            onClick={handleOk}
            hidden={isDescription}
          >
            {okText}
          </Button>,
        ]}
      >
        <div className="form-wrapper">
          <Form
            initialValues={{
              ...initialValues,
            }}
            form={form}
            layout="vertical"
          >
            <Form.Item
              label="News Title"
              name="title"
              required
              hidden={isDescription}
              rules={[
                {
                  required: true,
                  message: "Please Enter First Name!",
                },
              ]}
            >
              <Input placeholder="place news category name" />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please input description!",
                },
              ]}
            >
              {/* <JoditEditor
                ref={editor}
                // config={config}
                value={content}
                onChange={(newContent) => setContent(newContent)}
              /> */}
              <Editor
                onChange={(newContent) => setContent(newContent)}
                showToolbar={!isDescription}
              />
            </Form.Item>
            {/* <Form.Item
      label="Description"
      name="description"
      required
      rules={[
        {
          required: true,
          message: "Please provide description",
        },
      ]}
    >
      <Input placeholder="Description" />
    </Form.Item> */}
            <Form.Item
              label="News Category"
              name="category"
              required
              hidden={isDescription}
              rules={[
                {
                  required: true,
                  message: "Please select News Category !",
                },
              ]}
            >
              <DebounceSelect
                placeholder="Select News Category"
                fetchOptions={getNewsCategory}
              />
            </Form.Item>
            {!edit && (
              <Upload.Dragger multiple {...props} beforeUpload="/" maxCount={1}>
                Drag image here Or
                <br />
                <br />
                <Button icon={<AiOutlineUpload />}>Upload</Button>
              </Upload.Dragger>
            )}
          </Form>
        </div>
      </Modal>
    </>
  );
}

export default NewsForm;
