import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import moment from "moment";
import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import { useQuery } from "react-query";
import { api } from "../../services/Api";
import { fetchUser } from "../../services/User";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function AgentBarChart({ data }) {
  const [regexQuery, setRegexQuery] = useState("");

  const [params, setParams] = useState({
    sort: "-createdAt",
    pagination: false,
    status: undefined,
  });
  const fetchTask = () => {
    return api.get("/tasks", {
      params: { ...params, title: encodeURI(`/${regexQuery}/i`) },
    });
  };

  const {
    isLoading: task_loading,
    data: task_data,
    refetch,
  } = useQuery(["task", regexQuery, params], fetchTask);

  const { data: userData } = useQuery("user", fetchUser);

  const userId = userData.data._id;
  const userRole = userData.data.role.slug;

  const assignedTask = task_data?.data?.docs?.filter((data) =>
    data.assignee.some((assignee) => assignee._id === userId)
  );
  const onGoingData = assignedTask?.filter(
    (task) => task?.status === "Ongoing"
  );
  const completedData = assignedTask?.filter(
    (task) => task?.status === "Completed"
  );
  const pendingData = assignedTask?.filter(
    (task) => task?.status === "Pending"
  );
  const allonGoingData = task_data?.data?.docs?.filter(
    (task) => task?.status === "Ongoing"
  );
  const allpendingData = task_data?.data?.docs?.filter(
    (task) => task?.status === "Pending"
  );
  const allCompletedData = task_data?.data?.docs?.filter(
    (task) => task?.status === "Completed"
  );

  const ongoing_data_array = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const completed_data_array = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const pending_data_array = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  data[1]?.forEach((task) => {
    const monthNumber = moment(task.startDate).format("M");
    ongoing_data_array[monthNumber - 1]++;
  });

  data[2]?.forEach((task) => {
    const monthNumber = moment(task.startDate).format("M");
    completed_data_array[monthNumber - 1]++;
  });
  data[0]?.forEach((task) => {
    const monthNumber = moment(task.startDate).format("M");
    pending_data_array[monthNumber - 1]++;
  });

  const options = {
    responsive: true,

    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Monthly Task Progress Report",
      },
    },
  };

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const data2 = {
    labels,
    datasets: [
      {
        label: "Pending Task",
        // data: labels?.map((_, index) => data?.find()),
        data: pending_data_array,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Ongoing Task",
        data: ongoing_data_array,
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Completed Task",
        data: completed_data_array,
        backgroundColor: "rgba(53, 235, 99, 0.5)",
      },
    ],
  };
  return (
    <>
      <Bar height={175} options={options} data={data2} />
    </>
  );
}

export default AgentBarChart;
