import { Avatar, Button, Form, Row, Table, Tooltip } from "antd";
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";
import { CgExtensionAdd } from "react-icons/cg";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { app } from "../../../App";
import PageSearch from "../../../components/common/PageSearch";
import { restriction } from "../../../constant/Restrictions";
import { api } from "../../../services/Api";
import { fetchUser } from "../../../services/User";
import { stringToColour } from "../../../utils/getProfileColor";
import { limit } from "../../../utils/truncateString";

// import "./Companies.css"; // Ensure this file contains the .light-red-row class
var CryptoJS = require("crypto-js");

function AgentCompanies() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [company, setCompany] = useState([]);

  const [params, setParams] = useState({
    sort: "-updatedAt",
    page: 1,
    limit: 15,
    skip: 0,
  });
  const { isLoading: isUserLoading, data: userData } = useQuery(
    "user",
    fetchUser
  );
  const [regexQuery, setRegexQuery] = useState("");
  const db = getFirestore(app);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (_, record) => (
        <span>
          <Tooltip title={<div>{`${record?.name}`}</div>} placement="top">
            {record?.avatar?.url ? (
              <Avatar size="small" src={record?.avatar?.url} />
            ) : (
              <Avatar
                className="capital"
                size="small"
                style={{ backgroundColor: stringToColour(record?.name) }}
              >
                {record?.name?.[0] ?? "U"}
              </Avatar>
            )}
          </Tooltip>
          &nbsp; &nbsp;
          {limit(record.companyName, 50)}
        </span>
      ),
    },
    {
      title: "Password",
      dataIndex: "password",
      key: "nepaliName",
      render: (_, record) => <span>{record?.password}</span>,
    },
  ];

  useEffect(() => {
    if (restriction[userData?.data.role.slug]?.includes("view_all_company")) {
      setParams({ ...params, assignee: userData.data.id });
    }
  }, [params, userData.data.id, userData.data.role.slug]);

  const getCompanies = () => {
    return api.get("/companies", {
      params: {
        ...params,
        name: `/${regexQuery.trim()}/i`,
        page: regexQuery ? 1 : params.page,
        skip: regexQuery ? 0 : params.skip,
        sort: "-unPostedBillsCount",
      },
    });
  };

  const fetchTask = () => {
    return api.get("/tasks", {
      params: { ...params, name: `/${regexQuery.trim()}/i` },
    });
  };

  const createCompany = (data) => {
    return api.post("/companies", data);
  };

  const [isModalVisible, setModalVisible] = useState(false);
  const handleCancel = () => {
    form.resetFields();
    setModalVisible(false);
  };
  const handleCompanyCreate = (values) => {
    company_add_mutate(values);
  };
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        handleCompanyCreate(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const { mutate: company_add_mutate, isLoading: company_add_loading } =
    useMutation(createCompany, {
      onSuccess: () => {
        company_refetch();
        form.resetFields();
        setModalVisible(false);
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });

  const {
    isLoading: company_loading,
    data: company_data,
    refetch: company_refetch,
  } = useQuery(["allcompany", regexQuery, params], getCompanies);

  const {
    isLoading: task_loading,
    data: task_data,
    refetch,
  } = useQuery(["task", regexQuery, params], fetchTask);
  const userId = userData.data._id;

  const assignedTask = task_data?.data?.docs?.filter((data) =>
    data.assignee.some((assignee) => assignee?._id === userId)
  );
  const assignedCompanies = assignedTask?.map((task) => task.company);

  const updateCompanypic = (data) => {
    const payload = {
      avatar: data.avatar,
    };
    const formData = new FormData();
    formData.append("avatar", data.avatar);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return api.patch(
      `/companies/${data._id}/update-avatar-pic`,
      formData,
      config,
      payload
    );
  };

  const { mutate: update_profile_mutate, isLoading: update_profile_loading } =
    useMutation(updateCompanypic, {
      onSuccess: () => {
        company_refetch();
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });

  const uploadImage = (avatar) => {
    update_profile_mutate({ avatar: avatar });
  };

  const handleSearchChange = debounce((value) => {
    setRegexQuery(value);
  }, 800);

  useEffect(() => {
    const getAgentTaskCompany = async () => {
      try {
        const taskCollectionRef = collection(db, "task");
        const q = query(
          taskCollectionRef,
          where("userId", "==", userData.data._id)
        );
        const taskCollectionSnapshot = await getDocs(q);

        const companies = [];

        taskCollectionSnapshot.forEach((doc) => {
          const data = doc.data();

          if (data?.password) {
            // Decrypt the password
            const key = CryptoJS.enc.Utf8.parse(
              "+MbQeThVmYq3t6w9z$C&F)J@NcRfUjXn"
            );
            const iv = CryptoJS.enc.Utf8.parse("5ty76ujie324$567");

            const encryptedBytes = CryptoJS.enc.Hex.parse(
              data.password.toString()
            );
            const encryptedBase64 =
              CryptoJS.enc.Base64.stringify(encryptedBytes);

            const decrypted = CryptoJS.AES.decrypt(encryptedBase64, key, {
              iv: iv,
              mode: CryptoJS.mode.CBC,
              padding: CryptoJS.pad.Pkcs7,
            });

            const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);

            // Add company data to the array
            companies.push({
              companyName: data.companyName,
              email: data.username,
              password: decryptedText,
            });
          } else {
            console.log("Password field is missing!");
          }
        });

        // Update the state with the companies array
        setCompany(companies);
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    };

    getAgentTaskCompany();
  }, [db, setCompany, userData]);


  return (
    <>
      {!restriction[userData?.data.role.slug].includes("view_all_company") && (
        <>
          <div className="main-container">
            <Row className="content-apart mb-10">
              <h1 className="main-title">Companies</h1>
              <Row>
                <PageSearch
                  onChange={(e) => handleSearchChange(e.target.value)}
                  placeholder="Search for company"
                />
                {!restriction[userData?.data.role.slug].includes(
                  "change_company"
                ) && (
                  <Button
                    style={{ borderRadius: "0.5em" }}
                    type="primary"
                    onClick={() => {
                      setModalVisible(true);
                    }}
                  >
                    <CgExtensionAdd size={20} />
                  </Button>
                )}
              </Row>
            </Row>
            <Table
              style={{ overflowX: "scroll" }}
              loading={company_loading && isUserLoading}
              pagination={{
                current: params.page,
                total: company?.length,
                pageSize: 15,
                onChange: (page) => {
                  setParams({
                    ...params,
                    page,
                    skip: (page - 1) * params.limit,
                  });
                },
              }}
              size="small"
              onRow={(record, _) => {
                return {
                  onClick: (_) => {
                    navigate(`${record._id}`);
                  },
                };
              }}
              className="fiscal-table"
              columns={columns}
              dataSource={company}
              rowClassName={(record) =>
                record?.unPostedBillsCount && "light-red-row"
              }
            />
          </div>
          {/* {isModalVisible && (
            <CompanyForm
              isModalVisible={isModalVisible}
              handleCancel={handleCancel}
              handleOk={handleOk}
              form={form}
              loading={company_add_loading}
              okText="Create"
            />
          )} */}
        </>
      )}
    </>
  );
}

export default AgentCompanies;
