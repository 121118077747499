import React from "react";
import { useQuery } from "react-query";
import { api } from "../../services/Api";

const EmployeeBadge = ({ id }) => {
  const fetchEmployeeTasks = async () => {
    // Your fetch logic here
    return api.get(`/tasks/count/user/${id}`);
  };
  const { data, isLoading, isError } = useQuery(
    "employeeTaskscount",
    fetchEmployeeTasks,
    {
      refetchInterval: 10 * 1000, // Refetch every 2 minutes
    }
  );

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error fetching data</div>;

  const badgeStyle = {
    padding: "5px 10px",
    backgroundColor: "#007bff",
    color: "#fff",
    borderRadius: "5px",
    fontWeight: "bold",
  };

  return (
    <div>
      {" "}
      Tasks: <span style={badgeStyle}>{data?.data}</span>{" "}
    </div>
  );
};

export default EmployeeBadge;
