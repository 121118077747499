import { Avatar, Layout, Menu, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BsBuilding, BsKey } from "react-icons/bs";
import { FaTasks } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { HiMenuAlt3, HiOutlineNewspaper } from "react-icons/hi";
import {
  MdDashboard,
  MdOutlineGroups,
  MdOutlineNotifications,
  MdOutlineProductionQuantityLimits,
  MdPeopleOutline,
} from "react-icons/md";
import { useMutation, useQuery } from "react-query";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import verified from "../assets/icons/verified.svg";
import defaultUser from "../assets/images/default-user.png";
import Loading from "../components/common/Loading";
import hidePwdImg from "../components/login/hide-password.svg";
import showPwdImg from "../components/login/show-password.svg";
import AgentBadge from "../components/tasks/AgentBadge";
import AgentTasksBadge from "../components/tasks/AgentTasksBadge";
import EmployeeBadge from "../components/tasks/EmployeeBadge";
import TasksBadge from "../components/tasks/TasksBadge";
import { restriction } from "../constant/Restrictions";
import { BASEURL } from "../constant/Url";
import Employees from "../pages/dashboard/employee/Employee";
import EmployeeProfile from "../pages/dashboard/employee/EmployeeProfile";
import { api } from "../services/Api";
import { fetchUser } from "../services/User";
import Histories from "./dashboard/History";
import Main from "./dashboard/Main";
import Notifications from "./dashboard/Notification";
import AdminList from "./dashboard/admin/AdminList";
import Agents from "./dashboard/agent/Agent";
import AgentCompanies from "./dashboard/agent/AgentCompanies";
import AgentProfile from "./dashboard/agent/AgentProfile";
import AgentEmployeeTask from "./dashboard/agentTasks/AgentEmployeeTask";
import AgentTask from "./dashboard/agentTasks/AgentTask";
import SingleAgentTask from "./dashboard/agentTasks/SingleAgentTask";
import BillDetail from "./dashboard/bill/detail";
import BillTypes from "./dashboard/bill/types";
import ClientProfile from "./dashboard/client/ClientProfile";
import Clients from "./dashboard/client/Clients";
import Companies from "./dashboard/company/Companies";
import CompanyProfile from "./dashboard/company/CompanyDetail";
import CompanyTaskBadge from "./dashboard/company/CompanyTaskBadge";
import Feed from "./dashboard/feed/Feed";
import FeedDetail from "./dashboard/feed/FeedDetail";
import InventoryPage from "./dashboard/inventory/Inventory";
import InventoryHome from "./dashboard/inventory/InventoryHome";
import Merchants from "./dashboard/merchant/Merchants";
import NewsClient from "./dashboard/news/NewsClient";
import NewsSection from "./dashboard/news/NewsSection";
import BlogPage from "./dashboard/news/SinglePage";
import NewsCategory from "./dashboard/newsCategory/NewsCategory";
import ProductBrand from "./dashboard/product/ProductBrands";
import ProductCategory from "./dashboard/product/ProductCategory";
import ProductUOM from "./dashboard/product/ProductUOM";
import Products from "./dashboard/product/Products";
import AgentTaskDetail from "./dashboard/task/AgentTaskDetail";
import EmployeeTask from "./dashboard/task/EmployeeTask";
import Tasks from "./dashboard/task/Task";
import TaskDetail from "./dashboard/task/TaskDetail";

const { Sider, Content } = Layout;
function Dashboard() {
  const [loadingBtn, setLoadingBtn] = useState(false);

  const [passwordShown, setPasswordShown] = useState({
    old: false,
    new: false,
    confirm: false,
  });

  // change password api
  const ChangePw = (data) => {
    setLoadingBtn(true);
    const payload = {
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
    };
    return api.patch("users/change-password", payload);
  };
  const { mutate: changePwMutate } = useMutation(ChangePw, {
    onSuccess: () => {
      setIsModalVisible(false);
      setLoadingBtn(false);
      handleLogout();
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
      setLoadingBtn(false);
    },
  });

  const navigate = useNavigate();
  const [collapsednav, setCollapsedNav] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    changePwMutate(data);
    reset();
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token === null) {
      navigate(-1);
    }
  }, [navigate]);
  const { isLoading, data: userData } = useQuery("user", fetchUser);

  const dashboard = [
    {
      key: "/dashboard",
      icon: <MdDashboard />,
      label: "Dashboard",
    },
    {
      key: "/dashboard/tasks",
      icon: <FaTasks size={20} />,
      label:
        userData?.data?.role?.slug === "employee" ? (
          <EmployeeBadge id={userData?.data?._id} />
        ) : userData?.data?.role?.slug === "agent" ? (
          <AgentBadge id={userData?.data?._id} />
        ) : (
          <TasksBadge />
        ),
    },
  ];
  let items = [
    {
      key: "/dashboard/notifications",
      icon: <MdOutlineNotifications size={20} />,
      label: "Notifications",
    },
  ];

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  const Newsarea = [
    getItem("News Section ", "sub1", <HiOutlineNewspaper />, [
      getItem("News", "/dashboard/news_section", <HiOutlineNewspaper />),
      getItem(
        "News Category",
        "/dashboard/news_category",
        <HiOutlineNewspaper />
      ),
      getItem("News Client", "/dashboard/news_client", <HiOutlineNewspaper />),
    ]),
  ];
  const extraItems = [
    {
      key: "/dashboard/agent/companies",
      icon: <BsBuilding size={20} />,
      label: "Companies",
    },
  ];
  const taskItem = [
    {
      key: "/dashboard/tasks",
      icon: <FaTasks size={20} />,
      label: "Tasks",
    },
  ];

  const adminItems = [
    {
      key: "/dashboard/companies",
      icon: <BsBuilding size={20} />,
      label: <CompanyTaskBadge />,
    },
    {
      key: "/dashboard/employees",
      icon: <MdPeopleOutline size={20} />,
      label: "Employee",
    },
    {
      key: "/dashboard/admin",
      icon: <MdPeopleOutline size={20} />,
      label: "Admin",
    },
    {
      key: "/dashboard/clients",
      icon: <MdOutlineGroups size={20} />,
      label: "Clients",
    },

    {
      key: "/dashboard/agents",
      icon: <MdOutlineGroups size={20} />,
      label: "Agents",
    },

    {
      key: "/dashboard/agent-tasks",
      icon: <FaTasks size={20} />,
      label: <AgentTasksBadge />,
    },

    {
      key: "/dashboard/inventory/home",
      icon: <MdOutlineProductionQuantityLimits size={20} />,
      label: "Inventory",
    },
  ];
  const [collapsed, setCollapssed] = useState(false);

  if (isLoading) {
    return (
      <div className="loader-center">
        <Loading />
      </div>
    );
  }

  if (!restriction[userData?.data.role.slug]?.includes("view_inventory")) {
    items = [...items, ...adminItems];
  }

  if (!restriction[userData?.data.role.slug]?.includes("view_company")) {
    items = [...dashboard, ...items];
  }

  if (restriction[userData?.data.role.slug]?.includes("access_company")) {
    items = [...items, ...extraItems];
  }

  const handleLogout = async () => {
    const res = await api.delete(`${BASEURL}/users/logOut`);
    localStorage.removeItem("token");
    navigate("/");
  };

  const handleChanagePw = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    clearErrors();
    reset();
    setIsModalVisible(false);
    setLoadingBtn(false);
  };

  return (
    <Layout>
      <Sider
        collapsedWidth={60}
        trigger={null}
        collapsed={collapsed}
        collapsible
        width={220}
        className="sidebar"
      >
        <div>
          <div className="hammenu">
            <HiMenuAlt3
              size={20}
              onClick={() => {
                setCollapssed(!collapsed);
              }}
            />
          </div>
          {userData?.data.avatar?.url ? (
            <Avatar size="large" src={userData.data.avatar.url} alt="" />
          ) : (
            <Avatar size="large" src={defaultUser} alt="" />
          )}
          {!collapsed && (
            <>
              <div className="name">
                <span className="first" style={{ paddingRight: "6px" }}>
                  {`${userData?.data.firstName} ${userData?.data.lastName}`}
                </span>
                <span className="verified">
                  <img src={verified} alt=""></img>
                </span>
              </div>
              <div className="position">
                {userData?.data?.role?.name === "Agent"
                  ? "Support"
                  : userData?.data?.role?.name ?? ""}
              </div>
            </>
          )}

          <Menu
            className="side-nav"
            onClick={(e) => {
              if (e.key === "/dashboard/inventory/home") {
                window.open(e.key, "_blank");
              } else {
                navigate(e.key);
              }
            }}
            items={items}
          />

          <Menu
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            mode="inline"
            inlineCollapsed={collapsednav}
          >
            {Newsarea.map((section) => (
              <Menu.SubMenu
                key={section.key}
                icon={section.icon}
                title={section.label}
              >
                {restriction[userData?.data.role?.slug]?.includes(
                  "no-access-newsclient"
                )
                  ? section.children?.slice(0, 1).map((child) => (
                      <Menu.Item key={child.key}>
                        <Link
                          to={child.key}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          {child.icon}
                          {child.label}
                        </Link>
                      </Menu.Item>
                    ))
                  : section.children.map((child) => (
                      <Menu.Item key={child.key}>
                        <Link
                          to={child.key}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          {child.icon}
                          {child.label}
                        </Link>
                      </Menu.Item>
                    ))}
              </Menu.SubMenu>
            ))}
          </Menu>
        </div>
        <div>
          <button className="btn-logout" onClick={handleChanagePw}>
            {collapsed ? <BsKey size={20} /> : "Change password"}
          </button>
          <button className="btn-logout" onClick={handleLogout}>
            {collapsed ? <FiLogOut size={20} /> : "Log out"}
          </button>
        </div>
      </Sider>
      <Content className="main-content">
        <Routes>
          <Route exact path="/" element={<Main />} />
          <Route exact path="/clients" element={<Clients role="user" />} />
          <Route exact path="/agents" element={<Agents />} />
          <Route path="/clients/:clientId" element={<ClientProfile />} />
          <Route path="/agents/:clientId" element={<AgentProfile />} />
          <Route
            path="/clients/:clientId/billtypes/company/:companyId"
            element={<BillTypes />}
          />
          <Route
            path="/agents/:clientId/billtypes/company/:companyId"
            element={<BillTypes />}
          />
          <Route
            path="/clients/:clientId/billtypes/company/:companyId/:billId"
            element={<BillDetail />}
          />
          <Route
            path="/agents/:clientId/billtypes/company/:companyId/:billId"
            element={<BillDetail />}
          />
          <Route
            path="/companies/:companyId/bill-items"
            element={<BillTypes />}
          />
          <Route
            path="/companies/:companyId/bill-items/:billId"
            element={<BillDetail />}
          />

          <Route
            path="/tasks"
            element={
              userData?.data?.role?.name === "Agent" ? (
                <SingleAgentTask />
              ) : userData?.data?.role?.name === "Admin" ||
                userData?.data?.role?.name === "Super Admin" ? (
                <Tasks />
              ) : (
                <EmployeeTask />
              )
            }
          />

          <Route
            path="/agent-tasks"
            element={
              userData?.data?.role?.name === "Admin" ||
              userData?.data?.role?.name === "Super Admin" ? (
                <AgentTask />
              ) : (
                <AgentEmployeeTask />
              )
            }
          />
          <Route path="/tasks/:taskId" element={<TaskDetail />} />
          <Route path="/agent-tasks/:taskId" element={<AgentTaskDetail />} />

          <Route path="/employees" element={<Employees />} />
          <Route path="/admin" element={<AdminList />} />

          <Route path="/employees/:employeeId" element={<EmployeeProfile />} />
          <Route path="/admin/:employeeId" element={<EmployeeProfile />} />

          <Route path="/productCategory" element={<ProductCategory />} />
          <Route path="/brands" element={<ProductBrand />} />
          <Route path="/uom" element={<ProductUOM />} />
          <Route path="/companies" element={<Companies />} />
          <Route path="/agent/companies" element={<AgentCompanies />} />

          <Route path="/companies/:companyId" element={<CompanyProfile />} />
          <Route
            path="/companies/:companyId/inventory"
            element={<InventoryPage />}
          />
          <Route path="/inventory/home" element={<InventoryHome />} />
          <Route path="/feeds" element={<Feed />} />
          <Route path="/feeds/:feedId" element={<FeedDetail />} />
          <Route path="/products" element={<Products />} />
          <Route path="/merchants" element={<Merchants />} />
          <Route path="/histories" element={<Histories />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/news" element={<NewsSection />} />
          <Route path="/news-section/news/:id" element={<BlogPage />} />

          <Route path="/news_category" element={<NewsCategory />} />
          <Route path="/news_section" element={<NewsSection />} />
          <Route path="/news_client" element={<NewsClient />} />
        </Routes>
      </Content>
      <Modal
        onCancel={handleCancel}
        footer={false}
        maskClosable={false}
        title={"Change Password"}
        visible={isModalVisible}
        style={{
          overflow: "hidden",
          background: "white",
          paddingBottom: "0px",
          borderRadius: "1rem",
        }}
      >
        {/* <ChangePw /> */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            style={{
              position: "relative",
              border: "1px solid #c4bfbf",
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              padding: "0 0.5rem",
            }}
          >
            <input
              type={passwordShown.old ? "text" : "password"}
              className="st-input"
              style={{ marginBottom: "0", border: "none", outline: "none" }}
              name="oldPassword"
              placeholder="Enter Old Password"
              {...register("oldPassword", {
                required: "This field is required",
                minLength: {
                  value: 6,
                  message: "Minimum 6 character is required",
                },
              })}
            />

            <img
              style={{ cursor: "pointer" }}
              width={20}
              height={20}
              src={passwordShown.old ? hidePwdImg : showPwdImg}
              onClick={() =>
                setPasswordShown({ ...passwordShown, old: !passwordShown.old })
              }
              alt="password"
              className="passEye"
            />
          </div>
          <span
            style={{
              color: "red",
              marginTop: "-0.5rem",
              display: "block",
              marginBottom: "1rem",
            }}
          >
            {errors?.oldPassword?.message}
          </span>
          <div
            style={{
              position: "relative",
              border: "1px solid #c4bfbf",
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              padding: "0 0.5rem",
            }}
          >
            <input
              style={{ marginBottom: "0", border: "none", outline: "none" }}
              type={passwordShown.new ? "text" : "password"}
              className="st-input"
              name="newPassword"
              placeholder="Enter New Password"
              {...register("newPassword", {
                required: "This field is required",
                minLength: {
                  value: 6,
                  message: "Minimum 6 character is required",
                },
              })}
            />
            <img
              style={{ cursor: "pointer" }}
              width={20}
              height={20}
              src={passwordShown.new ? hidePwdImg : showPwdImg}
              onClick={() =>
                setPasswordShown({ ...passwordShown, new: !passwordShown.new })
              }
              alt="password"
              className="passEye"
            />
          </div>
          <span
            style={{
              color: "red",
              marginTop: "-0.5rem",
              display: "block",
              marginBottom: "1rem",
            }}
          >
            {errors.newPassword?.message}
          </span>
          <div
            style={{
              position: "relative",
              border: "1px solid #c4bfbf",
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              padding: "0 0.5rem",
            }}
          >
            <input
              style={{ marginBottom: "0", border: "none", outline: "none" }}
              type={passwordShown.confirm ? "text" : "password"}
              className="st-input"
              name="confirmPassword"
              placeholder="Confirm New Password"
              {...register("confirmPassword", {
                required: "This field is required",
                minLength: {
                  value: 6,
                  message: "Minimum 6 character is required",
                },
                validate: (value) => {
                  if (watch("newPassword") !== value) {
                    // console.log("error", errors);
                    return "Your password does not match";
                  }
                },
              })}
            />
            <img
              style={{ cursor: "pointer" }}
              width={20}
              height={20}
              src={passwordShown.confirm ? hidePwdImg : showPwdImg}
              onClick={() =>
                setPasswordShown({
                  ...passwordShown,
                  confirm: !passwordShown.confirm,
                })
              }
              alt="password"
              className="passEye"
            />
          </div>
          <span
            style={{
              color: "red",
              marginTop: "-0.5rem",
              display: "block",
              marginBottom: "1rem",
            }}
          >
            {errors.confirmPassword?.message}
          </span>
          <div>
            <button className="btn-custom mt-16" loading={loadingBtn}>
              {loadingBtn ? <Loading isSmall /> : "Change Password"}
            </button>
          </div>
        </form>
      </Modal>
    </Layout>
  );
}

export default Dashboard;
