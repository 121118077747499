import React, { useContext, useState } from "react";
import moment from "moment";
import { useQuery, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { fetchUser } from "../../../services/User";
import {
  Tabs,
  Table,
  Tag,
  Button,
  Form,
  Row,
  Avatar,
  Tooltip,
  Dropdown,
  Menu,
  Popover,
  Progress,
} from "antd";
import { api } from "../../../services/Api";
import { MdAddTask } from "react-icons/md";
import { BsFlag, BsPersonPlusFill } from "react-icons/bs";
import { BiUserPlus, BiUpload } from "react-icons/bi";
import { restriction } from "../../../constant/Restrictions";
import { stringToColour } from "../../../utils/getProfileColor";
import DebounceSelect from "../../../components/common/DebounceSelect";
import { useEffect } from "react";
import PageSearch from "../../../components/common/PageSearch";
import { debounce } from "lodash/debounce";
import { limit } from "../../../utils/truncateString";
import { multiTabContext } from "../../../components/reducer/context";
import AgentTaskForm from "./AgentTaskForm";
const { TabPane } = Tabs;

const colorByStatus = {
  Pending: "volcano",
  Ongoing: "yellow",
  Completed: "green",
};

const priorityFlag = {
  Low: "green",
  High: "red",
  Medium: "#e4d422",
};

function AgentEmployeeTask() {
  const { activeTab, setActiveTab } = useContext(multiTabContext);
  const navigate = useNavigate();
  const [createTaskForm] = Form.useForm();
  const { data: userData } = useQuery("user", fetchUser);
  const [params, setParams] = useState({
    sort: "-updatedAt",
    page: 1,
    limit: 100000,
    // skip: 0,
    // status: undefined,
  });

  const [regexQuery, setRegexQuery] = useState("");
  const priorityTask = (data) => {
    const payload = {
      priority: data.priority,
    };
    return api.patch("/tasks/" + data.taskId, payload);
  };

  const { mutate: priority_task_mutate } = useMutation(priorityTask, {
    onSuccess: () => {
      refetch();
      setPopoverVisible(false);
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });
  const assignTask = (data) => {
    const payload = {
      assignee: data.assignee,
    };
    return api.patch("/tasks/" + data.taskId + "/assign-user", payload);
  };
  const { mutate: assign_task_mutate } = useMutation(assignTask, {
    onSuccess: () => {
      refetch();
      setPopoverVisible(false);
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });

  const [popoverVisible, setPopoverVisible] = useState(false);
  const getUsers = async (queryString, exclude) => {
    return api
      .get("/users", {
        params: {
          "userName!": exclude.join(","),
          "relation_role.slug!": "user",
          firstName: encodeURI(`/${queryString}/i`),
        },
      })
      .then((body) =>
        body.data.docs.map((user) => ({
          label: `${user.firstName} ${user.lastName}`,
          value: user._id,
        }))
      );
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, record) => (
        <p
          onClick={() => {
            navigate(`${record._id}`);
          }}
          style={{ color: "black" }}
        >
          {limit(text, 50)}
        </p>
      ),
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      render: (company, _record) => (
        <>
          <p>{limit(company?.name, 50)}</p>
        </>
      ),
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      sorter: (a, b) => moment(a.dueDate) - moment(b.dueDate),
      render: (text) => (
        <p
          style={{ color: moment(text).fromNow() === "in a day" ? "red" : "" }}
        >
          {moment(text).fromNow()}
        </p>
      ),
    },

    {
      title: "Assigned To",
      dataIndex: "assignee",
      key: "assignee",
      render: (assignee, record) => (
        <Avatar.Group
          maxCount={5}
          maxPopoverTrigger="hover"
          size="small"
          maxStyle={{
            color: "#f56a00",
            backgroundColor: "#fde3cf",
            cursor: "pointer",
          }}
        >
          {assignee.map((user, i) => {
            const color = stringToColour(user.userName);
            return (
              <>
                <div key={i}>
                  <Tooltip
                    title={<div>{`${user.firstName} ${user.lastName}`}</div>}
                    placement="top"
                  >
                    {user.avatar?.url ? (
                      <Avatar src={user.avatar.url} />
                    ) : (
                      <Avatar
                        className="capital"
                        style={{ backgroundColor: color }}
                      >
                        {user.firstName[0] ?? "U"}
                      </Avatar>
                    )}
                  </Tooltip>
                </div>
              </>
            );
          })}
          {!restriction[userData?.data.role.slug]?.includes("add_assignee") && (
            <Popover
              open={popoverVisible}
              onOpenChange={() => setPopoverVisible(true)}
              content={
                <div
                  style={{
                    width: "300px",
                  }}
                >
                  <DebounceSelect
                    fetchOptions={(query) =>
                      getUsers(query, assignee?.map((e) => e.userName) ?? [])
                    }
                    onChange={(e) => {
                      assign_task_mutate({ taskId: record._id, assignee: e });
                    }}
                    placeholder="Assignee"
                  />
                </div>
              }
              trigger="click"
              placement="bottomLeft"
            >
              <Avatar
                className="capital"
                size="small"
                style={{
                  backgroundColor: "#87d068",
                  cursor: "pointer",
                }}
              >
                <BiUserPlus size={12} />
              </Avatar>
            </Popover>
          )}
        </Avatar.Group>
      ),
    },

    {
      title: "Status ",
      dataIndex: "status",
      key: "status",
      filters: [
        {
          text: "Pending",
          value: "Pending",
        },
        {
          text: "Ongoing",
          value: "Ongoing",
        },
        {
          text: "Completed",
          value: "Completed",
        },
      ],
      onFilter: (value, record) => record.status === value,
      render: (status, record) => {
        return (
          <Row>
            <Tag color={colorByStatus[status]}>{status}</Tag>
          </Row>
        );
      },
    },

    {
      title: "% Completed",
      dataIndex: "status",
      key: "status",
      filters: [
        {
          text: "Pending",
          value: "Pending",
        },
        {
          text: "Ongoing",
          value: "Ongoing",
        },
        {
          text: "Completed",
          value: "Completed",
        },
      ],

      onFilter: (value, record) => record.status === value,
      render: (status, record) => {
        const total = record?.modules?.length;
        const checked = record?.modules?.filter(
          (c, i) => c?.status === "Completed"
        )?.length;
        const percent = Math.round((checked / total) * 100);
        return (
          <Row>
            <div
              style={{
                width: 100,
              }}
            >
              {status === "Ongoing" && (
                <Progress percent={percent} size="small" />
              )}
            </div>
          </Row>
        );
      },
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      render: (priority, record) => (
        <>
          {!restriction[userData?.data.role.slug]?.includes("add_assignee") ? (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    onClick={() =>
                      priority_task_mutate({
                        taskId: record._id,
                        priority: "High",
                      })
                    }
                  >
                    High
                  </Menu.Item>
                  <Menu.Item
                    onClick={() =>
                      priority_task_mutate({
                        taskId: record._id,
                        priority: "Medium",
                      })
                    }
                  >
                    Medium
                  </Menu.Item>
                  <Menu.Item
                    onClick={() =>
                      priority_task_mutate({
                        taskId: record._id,
                        priority: "Low",
                      })
                    }
                  >
                    Low
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <BsFlag color={priorityFlag[priority ?? "Medium"]} />
            </Dropdown>
          ) : (
            <BsFlag color={priorityFlag[priority ?? "Medium"]} />
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    if (restriction[userData?.data.role.slug]?.includes("view_all_task")) {
      setParams({ ...params, assignee: userData.data.id });
    }
  }, [userData.data.id]);

  const fetchTask = () => {
    return api.get("/tasks", {
      params: { ...params, title: encodeURI(`/${regexQuery}/i`) },
    });
  };

  const createTask = async (data) => {
    const res = await api.post("/ ", data);
    if (data.markAsTemplate) {
      await api.patch(`tasks/${res.data?._id}/mark-as-template`);
    }
  };

  const { mutate: add_task_mutation, isLoading: add_task_mutation_loading } =
    useMutation(createTask, {
      onSuccess: () => {
        refetch();
        handleCancel();
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });

  const addTask = () => {
    createTaskForm.validateFields().then((values) => {
      values.status = "Pending";
      add_task_mutation(values);
    });
  };

  const {
    isLoading: task_loading,
    data: task_data,
    refetch,
  } = useQuery(["task", regexQuery, params], fetchTask);


  const userId = userData.data._id;

  const assignedTask = task_data?.data?.docs?.filter((data) =>
    data.assignee.some((assignee) => assignee._id === userId)
  );

  const employeeLogin = userData?.data.role.slug === "employee";
  const [visible, setVisible] = useState(false);
  const handleCancel = () => {
    createTaskForm.resetFields();
    setVisible(false);
  };

  const [filteredAllData, setFilteredAllData] = useState([]);
  const [filteredPendingData, setFilteredPendingData] = useState([]);
  const [filteredOngoingData, setFilteredOngoingData] = useState([]);
  const [filteredComletedData, setFilteredComletedData] = useState([]);
  const [manualParams, setManualParams] = useState({
    page: 1,
    index: 0,
  });
  const filteredData = task_data?.data?.docs.filter((item) =>
    item?.assignee.some((a) => a?._id === userId)
  );

  const pendingData = filteredData?.filter(
    (item) => item?.status === "Pending"
  );

  const ongoingData = filteredData?.filter(
    (item) => item?.status === "Ongoing"
  );

  const completedData = filteredData?.filter(
    (item) => item?.status === "Completed"
  );

  useEffect(() => {
    setFilteredAllData(
      filteredData?.slice(manualParams.index, filteredData?.length)
    );
  }, [task_data, userId]);

  useEffect(() => {
    setFilteredPendingData(
      pendingData?.slice(manualParams.index, pendingData?.length)
    );
  }, [task_data, userId]);

  useEffect(() => {
    setFilteredOngoingData(
      ongoingData?.slice(manualParams.index, ongoingData?.length)
    );
  }, [task_data, userId]);

  useEffect(() => {
    setFilteredComletedData(
      completedData?.slice(manualParams.index, completedData?.length)
    );
  }, [task_data, userId]);

  // TODO:ongoing data nikalne

  const ongoing_data_array = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  assignedTask?.forEach((task) => {
    const monthNumber = moment(task.startDate).format("M");
    ongoing_data_array[monthNumber - 1]++;
  });

  return (
    <>
      <div className="main-container">
        <Row className="content-apart">
          <h1 className="main-title">Task</h1>
          <Row>
            <PageSearch
              onChange={(e) => {
                debounce(setRegexQuery(e.target.value), 800);
              }}
              placeholder="Search for Tasks"
            />
            {!restriction[userData?.data.role.slug]?.includes(
              "change_task"
            ) && (
              <Button
                style={{ borderRadius: "0.5em" }}
                type="primary"
                onClick={() => {
                  setVisible(true);
                }}
              >
                <MdAddTask size={20} />
              </Button>
            )}
          </Row>
        </Row>
        <Tabs
          defaultActiveKey={activeTab}
          onTabClick={(status) => {
            setManualParams({
              page: 1,
              index: 0,
            });

            if (status === "All") {
              setParams({ ...params, status: undefined, page: 1, skip: 0 });
            } else {
              setParams({ ...params, status, page: 1, skip: 0 });
            }
            refetch();
          }}
          // defaultActiveKey="All"
        >
          <TabPane tab="All" key="All">
            <Table
              loading={task_loading}
              size="small"
              pagination={{
                current: manualParams.page,
                total: filteredData?.length,
                pageSize: 14,
                showSizeChanger: false,
                onChange: (page) => {
                  setManualParams({
                    page,
                    index: (page - 1) * 14,
                  });
                },
              }}
              className="fiscal-table"
              columns={columns}
              dataSource={filteredAllData}
            />
          </TabPane>
          <TabPane tab="Pending" key="Pending">
            <Table
              loading={task_loading}
              pagination={{
                current: manualParams.page,
                total: filteredPendingData?.length,
                pageSize: 14,
                onChange: (page) => {
                  setParams({
                    ...params,
                    page,
                    skip: (page - 1) * params.limit,
                  });
                },
              }}
              size="small"
              onRow={(record, _) => {
                return {
                  onClick: (_) => {
                    navigate(`${record._id}`);
                  },
                };
              }}
              className="fiscal-table"
              columns={columns}
              dataSource={filteredPendingData}
            />
          </TabPane>
          <TabPane tab="Ongoing" key="Ongoing">
            <Table
              pagination={{
                current: manualParams.page,
                total: filteredOngoingData?.length,
                pageSize: 14,
                onChange: (page) => {
                  setManualParams({
                    page,
                    index: (page - 1) * 14,
                  });
                },
              }}
              loading={task_loading}
              size="small"
              onRow={(record, _) => {
                return {
                  onClick: (_) => {
                    navigate(`${record._id}`);
                  },
                };
              }}
              className="fiscal-table"
              columns={columns}
              dataSource={filteredOngoingData}
            />
          </TabPane>
          <TabPane tab="Completed" key="Completed">
            <Table
              pagination={{
                current: manualParams.page,
                total: filteredComletedData?.length,
                showSizeChanger: false,

                pageSize: 14,
                onChange: (page) => {
                  setManualParams({
                    page,
                    index: (page - 1) * 14,
                  });
                },
              }}
              loading={task_loading}
              size="small"
              onRow={(record, _) => {
                return {
                  onClick: (_) => {
                    navigate(`${record._id}`);
                  },
                };
              }}
              className="fiscal-table"
              columns={columns}
              dataSource={filteredComletedData}
            />
          </TabPane>
        </Tabs>
      </div>

      {visible && (
        <AgentTaskForm
          okText="Create"
          isModalVisible={visible}
          form={createTaskForm}
          handleCancel={handleCancel}
          handleOk={addTask}
          loading={add_task_mutation_loading}
          headingText=""
        />
      )}
    </>
  );
}

export default AgentEmployeeTask;
