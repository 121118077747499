import React, { useState } from "react";
import moment from "moment";
import { useForm } from "react-hook-form";
import { api } from "../../../services/Api";
import { useQuery, useMutation } from "react-query";
import { MdModeEdit } from "react-icons/md";
import { Row, Table, Space, Menu, Dropdown } from "antd";
import { IoEllipsisVertical } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import Dialog from "../../../components/common/Dialog";
import debounce from "lodash/debounce";
import PageSearch from "../../../components/common/PageSearch";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { fetchUser } from "../../../services/User";
import { restriction } from "../../../constant/Restrictions";
import PlusImg from "../../../assets/images/plus.png";
import DelImg from "../../../assets/images/delete-button.png";

function ProductUOM() {
  const navigate = useNavigate();
  const [regexQuery, setRegexQuery] = useState("");
  const { isUserLoading, data: userData } = useQuery("user", fetchUser);
  const [showForm, setShowForm] = useState(false);
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Last updated",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: (a, b) => moment(a.updatedAt) - moment(b.updatedAt),
      render: (text) => <p>{moment(text).fromNow()}</p>,
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <div>
          {!restriction[userData?.data.role.slug].includes(
            "change_inventory"
          ) && (
            <Dropdown
              overlay={
                <Menu mode="horizontal">
                  <Menu.Item
                    key="one"
                    className="edit-btn"
                    onClick={() => showModal(record)}
                    icon={
                      <MdModeEdit style={{ color: "blue", fontSize: "20px" }} />
                    }
                  >
                    Edit
                  </Menu.Item>

                  <Menu.Item
                    key="two"
                    className="delete-btn"
                    onClick={() => handleDelete(text)}
                    icon={
                      <MdDelete style={{ color: "red", fontSize: "20px" }} />
                    }
                  >
                    Delete
                  </Menu.Item>
                </Menu>
              }
            >
              <Space>
                <IoEllipsisVertical
                  style={{
                    cursor: "pointer",
                    color: "#00ABC5",
                  }}
                  size={20}
                />
              </Space>
            </Dropdown>
          )}
        </div>
      ),
    },
  ];

  const [visible, setVisible] = useState(false);
  const [cat, setCat] = useState({
    id: "",
    name: "",
  });

  const showModal = (data) => {
    setVisible(true);
    setCat(data);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const {
    register,
    handleSubmit: handleSubmit1,
    reset,
    formState: { errors },
  } = useForm();

  const getCategory = () => {
    return api.get("/unit-of-measurements", {
      params: {
        name: encodeURI(`/${regexQuery}/i`),
      },
    });
  };
  const createCategory = (data) => {
    return api.post("/unit-of-measurements", data);
  };
  const removeCategory = (id) => {
    return api.delete("/unit-of-measurements/" + id);
  };
  const updateCategory = (data) => {
    return api.patch("/unit-of-measurements/" + data.id, data);
  };

  const { data, refetch } = useQuery(
    ["unit-of-measurements", regexQuery],
    getCategory
  );

  const { mutate: mutate1 } = useMutation(createCategory, {
    onSuccess: () => {
      refetch();
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });

  const { mutate: mutate2 } = useMutation(removeCategory, {
    onSuccess: () => {
      refetch();
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });
  const { mutate: mutate3 } = useMutation(updateCategory, {
    onSuccess: () => {
      refetch();
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });
  const addCategory = (data) => {
    mutate1(data);
    reset();
    setShowForm(false);
  };

  const editCategory = (cat) => {
    mutate3(cat);
    handleCancel();
  };
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure ?",
      text: "You want to delete this unit of measure",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Ok`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        mutate2(id);
      }
    });
  };

  const handleChange = (event) => {
    setCat({ ...cat, name: event.target.value });
  };

  const visibleForm = () => {
    setShowForm(!showForm);
  };

  return (
    <>
      <div className="main-container">
        <Row className="content-apart mb-10">
          <Row>
            <h1
              style={{
                cursor: "pointer",
              }}
              className="main-title"
            >
              Product UOM
            </h1>
            &nbsp;&nbsp;
            {!restriction[userData?.data.role.slug].includes(
              "change_inventory"
            ) && (
              <>
                <form>
                  <img
                    onClick={visibleForm}
                    src={showForm ? DelImg : PlusImg}
                    alt="images"
                    className="brandImg"
                  />
                </form>
                &nbsp;&nbsp;&nbsp;
                {showForm && (
                  <form onSubmit={handleSubmit1(addCategory)} className="flex">
                    <input
                      type="text"
                      name="name"
                      className="st-input"
                      placeholder="Enter product UOM"
                      style={{
                        color: "black",
                        marginRight: "1rem",
                        borderColor: "#f2f2f2f",
                        width: "400px",
                      }}
                      {...register("name", { required: true })}
                    />
                    {errors.name?.type === "required" && (
                      <span style={{ color: "red" }}>
                        {" "}
                        This field is required
                      </span>
                    )}
                    <div>
                      <button
                        style={{ borderRadius: "0.5em" }}
                        type="submit"
                        className="btn-custom"
                      >
                        Add UOM
                      </button>
                    </div>
                  </form>
                )}
              </>
            )}
          </Row>

          <Row>
            <PageSearch
              onChange={(e) => {
                debounce(setRegexQuery(e.target.value), 800);
              }}
              placeholder="Search for uoms"
            />
          </Row>
        </Row>
        <Table
          size="small"
          // scroll={{ y: "35vh" }}
          className="fiscal-table"
          columns={columns}
          dataSource={data?.data.docs}
        />
        <Dialog
          visible={visible}
          handleCancel={handleCancel}
          showModal={showModal}
        >
          <form onSubmit={(e) => e.preventDefault(editCategory(cat))}>
            <input
              type="text"
              name="ename"
              className="st-input"
              id={cat.id}
              value={cat.name}
              onChange={handleChange}
              placeholder="Enter product categroy"
              style={{
                color: "black",
                marginRight: "1rem",
                borderColor: "#f2f2f2f",
                marginTop: "25px",
              }}
            />
            <div style={{ marginTop: "1rem" }}>
              <button key="submit" className="btn-custom">
                Update
              </button>
            </div>
          </form>
        </Dialog>
      </div>
    </>
  );
}

export default ProductUOM;
