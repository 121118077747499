import { Avatar, Col, Row } from "antd";
import moment from "moment";
import React from "react";
import NotificationImage from "../assets/images/notification.png";

const NotificationComponent = ({
  userData,
  notification_data,
  filteredData,
}) => {
  return (
    <Row className="notification-list">
      <Col span={24}>
        {(userData?.data?.role?.slug === "superadmin"
          ? notification_data?.data?.docs
          : filteredData
        )?.map((n, i) => {
          return (
            <div className={`notification-item unread`} key={i}>
              <div className="notification-header">
                <Avatar className="notify-img" src={NotificationImage} />
                <div className="notification-info">
                  <h1 className="notification-title">{n.title}</h1>
                  <p className="notification-date">
                    {moment(n.createdAt).format("MMM Do, h:mm a")}
                  </p>
                </div>
              </div>

              <div className="notification-content">
                <p>{n.description}</p>
              </div>
            </div>
          );
        })}
      </Col>
    </Row>
  );
};

export default NotificationComponent;
