import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  Row,
  Space,
  message,
} from "antd";
import React, { useState } from "react";
import { FaEdit, FaTrash, FaUserEdit } from "react-icons/fa";
import { IoEllipsisVertical } from "react-icons/io5";
import {
  MdContactPhone,
  MdEmail,
  MdModeEditOutline,
  MdOutlineDriveFileRenameOutline,
  MdOutlineTimer,
} from "react-icons/md";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import profile from "../../../assets/images/default-user.png";
import Loading from "../../../components/common/Loading";
import { restriction } from "../../../constant/Restrictions";
import { api } from "../../../services/Api";
import { fetchUser } from "../../../services/User";

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { FaPlaceOfWorship } from "react-icons/fa";
import ProfileCard from "../../../components/common/ProfileCard";
import { limit } from "../../../utils/truncateString";
import AgentForm from "./AgentForm";

function AgentProfile() {
  const navigate = useNavigate();
  const [clientForm] = Form.useForm();
  const [isClientEditModalVisible, setClientEditModalVisible] = useState(false);
  const [passwordForm] = Form.useForm();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { isUserLoading, data: userData } = useQuery("user", fetchUser);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handlePasswordOk = () => {
    setIsModalOpen(false);
  };
  const handlePasswordCancel = () => {
    setIsModalOpen(false);
  };
  let { clientId } = useParams();

  const getData = () => {
    return api.get("/users/" + clientId);
  };

  const {
    isLoading,
    data,
    refetch: client_refetch,
  } = useQuery("clientdetail", getData);

  const removeClient = () => {
    return api.delete(`/users/${clientId}`);
  };

  const handleClientEditCancel = () => {
    clientForm.resetFields();
    setClientEditModalVisible(false);
  };

  const handleClientEditOk = () => {
    clientForm
      .validateFields()
      .then((values) => {
        client_patch_mutate(values);
      })
      .catch((info) => {});
  };

  const { mutate: client_delete_mutate, isLoading: client_delete_loading } =
    useMutation(removeClient, {
      onSuccess: () => {
        navigate("/dashboard/agents");
      },
      onError: () => {
        alert("There was an error");
      },
    });

  const handleClientDelete = () => {
    Swal.fire({
      title: "Are you sure ?",
      text: "You want to delete this Company.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Ok`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        client_delete_mutate();
      }
    });
  };

  const patchClient = (data) => {
    return api.patch(`/users/${clientId}`, data);
  };

  const patchPassword = (data) => {
    const payload = {
      password: data.password,
    };
    return api.patch(`/users/${clientId}/update-password`, payload);
  };

  const { mutate: password_patch_mutate, isLoading: password_patch_loading } =
    useMutation(patchPassword, {
      onSuccess: () => {
        client_refetch();
        navigate("/dashboard/agents");
        passwordForm.resetFields();
        setIsModalOpen(false);
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });

  const { mutate: client_patch_mutate, isLoading: client_patch_loading } =
    useMutation(patchClient, {
      onSuccess: () => {
        client_refetch();
        clientForm.resetFields();
        setClientEditModalVisible(false);
      },
      onError: () => {
        alert("There was an error");
      },
    });
  if (isLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Loading />
      </div>
    );
  }
  const menu = (
    <Menu mode="horizontal">
      <Menu.Item
        key="one"
        onClick={() => {
          setClientEditModalVisible(true);
        }}
        icon={
          <FaEdit
            style={{
              color: "blue",
              fontSize: "20px",
              marginRight: "10px",
            }}
          />
        }
      >
        Edit
      </Menu.Item>

      <Menu.Item
        key="two"
        onClick={() => {
          handleClientDelete();
        }}
        icon={
          <FaTrash
            style={{
              color: "red",
              fontSize: "20px",
              marginRight: "10px",
            }}
          />
        }
      >
        Delete
      </Menu.Item>
      <Menu.Item
        key="three"
        icon={
          <FaUserEdit
            style={{
              color: "green",
              fontSize: "20px",
              marginRight: "8px",
            }}
          />
        }
        onClick={showModal}
      >
        Change Password
      </Menu.Item>
    </Menu>
  );

  const toggleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  return (
    <>
      <div className="main-container">
        <Row className="content-apart">
          <div className="breadcrumb">
            <span
              onClick={() => {
                navigate(-1);
              }}
              className="main-title link-element"
            >
              Agent/
            </span>
            <span className="main-subtitle">{data?.data.userName}</span>
          </div>

          {!restriction[userData?.data.role.slug].includes("change_client") && (
            <Dropdown overlay={menu}>
              <Space>
                <IoEllipsisVertical size={20} />
              </Space>
            </Dropdown>
          )}
        </Row>
        <Row>
          <Col span={12}>
            <ProfileCard
              imgSrc={data?.data.avatar?.url ?? profile}
              icon={<MdModeEditOutline />}
              title={`${data?.data?.firstName} ${data?.data?.lastName}`}
            >
              <Row className="content-center profile-meta">
                <MdContactPhone className="mr-10" />
                {data?.data.phoneNumber}
              </Row>
              <Row className="content-center profile-meta">
                <p>
                  <MdEmail /> {limit(data?.data.email, 14)}
                </p>
              </Row>
            </ProfileCard>
          </Col>
          <Col span={12}>
            <div className="client-company-details shadow-card">
              <h1 style={{ fontSize: "24px", fontWeight: "bold" }}>
                Company Details
              </h1>
              <hr />
              <p style={{ fontSize: "15px", opacity: "0.8" }}>
                <MdOutlineDriveFileRenameOutline /> &nbsp; <span>Name: </span>
                {data?.data.company?.name}
              </p>
              <p style={{ fontSize: "15px", opacity: "0.8" }}>
                <MdContactPhone /> &nbsp; <span>Contact: </span>
                {data?.data.company?.phone}
              </p>
              <p style={{ fontSize: "15px", opacity: "0.8" }}>
                <MdOutlineTimer /> &nbsp; <span>NepaliName: </span>
                {data?.data.company?.nepaliName}
              </p>
              <p style={{ fontSize: "15px", opacity: "0.8" }}>
                <FaPlaceOfWorship /> &nbsp; <span>Municipality: </span>
                {data?.data.company?.address?.municipality?.label ||
                  data?.data.company?.address?.municipality}
              </p>
            </div>
          </Col>
        </Row>
      </div>
      {isClientEditModalVisible && (
        <AgentForm
          isModalVisible={isClientEditModalVisible}
          form={clientForm}
          okText="Update"
          handleCancel={handleClientEditCancel}
          handleOk={handleClientEditOk}
          loading={client_patch_loading}
          headingText="Update this agent"
          initialValues={data?.data}
        />
      )}

      {isModalOpen && (
        <Modal
          title="Change Password"
          visible={isModalOpen}
          onOk={handlePasswordOk}
          onCancel={handlePasswordCancel}
          footer={[
            <Button
              loading={password_patch_loading}
              type="primary"
              htmlType="submit"
              onClick={() => {
                passwordForm
                  .validateFields()
                  .then((values) => {
                    password_patch_mutate(values);
                    message.success(
                      <div>
                        <p style={{ color: "black" }}>Password Changed!</p>
                      </div>
                    );
                  })
                  .catch((info) => {
                    console.log("Validate Failed:", info);
                  });
              }}
            >
              Change Password
            </Button>,
          ]}
        >
          <Form form={passwordForm} autoComplete="off" layout="vertical">
            <Form.Item
              label="New Password"
              name="password"
              required
              rules={[
                {
                  required: true,
                  pattern: new RegExp(
                    // eslint-disable-next-line no-useless-escape
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/
                  ),
                  message:
                    "Password length must be 6 to 10 characters with at least one uppercase, one lowercase, one number and one special character!",
                },
              ]}
              hasFeedback
            >
              <div style={{ display: "flex" }}>
                <Input
                  placeholder="New Password"
                  type={showNewPassword ? "text" : "password"}
                />
                <Button
                  style={{
                    borderRadius: 8,
                    marginLeft: 10,
                    padding: 5,
                  }}
                  icon={
                    !showNewPassword ? (
                      <AiOutlineEye />
                    ) : (
                      <AiOutlineEyeInvisible />
                    )
                  }
                  onClick={toggleShowNewPassword}
                ></Button>
              </div>
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name="confirm password"
              required
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The password that you have entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <div style={{ display: "flex" }}>
                <Input
                  placeholder="Confirm Password"
                  type={showConfirmPassword ? "text" : "password"}
                />
                <Button
                  style={{
                    borderRadius: 8,
                    marginLeft: 10,
                    padding: 5,
                  }}
                  icon={
                    !showConfirmPassword ? (
                      <AiOutlineEye />
                    ) : (
                      <AiOutlineEyeInvisible />
                    )
                  }
                  onClick={toggleShowConfirmPassword}
                ></Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  );
}

export default AgentProfile;
