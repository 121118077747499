import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import Editor from "../../../components/common/Editor";

const NewsModal = ({ open, onOk, onCancel, descriptionText }) => {
  const [content, setContent] = useState(descriptionText || "");
  console.log(descriptionText);
  useEffect(() => {
    // Update content when descriptionText changes
    setContent(descriptionText || "");
  }, [descriptionText]);

  return (
    <Modal
      key={descriptionText}
      visible={open}
      onCancel={onCancel}
      onOk={onOk}
      style={{
        top: 20,
      }}
    >
      <div>
        <Editor value={descriptionText?.description} showToolbar={false} />
      </div>
    </Modal>
  );
};

export default NewsModal;
