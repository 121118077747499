import {
  Avatar,
  Button,
  Dropdown,
  Form,
  Menu,
  Popover,
  Progress,
  Row,
  Table,
  Tabs,
  Tag,
  Tooltip,
  message,
} from "antd";
import { debounce } from "lodash/debounce";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { BiUserPlus } from "react-icons/bi";
import { BsFlag } from "react-icons/bs";
import { MdAddTask } from "react-icons/md";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import DebounceSelect from "../../../components/common/DebounceSelect";
import PageSearch from "../../../components/common/PageSearch";
import { multiTabContext } from "../../../components/reducer/context";
import { restriction } from "../../../constant/Restrictions";
import { api } from "../../../services/Api";
import { fetchUser } from "../../../services/User";
import { SendPushNotification } from "../../../utils/SendNotification";
import { stringToColour } from "../../../utils/getProfileColor";
import { limit } from "../../../utils/truncateString";
import TaskForm from "./TaskForm";
const { TabPane } = Tabs;

const colorByStatus = {
  Pending: "volcano",
  Ongoing: "yellow",
  Completed: "green",
};

const priorityFlag = {
  Low: "green",
  High: "red",
  Medium: "#e4d422",
};

function Task() {
  const { activeTab, setActiveTab } = useContext(multiTabContext);

  const navigate = useNavigate();
  const [createTaskForm] = Form.useForm();
  const { data: userData } = useQuery("user", fetchUser);
  const [params, setParams] = useState({
    sort: "-updatedAt",
    page: 1,
    limit: 14,
    skip: 0,
    status: "Pending",
  });
  const [image, setImage] = useState([]);

  const [regexQuery, setRegexQuery] = useState("");

  const priorityTask = (data) => {
    const payload = {
      priority: data.priority,
    };
    return api.patch("/tasks/" + data.taskId, payload);
  };

  const { mutate: priority_task_mutate } = useMutation(priorityTask, {
    onSuccess: () => {
      refetch();
      setPopoverVisible(false);
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });
  const assignTask = (data) => {
    const payload = {
      assignee: data.assignee,
    };
    setPopoverVisible(false);

    return api.patch("/tasks/" + data.taskId + "/assign-user", payload);
  };
  const { mutate: assign_task_mutate } = useMutation(assignTask, {
    onSuccess: (value) => {
      setPopoverVisible(false);
      refetch();
      message.success("task successfully assigned");

      SendPushNotification(
        "/topics/employee", //send fcm token
        `Your Task has been assigned to ${value?.data?.assignee[0]?.name}`,
        `${value?.data?.title} has been started. Thank you.`,
        "",
        value.data._id,
        value?.data?.featureImage?.url
      );
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });

  const [popoverVisible, setPopoverVisible] = useState(false);
  const getUsers = async (queryString, exclude) => {
    return api
      .get("/users", {
        params: {
          "userName!": exclude.join(","),
          "relation_role.slug": "employee",
          firstName: encodeURI(`/${queryString}/i`),
        },
      })
      .then((body) =>
        body.data.docs.map((user) => ({
          label: `${user.firstName} ${user.lastName}`,
          value: user._id,
        }))
      );
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, record) => (
        <p
          onClick={() => {
            navigate(`${record._id}`);
          }}
          style={{ color: "black" }}
        >
          {limit(text, 50)}
        </p>
      ),
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      render: (company, _record) => (
        <>
          <p>{limit(company?.name, 50)}</p>
        </>
      ),
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      sorter: (a, b) => moment(a.dueDate) - moment(b.dueDate),
      render: (text) => (
        <p
          style={{ color: moment(text).fromNow() === "in a day" ? "red" : "" }}
        >
          {moment(text).fromNow()}
        </p>
      ),
    },

    {
      title: "Assigned To",
      dataIndex: "assignee",
      key: "assignee",
      render: (assignee, record) => (
        <Avatar.Group
          maxCount={5}
          maxPopoverTrigger="hover"
          size="small"
          maxStyle={{
            color: "#f56a00",
            backgroundColor: "#fde3cf",
            cursor: "pointer",
          }}
        >
          {assignee.map((user, i) => {
            const color = stringToColour(user.userName);
            return (
              <>
                <div key={i}>
                  <Tooltip
                    title={<div>{`${user.firstName} ${user.lastName}`}</div>}
                    placement="top"
                  >
                    {user.avatar?.url ? (
                      <Avatar src={user.avatar.url} />
                    ) : (
                      <Avatar
                        className="capital"
                        style={{ backgroundColor: color }}
                      >
                        {user.firstName[0] ?? "U"}
                      </Avatar>
                    )}
                  </Tooltip>
                </div>
              </>
            );
          })}
          {!restriction[userData?.data.role.slug]?.includes("add_assignee") && (
            <Popover
              open={popoverVisible}
              onOpenChange={() => setPopoverVisible(true)}
              content={
                <div
                  style={{
                    width: "300px",
                  }}
                >
                  <DebounceSelect
                    fetchOptions={(query) =>
                      getUsers(query, assignee?.map((e) => e.userName) ?? [])
                    }
                    onChange={(e) => {
                      assign_task_mutate({ taskId: record._id, assignee: e });
                    }}
                    placeholder="Assignee"
                  />
                </div>
              }
              trigger="hover"
              placement="bottomLeft"
            >
              <Avatar
                className="capital"
                size="small"
                style={{
                  backgroundColor: "#87d068",
                  cursor: "pointer",
                  border: "1px solid black",
                }}
              >
                <BiUserPlus size={22} />
              </Avatar>
            </Popover>
          )}
        </Avatar.Group>
      ),
    },

    {
      title: "Status ",
      dataIndex: "status",
      key: "status",
      filters: [
        {
          text: "Pending",
          value: "Pending",
        },
        {
          text: "Ongoing",
          value: "Ongoing",
        },
        {
          text: "Completed",
          value: "Completed",
        },
      ],
      onFilter: (value, record) => record.status === value,
      render: (status, record) => {
        return (
          <Row>
            <Tag color={colorByStatus[status]}>{status}</Tag>
          </Row>
        );
      },
    },

    {
      title: "% Completed",
      dataIndex: "status",
      key: "status",
      filters: [
        {
          text: "Pending",
          value: "Pending",
        },
        {
          text: "Ongoing",
          value: "Ongoing",
        },
        {
          text: "Completed",
          value: "Completed",
        },
      ],
      onFilter: (value, record) => record.status === value,
      render: (status, record) => {
        const total = record?.modules?.length;
        const checked = record?.modules?.filter(
          (c, i) => c?.status === "Completed"
        )?.length;
        const percent = Math.round((checked / total) * 100);
        return (
          <Row>
            <div
              style={{
                width: 100,
              }}
            >
              {status === "Ongoing" && (
                <Progress percent={percent} size="small" />
              )}
            </div>
          </Row>
        );
      },
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      render: (priority, record) => (
        <>
          {!restriction[userData?.data.role.slug]?.includes("add_assignee") ? (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    onClick={() =>
                      priority_task_mutate({
                        taskId: record._id,
                        priority: "High",
                      })
                    }
                  >
                    High
                  </Menu.Item>
                  <Menu.Item
                    onClick={() =>
                      priority_task_mutate({
                        taskId: record._id,
                        priority: "Medium",
                      })
                    }
                  >
                    Medium
                  </Menu.Item>
                  <Menu.Item
                    onClick={() =>
                      priority_task_mutate({
                        taskId: record._id,
                        priority: "Low",
                      })
                    }
                  >
                    Low
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <BsFlag color={priorityFlag[priority ?? "Medium"]} />
            </Dropdown>
          ) : (
            <BsFlag color={priorityFlag[priority ?? "Medium"]} />
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    if (restriction[userData?.data.role.slug]?.includes("view_all_task")) {
      setParams({ ...params, assignee: userData.data.id });
    }
  }, [params, userData.data.id, userData.data.role.slug]);

  const employeeLogin = userData?.data.role.slug === "employee";

  const fetchTask = () => {
    return api.get("/tasks", {
      params: {
        ...params,
        title: `/${regexQuery.trim()}/i`,
        "relation_createdBy.role.slug!": "agent",
        ...(employeeLogin && { "relation_assignee._id": userData?.data?._id }),
        page: regexQuery ? 1 : params.page,
        skip: regexQuery ? 0 : params.skip,
      },
    });
  };

  const employeeFetchTask = () => {
    return api.get(`/tasks?assignee=${userData?.data?.id}`, {
      params: { ...params, title: encodeURI(`/${regexQuery}/i`) },
    });
  };

  ///add docs
  const updateAttachment = (data) => {
    const arr = Array.from(data.attachments.fileList);
    const formData = new FormData();

    arr.forEach((f) => {
      formData.append("attachments", f.originFileObj);
    });
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return api.patch(`/tasks/${data?.id}/add-attachments`, formData, config);
  };
  const { mutate: mutate4, isLoading: upload_add_loading } = useMutation(
    updateAttachment,
    {
      onSuccess: () => {
        // setFile(null);
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    }
  );
  // const props = {
  //   listType: "picture",
  //   beforeUpload: (file) => {
  //     uploadFile(file);
  //     return false;
  //   },
  //   showUploadList: false,
  // };
  const uploadFile = ({ image, id }) => {
    mutate4({ attachments: image, id });
  };

  const createTask = async (data) => {
    const res = await api.post("/tasks ", data);
    if (res) {
      await (image?.file && uploadFile({ image, id: res?.data?.id }));
      setImage([]);

      // await api.patch(`tasks/${res.data?._id}/mark-as-template`);
    }
    if (data.markAsTemplate) {
      await api.patch(`tasks/${res.data?._id}/mark-as-template`);
    }
  };

  const { mutate: add_task_mutation, isLoading: add_task_mutation_loading } =
    useMutation(createTask, {
      onSuccess: () => {
        refetch();
        handleCancel();
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });

  const addTask = () => {
    createTaskForm.validateFields().then((values) => {
      values.status = "Pending";
      add_task_mutation(values);
    });
  };

  const {
    isLoading: task_loading,
    data: task_data,
    refetch,
  } = useQuery(["task", regexQuery, params], fetchTask);

  const { isLoading: employee_task_loading, data: employee_task_data } =
    useQuery(["task", regexQuery, params], employeeFetchTask);
  const userId = userData.data._id;

  const assignedTask = task_data?.data?.docs?.filter((data) =>
    data.assignee.some((assignee) => assignee._id === userId)
  );

  const [visible, setVisible] = useState(false);
  const handleCancel = () => {
    createTaskForm.resetFields();
    setVisible(false);
  };

  // TODO:ongoing data nikalne

  const ongoing_data_array = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  assignedTask?.forEach((task) => {
    const monthNumber = moment(task.startDate).format("M");
    ongoing_data_array[monthNumber - 1]++;
  });

  return (
    <>
      <div className="main-container">
        <Row className="content-apart">
          <h1 className="main-title">Task</h1>
          <Row>
            <PageSearch
              onChange={(e) => {
                debounce(setRegexQuery(e.target.value), 800);
              }}
              placeholder="Search for Tasks"
            />
            {!restriction[userData?.data.role.slug]?.includes(
              "change_task"
            ) && (
              <Button
                style={{ borderRadius: "0.5em" }}
                type="primary"
                onClick={() => {
                  setVisible(true);
                }}
              >
                <MdAddTask size={20} />
              </Button>
            )}
          </Row>
        </Row>
        <Tabs
          defaultActiveKey={activeTab}
          onTabClick={(status) => {
            if (status === "All") {
              setParams({ ...params, status: undefined, page: 1, skip: 0 });
            } else {
              setParams({ ...params, status, page: 1, skip: 0 });
            }
            refetch();
          }}
          // defaultActiveKey="All"
        >
          <TabPane tab="All" key="All">
            <Table
              loading={task_loading}
              size="small"
              pagination={{
                current: params.page,
                total: task_data?.data.totalDocs,
                pageSize: 14,
                showSizeChanger: false,
                onChange: (page) => {
                  setParams({
                    ...params,

                    page,
                    skip: (page - 1) * params.limit,
                  });
                },
              }}
              className="fiscal-table"
              columns={columns}
              dataSource={
                // task_data?.data?.docs
                (employeeLogin && assignedTask) ||
                task_data?.data?.docs.filter((f) => f.status !== "All")
              }
            />
          </TabPane>
          <TabPane tab="Pending" key="Pending">
            <Table
              loading={task_loading}
              pagination={{
                current: params.page,
                total: task_data?.data.totalDocs,
                pageSize: params.limit,
                onChange: (page) => {
                  setParams({
                    ...params,
                    status: "Pending",
                    page,
                    skip: (page - 1) * params.limit,
                  });
                },
              }}
              size="small"
              // onRow={(record, _) => {
              //   return {
              //     onClick: (_) => {
              //       navigate(`${record._id}`);
              //     },
              //   };
              // }}
              className="fiscal-table"
              columns={columns}
              dataSource={
                employeeLogin
                  ? // console.log(assignedTask?.filter((e) => e.status == "Pending"))
                    assignedTask?.filter((e) => e.status === "Pending")
                  : task_data?.data.docs
                // task_data?.data.docs
              }
            />
          </TabPane>
          <TabPane tab="Ongoing" key="Ongoing">
            <Table
              pagination={{
                current: params.page,
                total: task_data?.data.totalDocs,
                pageSize: 14,
                onChange: (page) => {
                  setParams({
                    ...params,
                    page,
                    status: "Ongoing",

                    skip: (page - 1) * params.limit,
                  });
                },
              }}
              loading={task_loading}
              size="small"
              // onRow={(record, _) => {
              //   return {
              //     onClick: (_) => {
              //       navigate(`${record._id}`);
              //     },
              //   };
              // }}
              className="fiscal-table"
              columns={columns}
              dataSource={
                employeeLogin
                  ? assignedTask?.filter((e) => e.status === "Ongoing")
                  : task_data?.data.docs
              }
            />
          </TabPane>
          <TabPane tab="Completed" key="Completed">
            <Table
              pagination={{
                current: params.page,
                total: task_data?.data.totalDocs,
                showSizeChanger: false,

                pageSize: 14,
                onChange: (page) => {
                  setParams({
                    ...params,
                    page,
                    skip: (page - 1) * params.limit,
                  });
                },
              }}
              loading={task_loading}
              size="small"
              // onRow={(record, _) => {
              //   return {
              //     onClick: (_) => {
              //       navigate(`${record._id}`);
              //     },
              //   };
              // }}
              className="fiscal-table"
              columns={columns}
              dataSource={
                employeeLogin
                  ? assignedTask?.filter((e) => e.status === "Completed")
                  : task_data?.data.docs
              }
            />
          </TabPane>
        </Tabs>
      </div>

      {visible && (
        <TaskForm
          okText="Create"
          isModalVisible={visible}
          form={createTaskForm}
          handleCancel={handleCancel}
          handleOk={addTask}
          loading={add_task_mutation_loading}
          headingText=""
          setImage={setImage}
          image={image}
        />
      )}
    </>
  );
}

export default Task;
