import { Col, Row, Typography } from "antd";
import React from "react";
import logInImg from "../assets/images/loginimg.webp";
import logo from "../assets/images/logo2.png";
import LoginForm from "../components/login/LoginForm";
import SocialLogin from "../components/login/SocialLogin";

function Login() {
  return (
    <div id="login">
      <Row>
        <Col
          lg={16}
          sm={24}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={logInImg} alt="loginImg" style={{ borderRadius: "4em" }} />
        </Col>
        <Col
          lg={8}
          sm={24}
          className="login-content"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {/* prabeg@fintax.com.np */}
            <img
              src={logo}
              alt="logo"
              style={{ height: "20rem", width: "100%", objectFit: "contain" }}
            />
            {/* <div className="login-heading">Login With</div>
            <SocialLogin />
            <div className="or">-OR-</div> */}
            <LoginForm />
            <Typography.Text>
              Note: Login details are provided by Fintax Analytics Nepal [Admin]
            </Typography.Text>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Login;
