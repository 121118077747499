import { createContext, useState } from "react";

export const multiTabContext = createContext();

const TabContext = ({ children }) => {
  const [activeTab, setActiveTab] = useState("Pending");

  return (
    <multiTabContext.Provider value={{ activeTab, setActiveTab }}>
      {children}
    </multiTabContext.Provider>
  );
};

export default TabContext;
