import { Col, Image, Row, Steps } from "antd";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { api } from "../../../services/Api";
import Step1Form from "./Step1Form";
import Step2Form from "./Step2Form";
const { Step } = Steps;

const Detail = () => {
  const { billId } = useParams();
  const [current, setCurrent] = useState(0);
  const getBill = () => {
    return api.get("/bills/" + billId);
  };
  const {
    data: bill_data,
    isLoading: bill_loading,
    refetch: bill_refetch,
  } = useQuery("singleBill", getBill);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const steps = [
    {
      title: "",
      content: <Step1Form billId={billId} next={next} />,
    },

    {
      title: "",
      content: <Step2Form billId={billId} next={next} />,
    },
  ];

  return (
    <>
      <div className="main-container">
        <Row>
          <Col span={10}>
            <Image
              src={bill_data?.data.attachment.url}
              alt="bill"
              className="bill-img"
            />
          </Col>
          <Col span={14}>
            <Steps style={{ float: "right" }} current={current}>
              {steps.map((item, index) => (
                <Step
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => setCurrent(index)}
                  key={index}
                  title={item.title}
                />
              ))}
            </Steps>
            <div className="steps-content">{steps[current].content}</div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Detail;
