import { Avatar, Form, Row, Table, Tooltip, message } from "antd";
import debounce from "lodash/debounce";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import PageSearch from "../../../components/common/PageSearch";
import { restriction } from "../../../constant/Restrictions";
import { api } from "../../../services/Api";
import { fetchUser } from "../../../services/User";
import { stringToColour } from "../../../utils/getProfileColor";
import { limit } from "../../../utils/truncateString";
import EmployeeForm from "../employee/EmployeeForm";

function AdminList() {
  const [count, setCount] = useState({});

  const { isUserLoading, data: userData } = useQuery("user", fetchUser);
  const [regexQuery, setRegexQuery] = useState("");
  const [addEmployeeVisible, setAddEmployeeVisible] = useState(false);
  const [employeeForm] = Form.useForm();
  const [params, setParams] = useState({
    sort: "-createdAt",
    page: 1,
    limit: 13,
    skip: 0,
  });
  const navigate = useNavigate();

  const columns = [
    {
      title: "Full Name",
      dataIndex: "firstName",
      key: "firstName",
      width: "30%",
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      render: (_, record) => {
        return (
          <span>
            <Tooltip
              title={<div>{`${record.firstName} ${record.lastName}`}</div>}
              placement="top"
            >
              {record.avatar?.url ? (
                <Avatar size="small" src={record.avatar.url} />
              ) : (
                <Avatar
                  className="capital"
                  size="small"
                  style={{ backgroundColor: stringToColour(record.userName) }}
                >
                  {record?.firstName?.[0] ?? "U"}
                </Avatar>
              )}
            </Tooltip>
            &nbsp; &nbsp;
            {limit(`${record.firstName} ${record.lastName}`, 20)}
          </span>
        );
      },
    },
    {
      title: "Contact",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: "25%",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "30%",
      render: (text) => (
        <a
          target="_blank"
          rel="noreferrer"
          href={`mailto:${text}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {limit(text, 27)}
        </a>
      ),
    },

    {
      title: "Role",
      key: "task",
      width: "15%",
      render: (_, record) => {
        return <>{record?.role?.name}</>;
      },
    },
  ];

  const getEmployee = () => {
    return api.get("/users", {
      params: {
        "relation_role.slug":
          "superadmin,admin,moderator,applicationadministrator",
        userName: `/${regexQuery.trim()}/i`,
        ...params,
        page: regexQuery ? 1 : params.page,
        skip: regexQuery ? 0 : params.skip,
      },
    });
  };

  const postEmployee = (data) => {
    return api.post("/users", { ...data, email: data.email.toLowerCase() });
  };
  const { mutate: employee_add_mutate, isLoading: employee_add_loading } =
    useMutation(postEmployee, {
      onSuccess: () => {
        employee_refetch();
        employeeForm.resetFields();
        setAddEmployeeVisible(false);
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });
  const handleEmployeeAddCancel = () => {
    employeeForm.resetFields();
    setAddEmployeeVisible(false);
  };
  const handleEmployeeAddOk = () => {
    employeeForm
      .validateFields()
      .then((values) => {
        values.enabled = true;
        employee_add_mutate(values);
      })
      .catch((error) => {
        // Display the error message to the user
        const errorMessage = error.errorFields[0].errors[0];
        message.error(errorMessage);
      });
  };
  const {
    isLoading: employee_loading,
    data,
    refetch: employee_refetch,
  } = useQuery(["adminlist", regexQuery, params], getEmployee);

  const updateProfilePic = (data) => {
    const payload = {
      avatar: data.avatar,
    };
    const formData = new FormData();
    formData.append("avatar", data.avatar);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return api.patch(
      `/users/${data._id}/update-profile-pic`,
      formData,
      config,
      payload
    );
  };

  const { mutate: update_profile_mutate, isLoading: update_profile_loading } =
    useMutation(updateProfilePic, {
      onSuccess: () => {
        employee_refetch();
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });

  const uploadImage = (avatar) => {
    update_profile_mutate({ avatar: avatar });
  };
  return (
    <>
      <div className="main-container">
        <Row className="content-apart mb-10">
          <h1 className="main-title">Admin List</h1>
          <Row>
            <PageSearch
              onChange={(e) => {
                debounce(setRegexQuery(e.target.value), 800);
              }}
              placeholder="Search for administrators"
            />
            {!restriction[userData?.data.role.slug].includes(
              "change_employee"
            ) && <></>}
          </Row>
        </Row>
        <Table
          pagination={{
            current: params.page,
            total: data?.data.totalDocs,
            pageSize: 15,
            onChange: (page) => {
              setParams({
                ...params,
                page,
                skip: (page - 1) * params.limit,
              });
            },
          }}
          loading={employee_loading}
          size="small"
          onRow={(record, _) => {
            return {
              onClick: (_) => {
                navigate(`${record._id}`);
              },
            };
          }}
          className="fiscal-table"
          columns={columns}
          dataSource={data?.data?.docs}
        />
      </div>
      {addEmployeeVisible && (
        <EmployeeForm
          isModalVisible={addEmployeeVisible}
          form={employeeForm}
          okText="Create"
          handleCancel={handleEmployeeAddCancel}
          handleOk={handleEmployeeAddOk}
          loading={employee_add_loading}
          headingText=""
        />
      )}
    </>
  );
}

export default AdminList;
