import { Button, Form, Input, Modal, Select, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import DebounceSelect from "../../../components/common/DebounceSelect";
import { Districts } from "../../../constant/Districts";
import { Municipality } from "../../../constant/Municipalities";
import { api } from "../../../services/Api";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 20,
    },
    sm: {
      span: 14,
    },
  },
};
const { Option } = Select;

function ClientForm({
  isModalVisible,
  handleCancel,
  handleOk,
  form,
  loading,
  initialValues,
  okText,
  headingText,
}) {
  const getRoles = async (queryString) => {
    return api
      .get("/roles", {
        params: {
          name: encodeURI(`/${queryString}/i`),
        },
      })
      .then((body) =>
        body.data.docs.map((role) => ({
          label: `${role.name}`,
          value: role._id,
        }))
      );
  };

  console.log(initialValues, "ths is now");

  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const [districtId, setId] = useState(1);
  const [districtname, setDistrictName] = useState();
  const [test, setTest] = useState("");
  const distrcitFunc = (e) => {
    setId(e);
  };

  useEffect(() => {
    if (initialValues?.address?.district?.label) {
      const districtId = parseInt(initialValues?.address?.district?.label);
      const district = Districts.find((d) => d.id === districtId);
      setTest(district?.label);
      form.resetFields();
    } else {
      const district = Districts.filter(
        (d) => d.id === initialValues?.address?.district
      );
      setDistrictName(district?.label);
      form.resetFields();
    }
  }, [initialValues, Districts, districtname, test]);
  return (
    <Modal
      maskClosable={false}
      // title={headingText}
      visible={isModalVisible}
      onCancel={handleCancel}
      okText="Create"
      cancelText="Cancel"
      width={"30vw"}
      style={{ background: "white", top: "0px" }}
      className="global-modal"
      footer={[
        <Button
          loading={loading}
          key="submit"
          type="primary"
          onClick={handleOk}
        >
          {okText}
        </Button>,
      ]}
    >
      <div className="form-wrapper">
        <Form
          // key={districtname}
          initialValues={{
            ...initialValues,
            role: initialValues?.role?._id,
            address: {
              country: "Nepal",
              // district: initialValues?.address?.district
              //   ? {
              //       ...initialValues?.address?.district,
              //       label: districtname,
              //       value: `${initialValues?.address?.district}`,
              //     }
              //   : undefined,
              district: {
                label: test || districtname,
                value:
                  initialValues?.address?.district?.label ||
                  initialValues?.address?.district,
              },
              municipality: initialValues?.address?.municipality
                ? {
                    // ...initialValues?.address?.municipality,
                    label:
                      initialValues?.address?.municipality?.label ||
                      initialValues?.address?.municipality,
                    value: `${initialValues?.address?.municipality?.district_Id}`,
                  }
                : undefined,
            },
          }}
          form={form}
          layout="vertical"
        >
          <Form.Item
            label="Client First Name"
            name="firstName"
            required
            rules={[
              {
                required: true,
                message: "Please Enter First Name!",
              },
            ]}
          >
            <Input placeholder="First Name" />
          </Form.Item>
          <Form.Item
            label="Client Last Name"
            name="lastName"
            required
            rules={[
              {
                required: true,
                message: "Please Last First Name!",
              },
            ]}
          >
            <Input placeholder="Last Name" />
          </Form.Item>
          <Form.Item
            label="Client User Name"
            name="userName"
            required
            rules={[
              {
                required: true,
                message: "Please Enter User Name!",
              },
            ]}
          >
            <Input placeholder="User Name" />
          </Form.Item>
          <Form.Item
            label="Client Email"
            name="email"
            required
            rules={[
              {
                required: true,
                message: "Please enter client's email!",
              },
              {
                type: "email",
                message: "Please enter a valid email address !",
              },
            ]}
          >
            <Input type="email" placeholder="Client's Email" />
          </Form.Item>
          {!initialValues && (
            <Form.Item
              label="Client Password"
              name="password"
              required
              rules={[
                {
                  required: true,
                  pattern: new RegExp(
                    // eslint-disable-next-line no-useless-escape
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/
                  ),
                  message:
                    "Password length must be 6 to 10 characters with at least one uppercase, one lowercase, one number and one special character!",
                },
              ]}
            >
              <div style={{ display: "flex" }}>
                <Input
                  placeholder="Client's Password"
                  type={showPassword ? "text" : "password"}
                />
                <Button
                  style={{
                    borderRadius: 8,
                    marginLeft: 10,
                    padding: 5,
                  }}
                  icon={
                    !showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />
                  }
                  onClick={toggleShowPassword}
                ></Button>
              </div>
            </Form.Item>
          )}

          <Form.Item
            label="Client Phone Number"
            name="phoneNumber"
            required
            rules={[
              {
                required: true,
                message: "Please enter client's phone!",
              },
            ]}
          >
            <Input type="tel" placeholder="Client's Phone Number" />
          </Form.Item>
          <Form.Item
            label="Client Role"
            name="role"
            required
            rules={[
              {
                required: true,
                message: "Please role of this client !",
              },
            ]}
          >
            <DebounceSelect placeholder="Select Role" fetchOptions={getRoles} />
          </Form.Item>
          <Form.Item
            label="Client Country"
            name={["address", "country"]}
            required
            rules={[
              {
                required: true,
                message: "Please enter client's country",
              },
            ]}
          >
            <Input placeholder="Client's Country" />
          </Form.Item>
          <Form.Item label="Client District" name={["address", "district"]}>
            <Select
              showSearch
              value={districtId}
              onChange={(e) => setId(e)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {Districts?.map((d, i) => {
                return (
                  <Option key={d.id} value={d.id}>
                    {d.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Client Municipality"
            name={["address", "municipality"]}
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {Municipality?.filter((el) => el.district_id === districtId)?.map(
                (m, i) => {
                  return (
                    <Option key={i} value={m.value}>
                      {m.label}
                    </Option>
                  );
                }
              )}
            </Select>
          </Form.Item>
          <Form.Item
            label="Invitation mail"
            name="sendInvitationByEmail"
            valuePropName="checked"
          >
            <Switch defaultChecked checked />
          </Form.Item>
          <Form.Item
            label="Welcome mail"
            name="sendWelcomeEmail"
            valuePropName="checked"
          >
            <Switch defaultChecked checked />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}

export default ClientForm;
