import React, { useState, useEffect } from "react";
import moment from "moment";
import { useMutation, useQuery } from "react-query";
import { api } from "../../../services/Api";
import { useForm } from "react-hook-form";
import Dialog from "../../../components/common/Dialog";
import EditDialog from "../../../components/common/EditModal";
import {
  Table,
  Row,
  Menu,
  Dropdown,
  Space,
  Modal,
  Form,
  Input,
  Select,
  Button,
  message,
  Collapse,
} from "antd";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { IoEllipsisVertical } from "react-icons/io5";
import debounce from "lodash/debounce";
import { useNavigate } from "react-router-dom";
import { restriction } from "../../../constant/Restrictions";
import { fetchUser } from "../../../services/User";
import Swal from "sweetalert2";
import PageSearch from "../../../components/common/PageSearch";

const { Panel } = Collapse;

function Products() {
  const { isUserLoading, data: userData } = useQuery("user", fetchUser);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    initialValues,
    handleOk,
    okText,
    formState: { errors },
  } = useForm();
  const [regexQuery, setRegexQuery] = useState("");

  const [visible, setVisible] = useState(false);
  const [product, setProduct] = useState({
    id: "",
    name: "",
    description: "",
    coe: "",
    category: "",
  });
  const [evisible, seteVisible] = useState(false);
  const [productForm] = Form.useForm();
  const nameValue = Form.useWatch("name", productForm);
  const categoryValue = Form.useWatch("category", productForm);
  const brandValue = Form.useWatch("brand", productForm);
  const [productCode, setProductCode] = useState("");

  useEffect(() => {
    let brandName = brand_data?.data.docs.filter(
      (b, i) => b._id === brandValue
    )[0]?.name;
    let categoryName = category_data?.data.docs.filter(
      (c, i) => c._id === categoryValue
    )[0]?.name;
    let concatedString =
      (categoryName?.substring(0, 3) ?? "") +
      "-" +
      (brandName?.substring(0, 3) ?? "") +
      "-" +
      (nameValue ?? "");
    concatedString = concatedString?.replace(/\s/g, "-").toUpperCase();
    productForm.setFieldsValue({ code: concatedString });
    setProductCode(concatedString);
  }, [nameValue, categoryValue, brandValue]);

  const showModal = (data) => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };
  const showEditModal = (data) => {
    setProduct(data);
    seteVisible(true);
  };

  const handleEditCancel = () => {
    seteVisible(false);
  };

  const getCategory = () => {
    return api.get("/categories");
  };
  const getBrand = () => {
    return api.get("/brands");
  };
  const getUoM = () => {
    return api.get("/unit-of-measurements");
  };
  const getProduct = () => {
    return api.get("/products", {
      params: {
        name: encodeURI(`/${regexQuery}/i`),
      },
    });
  };
  const createProduct = (data) => {
    return api.post("/products", data);
  };
  const removeProduct = (id) => {
    return api.delete("/products/" + id);
  };
  const updateProduct = (data) => {
    return api.patch("/products/" + data.id, data);
  };

  const { mutate: mutate1 } = useMutation(createProduct, {
    onSuccess: () => {
      refetch();
      productForm.resetFields();
      setVisible(false);
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });
  const { mutate: mutate2 } = useMutation(removeProduct, {
    onSuccess: () => {
      refetch();
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });
  const { mutate: mutate3 } = useMutation(updateProduct, {
    onSuccess: () => {
      refetch();
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });
  const [params, setParams] = useState({
    sort: "-createdAt",
    page: 1,
    limit: 10,
    skip: 0,
  });
  const { isloading: category_loading, data: category_data } = useQuery(
    "pcategory",
    getCategory
  );
  const { isloading: brand_loading, data: brand_data } = useQuery(
    "product_brand",
    getBrand
  );
  const { isloading: uom_loading, data: uom_data } = useQuery(
    "product_uom",
    getUoM
  );
  const {
    isloading: product_loading,
    data: data2,
    refetch,
  } = useQuery(["product", regexQuery], getProduct);

  const addProduct = (data) => {
    mutate1(data);
    reset();
    handleCancel();
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure ?",
      text: "You want to delete this product",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Ok`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        mutate2(id);
      }
    });
  };

  const editProduct = () => {
    mutate3(product);
    handleEditCancel();
  };
  const handleEditChange = (event) => {
    const { name, value } = event.target;
    setProduct((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  // const onChange = (key) => {
  //   console.log(key);
  // };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <>
          <p style={{ color: "black" }}>{text}</p>
        </>
      ),
    },
    {
      title: "Last updated",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: (a, b) => moment(a.updatedAt) - moment(b.updatedAt),
      render: (text) => <p>{moment(text).fromNow()}</p>,
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      filters: category_data?.data?.docs?.map((e) => {
        return {
          text: e.name,
          value: e.name,
        };
      }),
      onFilter: (value, record) => record.category?.name === value,
      render: (category) => <p>{category?.name}</p>,
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      filters: brand_data?.data?.docs?.map((e) => {
        return {
          text: e.name,
          value: e.name,
        };
      }),
      onFilter: (value, record) => record.category?.name === value,
      render: (brand) => <p>{brand?.name}</p>,
    },
    {
      title: "UoM",
      dataIndex: "uom",
      key: "uom",
      filters: uom_data?.data?.docs?.map((e) => {
        return {
          text: e.name,
          value: e.name,
        };
      }),
      onFilter: (value, record) => record.category?.name === value,
      render: (uom) => <p>{uom?.name}</p>,
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <div>
          {!restriction[userData?.data.role.slug].includes(
            "change_inventory"
          ) && (
            <Dropdown
              overlay={
                <Menu mode="horizontal">
                  <Menu.Item
                    key="one"
                    className="edit-btn"
                    onClick={() => showEditModal(record)}
                    icon={
                      <MdModeEdit style={{ color: "blue", fontSize: "20px" }} />
                    }
                  >
                    Edit
                  </Menu.Item>

                  <Menu.Item
                    key="two"
                    className="delete-btn"
                    onClick={() => handleDelete(text)}
                    icon={
                      <MdDelete style={{ color: "red", fontSize: "20px" }} />
                    }
                  >
                    Delete
                  </Menu.Item>
                </Menu>
              }
            >
              <Space>
                <IoEllipsisVertical
                  style={{
                    cursor: "pointer",
                    color: "#00ABC5",
                  }}
                  size={20}
                />
              </Space>
            </Dropdown>
          )}
        </div>
      ),
    },
  ];

  const { TextArea } = Input;
  const { Option } = Select;

  return (
    <>
      <div className="main-container">
        <Row className="content-apart mb-10">
          <h1
            style={{
              cursor: "pointer",
            }}
            className="main-title"
          >
            Products
          </h1>
          <Row>
            <PageSearch
              onChange={(e) => {
                debounce(setRegexQuery(e.target.value), 800);
              }}
              placeholder="Search for products"
            />
            {!restriction[userData?.data.role.slug].includes(
              "change_inventory"
            ) && (
              <Button type="primary" onClick={showModal}>
                Add Product
              </Button>
            )}

            <Modal
              maskClosable={false}
              visible={visible}
              onCancel={handleCancel}
              okText="Create"
              cancelText="Cancel"
              width={"30vw"}
              style={{ background: "white", top: "0px" }}
              className="global-modal"
              footer={[
                <Button
                  htmlType="submit"
                  type="primary"
                  onClick={() => {
                    productForm
                      .validateFields()
                      .then((values) => {
                        mutate1(values);
                        message.success(
                          <div>
                            <p style={{ color: "black" }}>Product created!</p>
                          </div>
                        );
                      })
                      .catch((info) => {
                        console.log("Validate Failed:", info);
                      });
                  }}
                >
                  Create
                </Button>,
              ]}
            >
              <div className="form-wrapper">
                <Form
                  initialValues={initialValues}
                  form={productForm}
                  layout="vertical"
                  onSubmit={handleSubmit(addProduct)}
                >
                  <Form.Item
                    label="Product Name"
                    name="name"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Product Name!",
                      },
                    ]}
                  >
                    <Input placeholder="Product Name" />
                  </Form.Item>

                  <Form.Item label="Product Description" name="description">
                    <TextArea placeholder="Product Description" rows={3} />
                  </Form.Item>

                  <Form.Item
                    label="Product Category"
                    name="category"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Please Select Product Category!",
                      },
                    ]}
                    onChange={(e) => setValue("category", e.target.value)}
                  >
                    <Select>
                      <Option value="none">Please select the category</Option>
                      {category_data?.data.docs.map((data) => {
                        return <Option value={data._id}>{data.name}</Option>;
                      })}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Product Brand"
                    name="brand"
                    required
                    onChange={(e) => setValue("brand", e.target.value)}
                    rules={[
                      {
                        required: true,
                        message: "Please Select Product Brand!",
                      },
                    ]}
                  >
                    <Select>
                      <Option value="none">Please select the brand</Option>
                      {brand_data?.data?.docs?.map((data) => {
                        return <Option value={data._id}>{data.name}</Option>;
                      })}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Product Uom"
                    name="uom"
                    required
                    onChange={(e) => setValue("brand", e.target.value)}
                    rules={[
                      {
                        required: true,
                        message: "Please Select Product uom!",
                      },
                    ]}
                  >
                    <Select>
                      <Option value="none">
                        Please select the product unit of measurement
                      </Option>
                      {uom_data?.data.docs.map((data) => {
                        return (
                          <Option
                            value={data._id}
                            style={{ background: "white", color: "black" }}
                          >
                            {data.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Product Code"
                    name="code"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Product Code!",
                      },
                    ]}
                  >
                    <Input
                      style={{
                        cursor: "pointer",
                      }}
                      readOnly
                      // value={productCode}
                    />
                  </Form.Item>
                </Form>
              </div>
            </Modal>
          </Row>
        </Row>

        <Table
          size="small"
          loading={product_loading}
          className="fiscal-table"
          columns={columns}
          dataSource={data2?.data.docs}
        />

        <EditDialog
          evisible={evisible}
          handleEditCancel={handleEditCancel}
          showModal={showEditModal}
          Title={"Edit Product"}
        >
          <form onSubmit={(e) => e.preventDefault(editProduct())}>
            <input
              className="st-input"
              name="name"
              value={product?.name}
              placeholder="Enter Product Name"
              onChange={handleEditChange}
            />

            <textarea
              className="st-input"
              name="description"
              placeholder="Enter Product Description"
              value={product.description}
              onChange={handleEditChange}
              style={{ height: "150px" }}
            />
            <input
              className="st-input"
              name="code"
              placeholder="Enter Product Code"
              value={product.code}
              onChange={handleEditChange}
            />

            <select
              className="st-input"
              name="category"
              style={{
                width: "100%",
                background: "white",
                marginTop: "1rem",
                color: "black",
              }}
              onChange={handleEditChange}
            >
              <option selected> {product.category?.name}</option>
              {category_data?.data.docs.map((data) => {
                return (
                  <option
                    value={data._id}
                    style={{ background: "white", color: "black" }}
                  >
                    {data.name}
                  </option>
                );
              })}
            </select>
            <select
              className="st-input"
              name="brand"
              style={{
                width: "100%",
                background: "white",
                marginTop: "1rem",
                color: "black",
              }}
              onChange={handleEditChange}
            >
              <option selected> {product.category?.name}</option>
              {brand_data?.data.docs.map((data) => {
                return (
                  <option
                    value={data._id}
                    style={{ background: "white", color: "black" }}
                  >
                    {data.name}
                  </option>
                );
              })}
            </select>
            <select
              className="st-input"
              name="uom"
              style={{
                width: "100%",
                background: "white",
                marginTop: "1rem",
                color: "black",
              }}
              onChange={handleEditChange}
            >
              <option selected> {product.category?.brand}</option>
              {uom_data?.data.docs.map((data) => {
                return (
                  <option
                    value={data._id}
                    style={{ background: "white", color: "black" }}
                  >
                    {data.name}
                  </option>
                );
              })}
            </select>

            <div>
              <button key="submit" className="btn-custom">
                Update Product
              </button>
            </div>
          </form>
        </EditDialog>
      </div>
    </>
  );
}

export default Products;
