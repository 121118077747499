import {
  Avatar,
  Button,
  Dropdown,
  Form,
  Menu,
  Progress,
  Row,
  Table,
  Tabs,
  Tag,
  Tooltip,
} from "antd";
import CryptoJS from "crypto-js";
import { collection, doc, getFirestore, setDoc } from "firebase/firestore";
import { debounce } from "lodash/debounce";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { BsFlag } from "react-icons/bs";
import { MdAddTask } from "react-icons/md";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { app } from "../../../App";
import PageSearch from "../../../components/common/PageSearch";
import { multiTabContext } from "../../../components/reducer/context";
import { restriction } from "../../../constant/Restrictions";
import { api } from "../../../services/Api";
import { fetchUser } from "../../../services/User";
import { stringToColour } from "../../../utils/getProfileColor";
import { limit } from "../../../utils/truncateString";
import AgentTaskForm from "./AgentTaskForm";
const { TabPane } = Tabs;

const colorByStatus = {
  Pending: "volcano",
  Ongoing: "yellow",
  Completed: "green",
};

const priorityFlag = {
  Low: "green",
  High: "red",
  Medium: "#e4d422",
};

function SingleAgentTask() {
  const db = getFirestore(app);

  const { activeTab, setActiveTab } = useContext(multiTabContext);
  const [image, setImage] = useState([]);

  const navigate = useNavigate();
  const [createTaskForm] = Form.useForm();
  const { data: userData } = useQuery("user", fetchUser);
  const [params, setParams] = useState({
    sort: "-updatedAt",
    page: 1,
    limit: 10,
    skip: 0,
    status: undefined,
  });

  const [regexQuery, setRegexQuery] = useState("");
  const priorityTask = (data) => {
    const payload = {
      priority: data.priority,
    };
    return api.patch("/tasks/" + data.taskId, payload);
  };

  const { mutate: priority_task_mutate } = useMutation(priorityTask, {
    onSuccess: () => {
      refetch();
      setPopoverVisible(false);
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });
  const assignTask = (data) => {
    const payload = {
      assignee: data.assignee,
    };
    return api.patch("/tasks/" + data.taskId + "/assign-user", payload);
  };
  const { mutate: assign_task_mutate } = useMutation(assignTask, {
    onSuccess: () => {
      refetch();
      setPopoverVisible(false);
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });

  const [popoverVisible, setPopoverVisible] = useState(false);
  const getUsers = async (queryString, exclude) => {
    return api
      .get("/users", {
        params: {
          "userName!": exclude.join(","),
          "relation_role.slug!": "user",
          firstName: encodeURI(`/${queryString}/i`),
        },
      })
      .then((body) =>
        body.data.docs.map((user) => ({
          label: `${user.firstName} ${user.lastName}`,
          value: user._id,
        }))
      );
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, record) => (
        <p
          onClick={() => {
            navigate(`${record._id}`);
          }}
          style={{ color: "black" }}
        >
          {limit(text, 50)}
        </p>
      ),
    },
    // {
    //   title: "Agent's userName",
    //   dataIndex: "createdBy",
    //   key: "createdBy",
    //   render: (createdBy, _record) => (
    //     <>
    //       <p>{limit(createdBy?.userName, 50)}</p>
    //     </>
    //   ),
    // },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      sorter: (a, b) => moment(a.dueDate) - moment(b.dueDate),
      render: (text) => (
        <p
          style={{ color: moment(text).fromNow() === "in a day" ? "red" : "" }}
        >
          {moment(text).fromNow()}
        </p>
      ),
    },

    {
      title: "Assigned To",
      dataIndex: "assignee",
      key: "assignee",
      render: (assignee, record) => (
        <Avatar.Group
          maxCount={5}
          maxPopoverTrigger="hover"
          size="small"
          maxStyle={{
            color: "#f56a00",
            backgroundColor: "#fde3cf",
            cursor: "pointer",
          }}
        >
          {assignee.map((user, i) => {
            const color = stringToColour(user.userName);
            return (
              <>
                <div key={i}>
                  <Tooltip
                    title={<div>{`${user.firstName} ${user.lastName}`}</div>}
                    placement="top"
                  >
                    {user.avatar?.url ? (
                      <Avatar src={user.avatar.url} />
                    ) : (
                      <Avatar
                        className="capital"
                        style={{ backgroundColor: color }}
                      >
                        {user.firstName[0] ?? "U"}
                      </Avatar>
                    )}
                  </Tooltip>
                </div>
              </>
            );
          })}
          {/* {!restriction[userData?.data.role.slug]?.includes("add_assignee") && (
            <Popover
              open={popoverVisible}
              onOpenChange={() => setPopoverVisible(true)}
              content={
                <div
                  style={{
                    width: "300px",
                  }}
                >
                  <DebounceSelect
                    fetchOptions={(query) =>
                      getUsers(query, assignee?.map((e) => e.userName) ?? [])
                    }
                    onChange={(e) => {
                      assign_task_mutate({ taskId: record._id, assignee: e });
                    }}
                    placeholder="Assignee"
                  />
                </div>
              }
              trigger="click"
              placement="bottomLeft"
            >
              <Avatar
                className="capital"
                size="small"
                style={{
                  backgroundColor: "#87d068",
                  cursor: "pointer",
                }}
              >
                <BiUserPlus size={12} />
              </Avatar>
            </Popover>
          )} */}
        </Avatar.Group>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: [
        {
          text: "Pending",
          value: "Pending",
        },
        {
          text: "Ongoing",
          value: "Ongoing",
        },
        {
          text: "Completed",
          value: "Completed",
        },
      ],
      onFilter: (value, record) => record.status === value,
      render: (status, record) => {
        return (
          <Row>
            <Tag color={colorByStatus[status]}>{status}</Tag>
          </Row>
        );
      },
    },

    {
      title: "% Completed",
      dataIndex: "status",
      key: "status",
      filters: [
        {
          text: "Pending",
          value: "Pending",
        },
        {
          text: "Ongoing",
          value: "Ongoing",
        },
        {
          text: "Completed",
          value: "Completed",
        },
      ],
      onFilter: (value, record) => record.status === value,
      render: (status, record) => {
        const total = record?.modules?.length;
        const checked = record?.modules?.filter(
          (c, i) => c?.status === "Completed"
        )?.length;
        const percent = Math.round((checked / total) * 100);
        return (
          <Row>
            <div
              style={{
                width: 100,
              }}
            >
              {status === "Ongoing" && (
                <Progress percent={percent} size="small" />
              )}
            </div>
          </Row>
        );
      },
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      render: (priority, record) => (
        <>
          {!restriction[userData?.data.role.slug]?.includes("add_assignee") ? (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    onClick={() =>
                      priority_task_mutate({
                        taskId: record._id,
                        priority: "High",
                      })
                    }
                  >
                    High
                  </Menu.Item>
                  <Menu.Item
                    onClick={() =>
                      priority_task_mutate({
                        taskId: record._id,
                        priority: "Medium",
                      })
                    }
                  >
                    Medium
                  </Menu.Item>
                  <Menu.Item
                    onClick={() =>
                      priority_task_mutate({
                        taskId: record._id,
                        priority: "Low",
                      })
                    }
                  >
                    Low
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <BsFlag color={priorityFlag[priority ?? "Medium"]} />
            </Dropdown>
          ) : (
            <BsFlag color={priorityFlag[priority ?? "Medium"]} />
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    if (restriction[userData?.data.role.slug]?.includes("view_all_task")) {
      setParams({ ...params, assignee: userData.data.id });
    }
  }, [userData.data.id]);

  const fetchTask = () => {
    return api.get("/tasks", {
      params: {
        ...params,
        title: `/${regexQuery.trim()}/i`,
        "relation_createdBy.role.slug": "agent",
        ...(employeeLogin && { "relation_assignee._id": userData?.data?._id }),
      },
    });
  };

  ///add docs
  const updateAttachment = (data) => {
    const arr = Array.from(data.attachments.fileList);
    const formData = new FormData();

    arr.forEach((f) => {
      formData.append("attachments", f.originFileObj);
    });
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return api.patch(`/tasks/${data?.id}/add-attachments`, formData, config);
  };
  const { mutate: mutate4, isLoading: upload_add_loading } = useMutation(
    updateAttachment,
    {
      onSuccess: () => {
        // setFile(null);
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    }
  );

  const uploadFile = ({ image, id }) => {
    mutate4({ attachments: image, id });
  };

  const createTask = async (data) => {
    const password = encryptString(data?.password);

    const res = await api.post("/tasks ", data);
    console.log(res);

    await setDoc(doc(collection(db, "task"), res?.data?._id), {
      username: userData?.data?.userName,
      password: password,
      companyName: data?.companyName,
      userId: userId,
    });

    // res?.data?.createdBy?.fcmTokens?.forEach((token) =>
    //   SendPushNotification(
    //     `${token?.token}`,
    //     `Your Task has been assigned to ${res?.data?.assignee[0]?.firstName}`,
    //     `${res?.data?.title} has been started. Thank you.`,
    //     "",
    //     res.data._id
    //   )
    // );
    // if (res && image) {
    //   uploadFile({ image, id: res?.data?.id });
    // }
    // if (data.markAsTemplate) {
    //   await api.patch(`tasks/${res.data?._id}/mark-as-template`);
    // }
  };

  const { mutate: add_task_mutation, isLoading: add_task_mutation_loading } =
    useMutation(createTask, {
      onSuccess: () => {
        refetch();
        handleCancel();
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });

  const addTask = () => {
    createTaskForm.validateFields().then((values) => {
      values.status = "Pending";
      add_task_mutation(values);
    });
  };

  const {
    isLoading: task_loading,
    data: task_data,
    refetch,
  } = useQuery(["task", regexQuery, params], fetchTask);
  const userId = userData.data._id;

  // const Alldata = task_data?.data.docs.filter(
  //   (e) => e?.createdBy?.role?.slug === "agent"
  // );

  const assignedTask = task_data?.data?.docs?.filter((data) =>
    data.assignee.some((assignee) => assignee._id === userId)
  );

  const employeeLogin = userData?.data.role.slug === "employee";
  const [visible, setVisible] = useState(false);
  const handleCancel = () => {
    createTaskForm.resetFields();
    setVisible(false);
  };

  // TODO:ongoing data nikalne

  const ongoing_data_array = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  assignedTask?.forEach((task) => {
    const monthNumber = moment(task.startDate).format("M");
    ongoing_data_array[monthNumber - 1]++;
  });

  const getAgentTask = ({ status }) => {
    return api.get(
      `/tasks?relation_createdBy._id=${userId}&status=${status}&sort=-createdAt`
    );
  };

  // For "Pending" tasks
  const {
    isLoading: all_loading,
    data: all_tasks,
    refetch: all_refetch,
  } = useQuery("alltasks", () => getAgentTask({ status: "" }));

  // For "Pending" tasks
  const {
    isLoading: pending_loading,
    data: pending_tasks,
    refetch: pending_refetch,
  } = useQuery("pendingTasks", () => getAgentTask({ status: "Pending" }));

  // For "Ongoing" tasks
  const {
    isLoading: ongoing_loading,
    data: ongoing_tasks,
    refetch: ongoing_refetch,
  } = useQuery("ongoingTasks", () => getAgentTask({ status: "Ongoing" }));

  // For "Completed" tasks
  const {
    isLoading: completed_loading,
    data: completed_tasks,
    refetch: completed_refetch,
  } = useQuery("completedTasks", () => getAgentTask({ status: "Completed" }));

  return (
    <>
      <div className="main-container">
        <Row className="content-apart">
          <h1 className="main-title">Agent Tasks</h1>
          <Row>
            <PageSearch
              onChange={(e) => {
                debounce(setRegexQuery(e.target.value), 800);
              }}
              placeholder="Search for Tasks"
            />
            {!restriction[userData?.data.role.slug]?.includes(
              "change_task"
            ) && (
              <Button
                style={{ borderRadius: "0.5em" }}
                type="primary"
                onClick={() => {
                  setVisible(true);
                }}
              >
                <MdAddTask size={20} />
              </Button>
            )}
          </Row>
        </Row>
        <Tabs
          defaultActiveKey={activeTab}
          onTabClick={(status) => {
            if (status === "All") {
              setParams({ ...params, status: undefined, page: 1, skip: 0 });
            } else {
              setParams({ ...params, status, page: 1, skip: 0 });
            }
            refetch();
          }}
          // defaultActiveKey="All"
        >
          <TabPane tab="All" key="All">
            <Table
              loading={task_loading}
              size="small"
              pagination={{
                current: params.page,
                total: all_tasks?.data?.totalDocs,
                pageSize: params.limit,
                showSizeChanger: false,
                onChange: (page) => {
                  setParams({
                    ...params,
                    page,
                    skip: (page - 1) * params.limit,
                  });
                },
              }}
              className="fiscal-table"
              columns={columns}
              dataSource={all_tasks?.data?.docs}
            />
          </TabPane>
          <TabPane tab="Pending" key="Pending">
            <Table
              loading={task_loading}
              pagination={{
                current: params.page,
                total: pending_tasks?.data?.totalDocs,

                pageSize: params.limit,
                onChange: (page) => {
                  setParams({
                    ...params,
                    page,
                    skip: (page - 1) * params.limit,
                  });
                },
              }}
              size="small"
              onRow={(record, _) => {
                return {
                  onClick: (_) => {
                    navigate(`${record._id}`);
                  },
                };
              }}
              className="fiscal-table"
              columns={columns}
              dataSource={pending_tasks?.data.docs}
            />
          </TabPane>
          <TabPane tab="Ongoing" key="Ongoing">
            <Table
              loading={task_loading}
              pagination={{
                current: params.page,
                total: ongoing_tasks?.data?.totalDocs,

                pageSize: params.limit,
                onChange: (page) => {
                  setParams({
                    ...params,
                    page,
                    skip: (page - 1) * params.limit,
                  });
                },
              }}
              size="small"
              onRow={(record, _) => {
                return {
                  onClick: (_) => {
                    navigate(`${record._id}`);
                  },
                };
              }}
              className="fiscal-table"
              columns={columns}
              dataSource={ongoing_tasks?.data?.docs}
            />
          </TabPane>
          <TabPane tab="Completed" key="Completed">
            <Table
              pagination={{
                current: params.page,
                total: completed_tasks?.data?.totalDocs,

                pageSize: params.limit,
                onChange: (page) => {
                  setParams({
                    ...params,
                    page,
                    skip: (page - 1) * params.limit,
                  });
                },
              }}
              loading={task_loading}
              size="small"
              onRow={(record, _) => {
                return {
                  onClick: (_) => {
                    navigate(`${record._id}`);
                  },
                };
              }}
              className="fiscal-table"
              columns={columns}
              dataSource={completed_tasks?.data?.docs}
            />
          </TabPane>
        </Tabs>
      </div>

      {visible && (
        <AgentTaskForm
          okText="Create"
          isModalVisible={visible}
          form={createTaskForm}
          handleCancel={handleCancel}
          handleOk={addTask}
          loading={add_task_mutation_loading}
          headingText=""
          agent
          image={image}
          setImage={setImage}
        />
      )}
    </>
  );
}

export default SingleAgentTask;

const encryptString = (plainText) => {
  const key = CryptoJS.enc.Utf8.parse("+MbQeThVmYq3t6w9z$C&F)J@NcRfUjXn");
  const iv = CryptoJS.enc.Utf8.parse("5ty76ujie324$567");

  const encrypted = CryptoJS.AES.encrypt(plainText.trim(), key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  console.log("Key:", CryptoJS.enc.Hex.stringify(key));
  console.log(
    "Encrypted Password:",
    encrypted.ciphertext.toString(CryptoJS.enc.Hex)
  );

  const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  console.log("Decrypted Password:", decrypted.toString(CryptoJS.enc.Utf8));

  return encrypted.ciphertext.toString(CryptoJS.enc.Hex);
};
