import React, { useState } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  Space,
  Row,
} from "antd";
import { FaInfoCircle, FaPlus, FaMinusCircle } from "react-icons/fa";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 12,
    },
    sm: {
      span: 14,
    },
  },
};
function CompanyRegistrationOfficeForm({
  isVisible,
  handleCancel,
  handleOk,
  loading,
  form,
  okText,
  initialValues,
}) {
  const regOffice = Form.useWatch("regOffice", form);
  const renderExpiryDate = () => {
    return (
      <Form.Item label="Expiry Date" name="expiryDate">
        <DatePicker />
      </Form.Item>
    );
  };
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const renderCROOptions = (param) => {
    switch (param) {
      case "Company Registrar Office":
        return (
          <>
            <Form.Item label="Username" name={["credential", "username"]}>
              <Input placeholder="Username" />
            </Form.Item>
            <Form.Item label="Password" name={["credential", "password"]}>
              <div style={{ display: "flex" }}>
                <Input
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                />
                <Button
                  style={{
                    borderRadius: 8,
                    marginLeft: 10,
                    padding: 5,
                  }}
                  icon={
                    !showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />
                  }
                  onClick={toggleShowPassword}
                ></Button>
              </div>
            </Form.Item>
            <Form.Item
              label="Authorized Capital"
              name={["capital", "authorizedCap"]}
            >
              <Input placeholder="Authorized Capital" />
            </Form.Item>
            <Form.Item label="Issued Capital" name={["capital", "issuedCap"]}>
              <Input placeholder="Issued Capital" />
            </Form.Item>
            <Form.Item label="Paidup Capital" name={["capital", "paidupCap"]}>
              <Input placeholder="Paidup Capital" />
            </Form.Item>
            <Form.List name="shareHolders">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field) => (
                    <Space key={field.key} align="baseline">
                      <Row>
                        <Form.Item label="Name" name={[field.name, "name"]}>
                          <TextArea
                            style={{
                              width: "16rem",
                            }}
                            rows={2}
                            placeholder="Shareholder Name"
                          />
                        </Form.Item>
                        <Form.Item
                          label="Citizenship:"
                          name={[field.name, "citizenshipNo"]}
                        >
                          <TextArea
                            style={{
                              width: "16rem",
                            }}
                            rows={2}
                            placeholder="Citizenship Number"
                          />
                        </Form.Item>
                      </Row>
                      <Row>
                        <Form.Item
                          label="Holding"
                          name={[field.name, "holding"]}
                        >
                          <TextArea
                            style={{
                              width: "16rem",
                            }}
                            rows={2}
                            placeholder="Shareholder Holding"
                          />
                        </Form.Item>
                        <Form.Item label="Ratio" name={[field.name, "ratio"]}>
                          <TextArea
                            style={{
                              width: "16rem",
                            }}
                            rows={2}
                            placeholder="Shareholder Ratio"
                          />
                        </Form.Item>
                        <FaMinusCircle onClick={() => remove(field.name)} />
                      </Row>
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      style={{
                        left: "50%",
                      }}
                      icon={<FaPlus />}
                    >
                      Add Shareholder
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </>
        );
      case "Department of Commerce and Supply":
        return <>{renderExpiryDate()}</>;
      case "Department of Cottage and Small Industry":
        return <>{renderExpiryDate()}</>;

      case "Local Bodies":
        return <>{renderExpiryDate()}</>;


      case "Other Bodies":
        return <>{renderExpiryDate()}</>;

      case "Licensing Bodies":
        return <>{renderExpiryDate()}</>;

      default:
        return;
    }
  };
  return (
    <Modal
      maskClosable={false}
      visible={isVisible}
      onCancel={handleCancel}
      okText="Create"
      className="global-modal"
      cancelText="Cancel"
      width={"40vw"}
      style={{ background: "white", top: "0px" }}
      footer={[
        <Button
          loading={loading}
          key="submit"
          type="primary"
          onClick={handleOk}
        >
          {okText}
        </Button>,
      ]}
    >
      <Form
        initialValues={initialValues}
        {...formItemLayout}
        form={form}
        layout="vertical"
      >
        <Form.Item
          label="Registration Number"
          name="regNo"
          required
          tooltip={{
            title: "Enter registration number",
            icon: <FaInfoCircle />,
          }}
          rules={[
            {
              required: true,
              message: "Please enter registration number!",
            },
          ]}
        >
          <Input placeholder="Registration Number" />
        </Form.Item>
        <Form.Item
          label="Registration Office"
          name="regOffice"
          required
          tooltip={{
            title: "Enter registration office",
            icon: <FaInfoCircle />,
          }}
          rules={[
            {
              required: true,
              message: "Please enter registration office !",
            },
          ]}
        >
          <Select>
            <Select.Option value="Company Registrar Office">
              Company Registrar Office
            </Select.Option>
            <Select.Option value="Department of Commerce and Supply">
              Department of Commerce and Supply
            </Select.Option>
            <Select.Option value="Department of Cottage and Small Industry">
              Department of Cottage and Small Industry
            </Select.Option>
            <Select.Option value="Local Bodies">Local Bodies</Select.Option>
            <Select.Option value="Inland Department">
              Inland Revenue Department
            </Select.Option>
            <Select.Option value="Other Bodies">Other Bodies</Select.Option>
            <Select.Option value="Licensing Bodies">
              Licensing Bodies
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          required
          label="Registration Date"
          name="regDate"
          rules={[
            {
              type: "object",
              required: false,
              message: "Please input registration date",
              whitespace: true,
            },
          ]}
        >
          <DatePicker />
        </Form.Item>
        {renderCROOptions(regOffice)}
      </Form>
    </Modal>
  );
}

export default CompanyRegistrationOfficeForm;
