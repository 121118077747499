export const restriction = {
  admin: ["agent_company"],
  applicationadministrator: ["agent_company"],
  superadmin: ["agent_company"],
  moderator: [
    "change_employee",
    "change_client",
    "change_feed",
    "change_notification",
    "change_inventory",
    "access_modify",
    "agent_company",
  ],
  agent: [
    "view_inventory",
    "change_notification",
    "change_company",
    // "access_modify",
    "view_dashboard",
    "agent_dashboard",
    "edit_news",
    "no-access-newsclient",
    "add_assignee",
    "change_news_section",
    "access_module",
    "access_company"
  ],
  user: ["view_inventory", "view_company", "change_user", "agent_company"],
  employee: [
    "change_employee",
    "change_client",
    "change_task",
    "view_task_assigned",
    "change_feed",
    "change_news_section",
    "change_news",
    "change_news_category",
    "change_notification",
    "change_company",
    "no-access-newsclient",
    // "change_inventory",
    // "access_modify",
    // "view_company",
    "view_inventory",
    "view_all_client",
    "view_all_company",
    "view_notification",
    "add_attachment",
    "add_assignee",
    "add_news_img",
    "access_modify",
    "agent_company",
    // "view_user_overview",
  ],
  feedwritter: [
    // "change_employee",
    // "change_client",
    // "change_task",
    "change_notification",
    // "change_company",
    "view_inventory",
    // "view_company",
    // "view_all_task",
    // "view_all_client",
    // "view_all_company",
    // "view_dashboard",
    "view_company",
    "agent_company",
    "feed_task",
  ],
};
