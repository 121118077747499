import React from "react";
import noDataImg from "../../assets/icons/no-data.png";
import "./NoData.css"; // Import CSS file for styling

const NoData = () => {
  return (
    <div className="no-data-container">
      <img src={noDataImg} alt="No Data Icon" className="no-data-icon" />
      <p className="no-data-text">
        No data available at the moment. Please try again later.
      </p>
      {/* Add additional elements or buttons here if needed */}
    </div>
  );
};

export default NoData;
