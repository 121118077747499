import axios from "axios";

export const SendPushNotification = async (
  token,
  title,
  body,
  screen,
  id,
  image
) => {
  var data = JSON.stringify({
    data: {
      screen,
      id,
    },
    notification: {
      body,
      title,
      image,
    },
    to: token,
  });

  const options = {
    method: "POST",
    url: "https://fcm.googleapis.com/fcm/send",
    headers: {
      Authorization:
        "key=AAAAgnGlZts:APA91bF5IxHiauFHpLYi2j1H8hEI_hKXrBShk0QtGeJKTcmav_k3lzfnDc27-4CKGS98pvbcxReOiGYUWc-bdyXbwMVjCH9IYw52x9q_KR7_w0VjzCchinZIQ0QiPcciVF4yGLMzJUVE",
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    axios
      .request(options)
      .then(function (response) {
        console.log(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  } catch (error) {
    console.log("error", error);
  }
};
