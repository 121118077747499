import {
  Avatar,
  Button,
  Checkbox,
  Col,
  Dropdown,
  Form,
  Image,
  List,
  Menu,
  Modal,
  Popover,
  Row,
  Space,
  Tag,
  Tooltip,
  Upload,
  message,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { collection, doc, getDoc, getFirestore } from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { AiOutlineUpload } from "react-icons/ai";
import { BiUserPlus } from "react-icons/bi";
import { BsFlag } from "react-icons/bs";
import { FiDownload, FiSend } from "react-icons/fi";
import { IoEllipsisVertical } from "react-icons/io5";
import {
  MdContactPhone,
  MdDelete,
  MdEmail,
  MdModeEdit,
  MdOutlineDriveFileRenameOutline,
} from "react-icons/md";
import { VscDebugStackframeDot } from "react-icons/vsc";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Doc from "../../../assets/images/doc.png";
import Excel from "../../../assets/images/excel.png";
import Pdf from "../../../assets/images/pdf.png";
import DebounceSelect from "../../../components/common/DebounceSelect";
import Loading from "../../../components/common/Loading";
import { restriction } from "../../../constant/Restrictions";
import { api } from "../../../services/Api";
import { fetchUser } from "../../../services/User";
import { SendPushNotification } from "../../../utils/SendNotification";
import { stringToColour } from "../../../utils/getProfileColor";
import { limit } from "../../../utils/truncateString";
import AssignEmployeeForm from "./AssignEmployeeForm";
import TaskEditForm from "./TaskEditForm";

import { app } from "../../../App";
var CryptoJS = require("crypto-js");

const colorByStatus = {
  Pending: "volcano",
  Ongoing: "yellow",
  Completed: "green",
};
const priorityFlag = {
  Low: "green",
  Medium: "#e4d422",
  High: " #d07e59",
};

function TaskDetail() {
  let { taskId } = useParams();
  const navigate = useNavigate();
  const [evisible, seteVisible] = useState(false);
  const [company, setCompany] = useState(null);

  const [addAssigneeVisible, setAddAssigneeVisible] = useState(false);
  const { isUserLoading, data: userData } = useQuery("user", fetchUser);
  const [addAssigneeForm] = Form.useForm();
  const [form] = Form.useForm();
  const [editTaskForm] = Form.useForm();
  const [fileUrl, setFileUrl] = useState();
  const [filePreview, setFilePreview] = useState(false);
  const db = getFirestore(app);

  const props = {
    listType: "picture",
    beforeUpload: (file) => {
      uploadFile(file);
      return false;
    },
    showUploadList: false,
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: [
        {
          text: "Pending",
          value: "Pending",
        },
        {
          text: "Ongoing",
          value: "Ongoing",
        },
        {
          text: "Completed",
          value: "Completed",
        },
      ],
      onFilter: (value, record) => record.status === value,
      render: (status) => {
        return <Tag color={colorByStatus[status]}>{status}</Tag>;
      },
    },
  ];

  const [task, setTask] = useState({
    id: "",
    title: "",
    description: "",
    company: "",
  });

  const showEditModal = (data) => {
    setTask(data);
    seteVisible(true);
  };
  const [popoverVisible, setPopoverVisible] = useState(false);
  const getUsers = async (queryString, exclude) => {
    return api
      .get("/users", {
        params: {
          "userName!": exclude.join(","),
          // "relation_role.slug!": "user",
          "relation_role.slug": "employee",
          // "role.slug!": "user,applicationadministrator,superadmin,admin",

          firstName: encodeURI(`/${queryString}/i`),
        },
      })
      .then((body) =>
        body.data.docs.map((user) => ({
          label: `${user.firstName} ${user.lastName}`,
          value: user._id,
        }))
      );
  };

  const priorityTask = (data) => {
    const payload = {
      priority: data.priority,
    };
    return api.patch("/tasks/" + data.taskId, payload);
  };
  const { mutate: priority_task_mutate } = useMutation(priorityTask, {
    onSuccess: () => {
      refetch();
      setPopoverVisible(false);
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });

  const handleEditCancel = () => {
    seteVisible(false);
  };

  const postComments = (payload) => {
    payload.task = taskId;
    return api.post(`/task/${taskId}/comments`, payload);
  };

  const { mutate: comments_add_mutate, isLoading: comments_add_loading } =
    useMutation(postComments, {
      onSuccess: () => {
        SendPushNotification();
        comments_refetch();
        form.resetFields();
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });

  const patchTaskStatus = (data) => {
    const payload = {
      status: data.status,
    };
    return api.patch(`/tasks/${taskId}/update-status`, payload);
  };

  const { mutate: status_patch_mutate, isLoading: status_patch_loading } =
    useMutation(patchTaskStatus, {
      onSuccess: () => {
        refetch();
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    });

  const getData = () => {
    return api.get("/tasks/" + taskId);
  };
  const getUser = () => {
    return api.get("/users");
  };
  const getComments = () => {
    return api.get(`/task/${taskId}/comments`);
  };
  const removeTask = (id) => {
    return api.delete("/tasks/" + id);
  };
  const removeAssignee = (data) => {
    return api.patch("/tasks/" + taskId + "/remove-assigned-user", data);
  };
  const assignTask = (data) => {
    return api.patch("/tasks/" + taskId + "/assign-user", data);
  };
  const updateStatus = (data) => {
    return api.patch("/tasks/" + taskId, data);
  };

  const updateModuleStatus = (data) => {
    const payload = {
      status: data.status === "Pending" ? "Completed" : "Pending",
    };
    return api.patch(
      "/tasks/" + taskId + "/modules/" + data.id + "/update-status",
      payload
    );
  };

  //module assignee
  const assigneeModules = (data) => {
    const payload = {
      assignee: data.assignee,
    };
    return api.patch(
      "/tasks/" + taskId + "/module/" + data.moduleId + "/assign-user",
      payload
    );
  };

  const { mutate: assign_module_mutate } = useMutation(assigneeModules, {
    onSuccess: () => {
      refetch();
      setPopoverVisible(false);
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });

  //update
  const { mutate: updateModuleS } = useMutation(updateModuleStatus, {
    onSuccess: () => {
      refetch();
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });
  //checkbox
  const toggleChecked = (data) => {
    updateModuleS(data);
  };

  const updateAttachment = (data) => {
    const arr = Array.from(data.attachments);
    const formData = new FormData();
    arr.forEach((f) => {
      formData.append("attachments", f);
    });
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return api.patch(`/tasks/${taskId}/add-attachments`, formData, config);
  };

  const removeAttachment = (data) => {
    return api.delete(`/tasks/${taskId}/remove-attachment/${data}`);
  };
  const updateTask = (data) => {
    return api.patch("/tasks/" + taskId, data);
  };

  const { mutate: assign_task_mutate } = useMutation(assignTask, {
    onSuccess: () => {
      refetch();
      addAssigneeForm.resetFields();
      setAddAssigneeVisible(false);
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });

  const { mutate: mutate2 } = useMutation(removeTask, {
    onSuccess: () => {
      navigate("/dashboard/tasks");
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });
  const { mutate: delete_assignee_mutate } = useMutation(removeAssignee, {
    onSuccess: () => {
      refetch();
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });
  const { mutate: mutate3 } = useMutation(updateStatus, {
    onSuccess: () => {
      refetch();
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });
  const { mutate: mutate4, isLoading: upload_add_loading } = useMutation(
    updateAttachment,
    {
      onSuccess: () => {
        // setFile(null);
        refetch();
      },
      onError: (e) => {
        alert(e?.response?.data?.message);
      },
    }
  );
  const { mutate: mutate5 } = useMutation(removeAttachment, {
    onSuccess: () => {
      refetch();
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });
  const { mutate: mutate6 } = useMutation(updateTask, {
    onSuccess: () => {
      refetch();
    },
    onError: (e) => {
      alert(e?.response?.data?.message);
    },
  });

  const {
    isLoading: loading1,
    data: data1,
    refetch,
  } = useQuery("taskd", getData);
  const { isLoading: loading2, data: data2 } = useQuery("userdata", getUser, {
    refetchOnWindowFocus: "always",
  });
  const {
    isLoading: comments_loading,
    data: comments_data,
    refetch: comments_refetch,
  } = useQuery("comments", getComments);

  useEffect(() => {
    const interval = setInterval(() => {
      comments_refetch();
    }, 100000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const getAgentTaskCompany = async () => {
      try {
        const taskDocRef = doc(collection(db, "task"), taskId);
        const taskDocSnapshot = await getDoc(taskDocRef);

        if (taskDocSnapshot.exists()) {
          const data = taskDocSnapshot.data();
          if (data?.password) {
            // Decrypt the password
            const key = CryptoJS.enc.Utf8.parse(
              "+MbQeThVmYq3t6w9z$C&F)J@NcRfUjXn"
            );
            const iv = CryptoJS.enc.Utf8.parse("5ty76ujie324$567");

            const encryptedBytes = CryptoJS.enc.Hex.parse(
              data.password.toString()
            );
            const encryptedBase64 =
              CryptoJS.enc.Base64.stringify(encryptedBytes);

            const decrypted = CryptoJS.AES.decrypt(encryptedBase64, key, {
              iv: iv,
              mode: CryptoJS.mode.CBC,
              padding: CryptoJS.pad.Pkcs7,
            });

            const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);

            setCompany((prev) => ({
              ...prev,
              companyName: data.companyName,
              email: data.username,
              password: decryptedText,
            }));
          } else {
            console.log("Password field is missing!");
          }
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching task:", error);
      }
    };

    getAgentTaskCompany();
  }, [db, taskId, setCompany]);

  ////agent role
  const isAgent = userData?.data?.role?.name === "Agent" ? true : false;
  ///agent
  if (loading1) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Loading />
      </div>
    );
  }
  if (loading2) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Loading />
      </div>
    );
  }
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure ?",
      text: "You want to delete this task",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Ok`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        mutate2(id);
      }
    });
  };
  const handleAssigneeDelete = (id) => {
    Swal.fire({
      title: "Are you sure ?",
      text: "You want to remove this assignee",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Ok`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        delete_assignee_mutate({
          assignee: id,
        });
      }
    });
  };
  const uploadFile = (file) => {
    mutate4({ attachments: [file] });
  };
  const handleAttachmentDelete = (data) => {
    Swal.fire({
      title: "Are you sure ?",
      text: "You want to delete this file",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Ok`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        mutate5(data);
      }
    });
  };

  const editTask = () => {
    editTaskForm.validateFields().then((values) => {
      mutate6(values);
      handleEditCancel();
    });
  };

  const menu = (
    <Menu mode="horizontal">
      <Menu.Item
        key="one"
        onClick={() => showEditModal(data1?.data)}
        icon={
          <MdModeEdit
            style={{
              color: "blue",
              fontSize: "20px",
            }}
          />
        }
      >
        Edit
      </Menu.Item>

      <Menu.Item
        key="two"
        onClick={() => {
          handleDelete(data1.data._id);
        }}
        icon={
          <MdDelete
            style={{
              color: "red",
              fontSize: "20px",
            }}
          />
        }
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  //get task company from firebase storage

  return (
    <>
      <div className="main-container">
        <Row className="content-apart">
          <div className="breadcrumb">
            <span
              onClick={() => {
                navigate(-1);
              }}
              className="main-title link-element"
            >
              Task/
            </span>
            <span className="main-subtitle">Task Detail</span>
          </div>
          {!restriction[userData?.data.role.slug].includes("access_modify") && (
            <Dropdown overlay={menu}>
              <Space>
                <IoEllipsisVertical
                  style={{
                    cursor: "pointer",
                    color: "#00ABC5",
                  }}
                  size={20}
                />
              </Space>
            </Dropdown>
          )}
        </Row>
        <Row className="header-details-task">
          <Col span={24}>
            <h1 style={{ fontSize: "27px", opacity: 0.8, marginTop: "8px" }}>
              {data1?.data.title}
            </h1>
            <Row className="mt-8" style={{ justifyContent: "space-between" }}>
              <div>
                <Row>
                  <Avatar.Group
                    maxCount={5}
                    maxPopoverTrigger="hover"
                    size="large"
                    maxStyle={{
                      color: "#f56a00",
                      backgroundColor: "#fde3cf",
                      cursor: "pointer",
                    }}
                  >
                    {data1.data.assignee.map((user, i) => {
                      const color = stringToColour(user.userName);
                      return (
                        <div key={i}>
                          <Tooltip
                            title={
                              <div>
                                {`${user.firstName} ${user.lastName}`}
                                <MdDelete
                                  onClick={() => {
                                    handleAssigneeDelete(user._id);
                                  }}
                                  style={{
                                    color: "red",
                                    fontSize: "20px",
                                    cursor: "pointer",
                                  }}
                                />
                              </div>
                            }
                            placement="top"
                          >
                            <Avatar
                              className="capital"
                              style={{ backgroundColor: color }}
                            >
                              {user?.firstName[0]}
                            </Avatar>
                          </Tooltip>
                        </div>
                      );
                    })}
                    '
                    {!restriction[userData?.data.role.slug]?.includes(
                      "add_assignee"
                    ) && (
                      <Popover
                        open={popoverVisible}
                        onOpenChange={() => {
                          setAddAssigneeVisible(true);
                        }}
                        content={
                          <div
                            style={{
                              width: "300px",
                            }}
                          >
                            <DebounceSelect
                              fetchOptions={(query) =>
                                getUsers(
                                  query,
                                  data1?.data?.assignee?.map(
                                    (e) => e.userName
                                  ) ?? []
                                )
                              }
                              onChange={(e) => {
                                assign_task_mutate({
                                  taskId: taskId,
                                  assignee: e,
                                });
                              }}
                              placeholder="Assignee"
                            />
                          </div>
                        }
                        trigger="click"
                        placement="bottomLeft"
                      >
                        <Avatar
                          size="large"
                          style={{
                            backgroundColor: "#87d068",
                            cursor: "pointer",
                            padding: "4px",
                          }}
                        >
                          <BiUserPlus size={20} />
                        </Avatar>
                      </Popover>
                    )}
                  </Avatar.Group>
                  <div style={{ marginTop: "12px" }}>
                    <h3 style={{ fontWeight: 500 }}>
                      <span style={{ marginLeft: "8px" }}>
                        <Tag color={colorByStatus[data1?.data?.status]}>
                          {data1?.data?.status}
                        </Tag>
                      </span>
                    </h3>
                  </div>
                  <div style={{ marginTop: "12px" }}>
                    <h3 style={{ fontWeight: 600 }}>
                      <span
                        style={{
                          marginLeft: "8px",
                        }}
                      >
                        {!restriction[userData?.data.role.slug]?.includes(
                          "add_assignee"
                        ) ? (
                          <Dropdown
                            overlay={
                              <Menu>
                                <Menu.Item
                                  onClick={() =>
                                    priority_task_mutate({
                                      taskId: taskId,
                                      priority: "High",
                                    })
                                  }
                                >
                                  High
                                </Menu.Item>
                                <Menu.Item
                                  onClick={() =>
                                    priority_task_mutate({
                                      taskId: taskId,
                                      priority: "Medium",
                                    })
                                  }
                                >
                                  Medium
                                </Menu.Item>
                                <Menu.Item
                                  onClick={() =>
                                    priority_task_mutate({
                                      taskId: taskId,
                                      priority: "Low",
                                    })
                                  }
                                >
                                  Low
                                </Menu.Item>
                              </Menu>
                            }
                            trigger={["click"]}
                          >
                            <BsFlag
                              style={{
                                cursor: "pointer",
                              }}
                              color={
                                priorityFlag[data1?.data?.priority ?? "Medium"]
                              }
                              size={20}
                            />
                          </Dropdown>
                        ) : (
                          <BsFlag
                            style={{
                              cursor: "pointer",
                            }}
                            color={
                              priorityFlag[data1?.data?.priority ?? "Medium"]
                            }
                            size={20}
                          />
                        )}
                      </span>
                    </h3>
                  </div>
                  &nbsp; &nbsp;
                  <div style={{ marginTop: "12px" }}>
                    <h3 style={{ fontWeight: 500 }}>
                      <span
                        style={{
                          color: "#554545",
                          fontWeight: "bold",
                          opacity: 0.9,
                        }}
                      >
                        Start:
                      </span>
                      &nbsp;
                      <span style={{ marginLeft: "5px", marginTop: "-20px" }}>
                        {moment(data1?.data?.createdAt).format(
                          "MMM Do, h:mm a"
                        )}
                      </span>
                    </h3>
                  </div>
                  &nbsp; &nbsp;
                  <div style={{ marginTop: "12px" }}>
                    <h3 style={{ fontWeight: 500 }}>
                      <span
                        style={{
                          color: "#554545",
                          fontWeight: "bold",
                          opacity: 0.9,
                        }}
                      >
                        Due:
                      </span>
                      &nbsp;
                      <span style={{ marginLeft: "5px", marginTop: "-20px" }}>
                        {moment(data1?.data?.dueDate).format("MMM Do, h:mm a")}
                      </span>
                    </h3>
                  </div>
                </Row>
              </div>
            </Row>
          </Col>
        </Row>
        {!restriction[userData?.data?.role.slug]?.includes("agent_company") && (
          <Col span={12}>
            <div className="agent-company-details shadow-card">
              <h1 style={{ fontSize: "24px", fontWeight: "bold" }}>
                Company Details
              </h1>
              <hr />
              <p
                style={{
                  fontSize: "15px",
                  opacity: "0.8",
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <MdOutlineDriveFileRenameOutline /> &nbsp; <span>Name: </span>
                {company?.companyName}
              </p>

              <p
                style={{
                  fontSize: "15px",
                  opacity: "0.8",
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <MdEmail /> &nbsp; <span>Email/Username: </span>
                {company?.email}
              </p>

              <p
                style={{
                  fontSize: "15px",
                  opacity: "0.8",
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <MdContactPhone /> &nbsp; <span>Password: </span>
                {company?.password}
              </p>
            </div>
          </Col>
        )}

        {/* main content of task */}
        <Row gutter={54} className="mt-16">
          <Col
            span={12}
            style={{
              overflow: "auto",
              marginTop: "20px",
            }}
          >
            <h2>Description</h2>
            <hr />
            {data1?.data?.description ? (
              <h3
                style={{ opacity: 0.7 }}
                dangerouslySetInnerHTML={{ __html: data1?.data?.description }}
              ></h3>
            ) : (
              <h3 style={{ opacity: 0.7 }}>No description</h3>
            )}
          </Col>

          <Col span={12} className="comment-section">
            <h1 style={{ fontSize: "27px", opacity: 0.8 }}>#Comments</h1>
            <hr />

            <Row style={{ display: "block" }}>
              <div
                style={{
                  overflow: "scroll",
                  height: "40vh",
                }}
              >
                {comments_data?.data?.docs?.map((c, i) => {
                  if (userData?.data?.userName === c?.createdBy?.userName) {
                    const color = stringToColour(c?.createdBy?.userName);
                    return (
                      <>
                        <div
                          style={{
                            borderRadius: "10px",
                            padding: "5px",
                            marginTop: "10px",
                          }}
                          key={i}
                        >
                          <h3>
                            <Row>
                              <Avatar
                                style={{
                                  border: "3px solid white",
                                  width: "45px",
                                  height: "45px",
                                  background: color,
                                }}
                                size="large"
                                src={c?.createdBy?.avatar?.url}
                              >
                                {c?.createdBy?.firstName[0]}
                              </Avatar>
                              &nbsp;
                              <h2
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 600,
                                }}
                              >
                                {c?.createdBy?.firstName}
                              </h2>
                              &nbsp; &nbsp; &nbsp;
                              <p
                                style={{
                                  opacity: 0.9,
                                  fontSize: "12px",
                                  color: "#d3b882",
                                  marginTop: "5px",
                                }}
                              >
                                <VscDebugStackframeDot /> &nbsp; &nbsp;
                                {moment(c?.createdAt).format("MMM Do, h:mm a")}
                              </p>
                            </Row>
                          </h3>
                          <Row
                            style={{
                              marginLeft: "49px",
                              marginTop: "-30px",
                            }}
                          >
                            <h4
                              style={{
                                fontSize: "13px",
                                opacity: 0.88,
                                overflowWrap: "anywhere",
                              }}
                            >
                              {c?.message}
                            </h4>
                          </Row>
                        </div>
                      </>
                    );
                  } else {
                    const color = stringToColour(c?.createdBy?.userName);
                    return (
                      <>
                        <div
                          className="right-align"
                          style={{
                            borderRadius: "10px",
                            padding: "5px",
                            marginTop: "10px",
                          }}
                          key={i}
                        >
                          <h3>
                            <Row>
                              <p
                                style={{
                                  opacity: 0.9,
                                  fontSize: "12px",
                                  color: "#d3b882",
                                  marginTop: "3px",
                                }}
                              >
                                {moment(c?.createdAt).format("MMM Do, h:mm a")}
                                &nbsp; &nbsp;&nbsp;
                                <VscDebugStackframeDot />
                              </p>
                              &nbsp; &nbsp; &nbsp;
                              <h2
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  marginRight: "5px",
                                }}
                              >
                                {c?.createdBy?.firstName}
                              </h2>
                              <Avatar
                                style={{
                                  border: "3px solid white",
                                  width: "45px",
                                  height: "45px",
                                  background: color,
                                }}
                                size="large"
                                src={c?.createdBy?.avatar?.url}
                              >
                                {c?.createdBy?.firstName[0]}
                              </Avatar>
                            </Row>
                          </h3>
                          <Row
                            style={{
                              marginTop: "-30px",
                            }}
                            className="right-align-message"
                          >
                            <h4
                              style={{
                                fontSize: "13px",
                                opacity: 0.88,
                                overflowWrap: "anywhere",
                              }}
                            >
                              {c?.message}
                            </h4>
                          </Row>
                        </div>
                      </>
                    );
                  }
                })}
              </div>
            </Row>

            <Form
              form={form}
              style={{ position: "sticky" }}
              name="basic"
              wrapperCol={{
                span: 24,
              }}
              autoComplete="off"
              className=""
            >
              <Row>
                <Form.Item
                  name="message"
                  required
                  rules={[
                    {
                      message: "Please type comments first!",
                    },
                  ]}
                >
                  <TextArea
                    className="comment-input"
                    placeholder="Type your comments"
                    rows={1}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    style={{ paddingTop: "6px", float: "right" }}
                    loading={comments_add_loading}
                    type="primary"
                    htmlType="submit"
                    onClick={() => {
                      form
                        .validateFields()
                        .then((values) => {
                          comments_add_mutate(values);
                          message.success({
                            content: "Comment Added",
                            style: {
                              right: 0,
                              float: "right",
                            },
                          });
                        })
                        .catch((info) => {
                          console.log("Validate Failed:", info);
                        });
                    }}
                  >
                    <FiSend size={20} />
                  </Button>
                </Form.Item>
              </Row>
            </Form>
          </Col>
        </Row>
        {/* here */}
        {!restriction[userData?.data.role.slug].includes("add_attachment") && (
          <>
            <h1>Add Task Attachments:</h1>
            <Upload.Dragger multiple {...props}>
              Drag files here Or
              <br />
              <br />
              <Button loading={upload_add_loading} icon={<AiOutlineUpload />}>
                Upload
              </Button>
            </Upload.Dragger>
          </>
        )}
        <div className="mt-32">
          <div className="mt-8">
            {data1?.data?.attachments?.length > 0 && (
              <h1
                style={{
                  marginTop: "10px",
                }}
              >
                Attachments
              </h1>
            )}
            <Row>
              <Col className="attachment-details" span={24}>
                <Row className="display-attachments">
                  <ul
                    style={{
                      listStyle: "none",
                      display: "flex",
                    }}
                  >
                    {data1?.data.attachments.map((file, i) => {
                      if (file.ext === ".pdf") {
                        return (
                          <>
                            <li
                              style={{
                                marginLeft: "7px",
                              }}
                            >
                              <Image
                                style={{
                                  float: "left",
                                  borderRadius: "5px",
                                }}
                                src={Pdf}
                                preview={false}
                                alt="img"
                                width={80}
                                height={80}
                                onClick={() => {
                                  setFileUrl(file.url);
                                  setFilePreview(true);
                                }}
                              />

                              <Dropdown
                                overlay={
                                  <Menu>
                                    <Menu.Item>
                                      <button
                                        className="delete-btn"
                                        id="del-task"
                                        onClick={() => {
                                          handleAttachmentDelete(file.id);
                                        }}
                                        style={{
                                          position: "relative",
                                        }}
                                      >
                                        <MdDelete
                                          style={{
                                            color: "red",
                                            fontSize: "18px",
                                          }}
                                        />
                                      </button>
                                    </Menu.Item>

                                    <Menu.Item>
                                      <button
                                        className="delete-btn"
                                        id="del-task"
                                        style={{
                                          position: "relative",
                                        }}
                                      >
                                        <a href={file.url} target="next">
                                          <FiDownload
                                            style={{
                                              color: "blue",
                                              fontSize: "18px",
                                            }}
                                          />
                                        </a>
                                      </button>
                                    </Menu.Item>
                                  </Menu>
                                }
                                trigger={["hover"]}
                              >
                                <IoEllipsisVertical
                                  style={{
                                    marginBottom: "55px",
                                    marginLeft: "7px",
                                    color: "#252222",
                                    cursor: "pointer",
                                  }}
                                  size={20}
                                />
                              </Dropdown>
                              <p
                                style={{
                                  fontSize: "10px",
                                }}
                              >
                                {limit(file.filename, 15)}
                              </p>
                            </li>
                          </>
                        );
                      } else if (file.ext === ".docx") {
                        return (
                          <li
                            style={{
                              marginLeft: "7px",
                            }}
                          >
                            <Image
                              style={{
                                float: "left",
                                borderRadius: "5px",
                              }}
                              src={Doc}
                              alt="img"
                              preview={false}
                              width={80}
                              height={80}
                              onClick={() => {
                                setFileUrl(file.url);
                                setFilePreview(true);
                              }}
                            />
                            <Dropdown
                              overlay={
                                <Menu>
                                  <Menu.Item>
                                    <button
                                      className="delete-btn"
                                      id="del-task"
                                      onClick={() => {
                                        handleAttachmentDelete(file.id);
                                      }}
                                      style={{
                                        position: "relative",
                                      }}
                                    >
                                      <MdDelete
                                        style={{
                                          color: "red",
                                          fontSize: "18px",
                                        }}
                                      />
                                    </button>
                                  </Menu.Item>

                                  <Menu.Item>
                                    <button
                                      className="delete-btn"
                                      id="del-task"
                                      style={{
                                        position: "relative",
                                      }}
                                    >
                                      <a href={file.url} target="next">
                                        <FiDownload
                                          style={{
                                            color: "blue",
                                            fontSize: "18px",
                                          }}
                                        />
                                      </a>
                                    </button>
                                  </Menu.Item>
                                </Menu>
                              }
                              trigger={["hover"]}
                            >
                              <IoEllipsisVertical
                                style={{
                                  marginBottom: "55px",
                                  marginLeft: "7px",
                                  color: "#252222",
                                  cursor: "pointer",
                                }}
                                size={20}
                              />
                            </Dropdown>
                            <p
                              style={{
                                fontSize: "10px",
                              }}
                            >
                              {limit(file.filename, 15)}
                            </p>
                          </li>
                        );
                      } else if (file.ext === ".xlsx") {
                        return (
                          <li
                            style={{
                              marginLeft: "7px",
                            }}
                          >
                            <Image
                              style={{
                                float: "left",
                                borderRadius: "5px",
                              }}
                              src={Excel}
                              alt="img"
                              preview={false}
                              width={80}
                              height={80}
                              onClick={() => {
                                setFileUrl(file.url);
                                setFilePreview(true);
                              }}
                            />
                            <Dropdown
                              overlay={
                                <Menu>
                                  <Menu.Item>
                                    <button
                                      className="delete-btn"
                                      id="del-task"
                                      onClick={() => {
                                        handleAttachmentDelete(file.id);
                                      }}
                                      style={{
                                        position: "relative",
                                      }}
                                    >
                                      <MdDelete
                                        style={{
                                          color: "red",
                                          fontSize: "18px",
                                        }}
                                      />
                                    </button>
                                  </Menu.Item>

                                  <Menu.Item>
                                    <button
                                      className="delete-btn"
                                      id="del-task"
                                      style={{
                                        position: "relative",
                                      }}
                                    >
                                      <a href={file.url} target="next">
                                        <FiDownload
                                          style={{
                                            color: "blue",
                                            fontSize: "18px",
                                          }}
                                        />
                                      </a>
                                    </button>
                                  </Menu.Item>
                                </Menu>
                              }
                              trigger={["hover"]}
                            >
                              <IoEllipsisVertical
                                style={{
                                  marginBottom: "55px",
                                  marginLeft: "7px",
                                  color: "#252222",
                                  cursor: "pointer",
                                }}
                                size={20}
                              />
                            </Dropdown>
                            <p
                              style={{
                                fontSize: "10px",
                              }}
                            >
                              {limit(file.filename, 15)}
                            </p>
                          </li>
                        );
                      }
                      return (
                        <li
                          style={{
                            marginLeft: "7px",
                          }}
                        >
                          <Image
                            style={{
                              float: "left",
                              borderRadius: "5px",
                            }}
                            src={file.url}
                            alt="img"
                            width={80}
                            height={80}
                          />
                          <Dropdown
                            overlay={
                              <Menu>
                                <Menu.Item>
                                  <button
                                    className="delete-btn"
                                    id="del-task"
                                    onClick={() => {
                                      handleAttachmentDelete(file.id);
                                    }}
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <MdDelete
                                      style={{
                                        color: "red",
                                        fontSize: "18px",
                                      }}
                                    />
                                  </button>
                                </Menu.Item>

                                <Menu.Item>
                                  <button
                                    className="delete-btn"
                                    id="del-task"
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <a href={file.url} target="next">
                                      <FiDownload
                                        style={{
                                          color: "blue",
                                          fontSize: "18px",
                                        }}
                                      />
                                    </a>
                                  </button>
                                </Menu.Item>
                              </Menu>
                            }
                            trigger={["hover"]}
                          >
                            <IoEllipsisVertical
                              style={{
                                marginBottom: "55px",
                                marginLeft: "7px",
                                color: "#252222",
                                cursor: "pointer",
                              }}
                              size={20}
                            />
                          </Dropdown>
                          <p
                            style={{
                              fontSize: "10px",
                            }}
                          >
                            {limit(file.filename, 15)}
                          </p>
                        </li>
                      );
                    })}
                  </ul>
                </Row>
              </Col>
            </Row>
          </div>
          <br />
          {!restriction[userData?.data.role.slug].includes("access_module") && (
            <>
              {data1?.data?.modules?.length > 0 && <h1>Modules</h1>}
              <Row style={{ display: "block" }}>
                <Col className="module-section" span={12}>
                  {data1?.data?.modules?.map((m, i) => {
                    return (
                      <>
                        <List key={i}>
                          <Row
                            style={{
                              justifyContent: "flex-start",
                              minWidth: "150%",
                            }}
                          >
                            <Checkbox
                              checked={m.status === "Completed"}
                              onClick={() => {
                                toggleChecked({ id: m._id, status: m.status });
                              }}
                            />
                            &nbsp;
                            <p>{m.title}</p>
                            &nbsp;
                            <Avatar.Group
                              maxCount={5}
                              maxPopoverTrigger="hover"
                              size="small"
                              maxStyle={{
                                color: "#f56a00",
                                backgroundColor: "#fde3cf",
                                cursor: "pointer",
                              }}
                            >
                              {m?.assignee?.map((a) => {
                                const color = stringToColour(a?.userName);
                                return (
                                  <>
                                    <Avatar
                                      className="capital"
                                      style={{ backgroundColor: color }}
                                    >
                                      {a?.firstName[0]}
                                    </Avatar>
                                  </>
                                );
                              })}

                              <Popover
                                open={popoverVisible}
                                onOpenChange={() => {
                                  setAddAssigneeVisible(true);
                                }}
                                content={
                                  <div
                                    style={{
                                      width: "300px",
                                    }}
                                  >
                                    <DebounceSelect
                                      fetchOptions={(query) =>
                                        getUsers(
                                          query,
                                          data1?.data?.assignee?.map(
                                            (e) => e.userName
                                          ) ?? []
                                        )
                                      }
                                      onChange={(e) => {
                                        assign_module_mutate({
                                          moduleId: m._id,
                                          assignee: e,
                                        });
                                      }}
                                      placeholder="Assignee"
                                    />
                                  </div>
                                }
                                trigger="click"
                                placement="bottomLeft"
                              >
                                <Avatar
                                  size="small"
                                  style={{
                                    backgroundColor: "#87d068",
                                    cursor: "pointer",
                                  }}
                                >
                                  <BiUserPlus size={20} />
                                </Avatar>
                              </Popover>
                            </Avatar.Group>
                          </Row>
                        </List>
                      </>
                    );
                  })}
                </Col>
              </Row>
            </>
          )}
        </div>
        {evisible && (
          <TaskEditForm
            form={editTaskForm}
            isModalVisible={evisible}
            okText="Update"
            initialValues={data1?.data}
            handleCancel={handleEditCancel}
            handleOk={editTask}
            headingText=""
            isAgent={isAgent}
          />
        )}
        {addAssigneeVisible && (
          <AssignEmployeeForm
            exclude={data1?.data?.assignee?.map((e) => e?.userName) ?? []}
            form={addAssigneeForm}
            visible={addAssigneeVisible}
            handleCancel={() => {
              addAssigneeForm.resetFields();
              setAddAssigneeVisible(false);
            }}
            handleOk={() => {
              addAssigneeForm
                .validateFields()
                .then((values) => assign_task_mutate(values))
                .catch(() => {});
            }}
          />
        )}
      </div>
      <Modal visible={filePreview} onCancel={() => setFilePreview(false)}>
        <DocViewer
          config={{
            header: {
              disableHeader: false,
              disableFileName: false,
              retainURLParams: false,
            },
          }}
          documents={[{ uri: fileUrl, fileType: "docx" }]}
          // documents={[{ uri: fileUrl }]}
          pluginRenderers={DocViewerRenderers}
        />
      </Modal>
    </>
  );
}

export default TaskDetail;
