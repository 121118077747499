import React, { useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../components/common/Loading";
import { BASEURL } from "../constant/Url.js";

function Redirect() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const access_token = params.get("access_token");
  const navigate = useNavigate();
  const { provider } = useParams();
  useEffect(() => {
    login();
  }, []);

  const login = async () => {
    try {
      const response = await axios.get(`${BASEURL}/auth/${provider}/callback`, {
        params: {
          access_token,
        },
      });
      localStorage.setItem("token", response.data.access_token);
      const token = localStorage.getItem("token");
      if (token) {
        navigate("/dashboard");
        window.location.reload(); // force a refresh w/ the server
      }
    } catch (error) {
      const errorMessage = error.response.data.message;
    }
  };
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Loading />
    </div>
  );
}

export default Redirect;
