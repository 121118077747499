import { Col, Row } from "antd";
import moment from "moment";
import React from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { api } from "../../../services/Api";

const AgentNews = () => {
  const navigate = useNavigate();
  const getFeeds = () => {
    return api.get("/feeds");
  };
  const {
    isLoading: feed_loading,
    data: feed_data,
    refetch: feed_refetch,
  } = useQuery("feed", getFeeds);
  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={24} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
          {/* Second item */}
          <Row gutter={[16, 16]}>
            {feed_data?.data?.docs?.map((feed, i) => {
              return (
                <Col span={8}>
                  <div
                    style={{
                      // background: "#f0f0f0",
                      height: "300px",
                      overflow: "hidden",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      navigate(`/dashboard/news-section/news/${feed?._id}`)
                    }
                  >
                    <img
                      alt="example"
                      src={feed?.featureImage?.url}
                      style={{
                        width: "100%",
                        height: "70%",
                        objectFit: "cover",
                        objectPosition: "top",
                      }}
                    />
                    <div style={{ padding: "8px 4px" }}>
                      <h3
                        style={{
                          paddingRight: "7px",
                          opacity: 0.8,
                          marginTop: "5px",
                        }}
                        // dangerouslySetInnerHTML={{
                        //   __html: limit(feed?.description, 160),
                        // }}
                      >
                        {feed?.title}
                      </h3>
                      <span
                        style={{
                          marginLeft: "5px",
                        }}
                      >
                        {moment(feed.createdAt).format("YYYY/MM/DD , h:mm a")}
                      </span>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AgentNews;
