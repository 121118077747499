import { Button, Card, Form, Input, Typography, message } from "antd";
import "antd/dist/antd.css";
import React, { useState } from "react";
import logo from "../../assets/images/logo2.png";
import { api } from "../../services/Api";

const { Title, Text } = Typography;

const ResetPasswordForm = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const handleFinish = async (values) => {
    setLoading(true);
    try {
      const res = await api.post(`/auth/forgot-password`, values);
      console.log(res);
      if (res) {
        form.resetFields();
        message.success("Mail sent successfully, Please Check your Email");
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
    setLoading(false);
  };

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    backgroundColor: "#f0f2f5",
    padding: "20px",
  };

  const cardStyle = {
    width: "100%",
    maxWidth: "400px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  };

  const formStyle = {
    padding: "20px",
    textAlign: "center",
  };

  const buttonStyle = {
    width: "100%",
    backgroundColor: "#1890ff",
    borderColor: "#1890ff",
    color: "#fff",
  };

  const logoStyle = {
    width: "150px",
    marginBottom: "20px",
  };

  return (
    <div style={containerStyle}>
      <Card style={cardStyle}>
        <div style={formStyle}>
          <img src={logo} alt="Logo" style={logoStyle} />
          <Title level={3} style={{ marginBottom: "20px" }}>
            Reset Password
          </Title>
          <Text
            type="secondary"
            style={{ display: "block", marginBottom: "20px" }}
          >
            Enter your email address to receive a link to reset your password.
          </Text>
          <Form
            name="reset_password"
            onFinish={handleFinish}
            layout="vertical"
            form={form}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
                {
                  type: "email",
                  message: "Please enter a valid email!",
                },
              ]}
            >
              <Input placeholder="Enter your email" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={buttonStyle}
                loading={loading}
              >
                Send Reset Link
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default ResetPasswordForm;
