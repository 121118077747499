// src/BlogPage.js
import { Col, Image, Layout, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import moment from "moment";
import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { api } from "../../../services/Api";

const { Title, Paragraph } = Typography;

const BlogPage = () => {
  const { id } = useParams();
  const getFeeds = () => {
    return api.get(`/feeds/${id}`);
  };
  const {
    isLoading: feed_loading,
    data: feed_data,
    refetch: feed_refetch,
  } = useQuery("feed", getFeeds);
  return (
    <div className="main-container">
      <Layout style={{ background: "#fff", padding: "0 24px" }}>
        <Content
          style={{ maxWidth: "900px", margin: "0 auto", padding: "24px 0" }}
        >
          <Row justify="center">
            <Col>
              <Title level={2}>{feed_data?.data?.title}</Title>
              <Paragraph style={{ textAlign: "center", color: "gray" }}>
                {moment(feed_data?.data?.updatedAt).format("MMMM Do, YYYY")}
              </Paragraph>
            </Col>
          </Row>
          <Row justify="center" style={{ margin: "24px 0" }}>
            <Col style={{ width: "100%" }}>
              <Image
                src={feed_data?.data?.featureImage?.url}
                alt="blog example"
                width="100%"
                height="auto"
                style={{ borderRadius: "8px", width: "100%" }}
              />
            </Col>
          </Row>
          <p
            dangerouslySetInnerHTML={{ __html: feed_data?.data?.description }}
          ></p>
        </Content>
      </Layout>
    </div>
  );
};

export default BlogPage;
